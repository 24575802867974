import {Component, OnInit, Output,EventEmitter,Provider, forwardRef, AfterViewInit} from '@angular/core';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { ModelValidators } from '../validators/validators';
import { CURRENCIES } from '../object_lists/assets_object';
import { FormComponent } from '../can_deactivate/prevent_unsaved_changes_gaurd.service';
import { LoggedinService } from '../loggedin.service';

import * as _ from "lodash";

@Component({
    templateUrl: './mesa-ce-exp.component.html',
    styleUrls:['./mesa-ce-exp.component.css','../app.component.css'],
    providers: [RouterLink, FormBuilder, ApiService ]
})  
export class MesaCeExpComponent  implements OnInit, FormComponent {

    forward_link:string;
    endpoint='/test/model/ceexp';
    use_hash = false;
    myForm: FormGroup;
    //myFormArrayAll = new FormArray([]);
    myFormArrayType = new FormArray([]);
    myFormArrayVol =  new FormArray([]);
    model_id: string;
    isLoading = false;
    crud:string;

    is_user_hash = {}

    /*
    if (api_object["marketriskfactors"]["commodities"].length==0){
                    this.router.navigateByUrl('/dashboard(content:models/hedgeratio/' +  model_id +')');
                    return true;
                }
    */
    model_name:string;
    constructor(
        private loggedinService: LoggedinService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private _apiService: ApiService) {

        this.loggedinService.announceLoggedin(true);

        this.model_name = localStorage.getItem('model_name');

        this.myForm = fb.group({
             my_form_array_type: this.myFormArrayType,
             my_form_array_vol: this.myFormArrayVol
         })
         
    }

    api_object:any;
    currency_array = CURRENCIES;
    currency_col_array: any[] = []
    currency_list: any[] = [];
    p_values: any[] = [];
    beta_list: any[] = [];
    temp:any;
    col_count:number;
    analysis_horizon:number;
    asset_index_hash = {};
    fx_estresult_exps_fwd: any[] = [];
    fx_estresult_exps_spot: any[] = [];

    is_comm = true;
    forward_title="Commodity: Mean Reversion";
   


    edit_asset_type_hash ={}
    orig_asset_type_hash:any;

    edit_fx_estresult_exps_spot: any[] = [];
    edit_fx_estresult_exps_fwd: any[] = [];

    asset_type_label = {}
    edit_object = {}
    undo_array: any[] = [];
    redo_array: any[] = [];

    isUser(asset_pair,user_list){
        this.is_user_hash[asset_pair]=false;
        for (var _i = 0; _i <  user_list.length; _i++) {
            if (user_list[_i]!='NA'){
                this.is_user_hash[asset_pair]=true
                break;
            }
        }
    }//end isUser

     menuEvent(event){

      
        if (event['event']!='clear'){
            
            let test = []
            let temp_list:any
            let prior_list:any
            let type = event['event']
            let ce = this.currency_list[event['asset_idx']]
            let asset = ce;
            let prior_label = this.asset_type_label[ce];


            this.asset_type_label[ce]=type

            if (prior_label=='user'){
                prior_list = this.edit_asset_type_hash[asset]['user'];
            }

            if (prior_label!='user'){
                prior_list = this.orig_asset_type_hash[asset][prior_label];
                for (var _i = 0; _i <  prior_list.length; _i++) {
                   if (prior_list[_i]==null){
                       prior_list[_i]='NA'
                   }
                } 
            }

            if (type != 'user'){
               temp_list = this.orig_asset_type_hash[asset][type];
               for (var _i = 0; _i <  temp_list.length; _i++) {
                   if (temp_list[_i]==null){
                       temp_list[_i]='NA'
                   }
                } 
            }

            if (type == 'user'){
               temp_list = this.edit_asset_type_hash[asset]['user'];
            }
            
            let _j = 0;
            for (var _i = 0; _i < this.col_count; _i++) {
                if ( _i>=this.asset_index_hash[ce] && _i<this.asset_index_hash[ce]+this.analysis_horizon){
                    test.push(temp_list[_j]);
                    _j = _j + 1;
                } else {
                    test.push(this.myForm.controls['my_form_array_vol'].value[_i]);
                }
            } // end i

            this.myFormArrayVol.setValue(test);


            this.edit_object[asset]['undo'].push({'type':prior_label,'data':prior_list});


 
        } else {

            this.clearRow(event['asset_idx'])
            
        }

        let new_list = []
        for (var _i = 0; _i < this.currency_list.length; _i++) {
            new_list.push(this.asset_type_label[this.currency_list[_i]]) 
        }
        this.myFormArrayType.setValue(new_list)

    } //end menuEvent

      undo(ce,i,j){

        
         let test = []
         var last_val_idx = this.edit_object[ce]['undo'].length;
         if (last_val_idx>0){

               var last_val = this.edit_object[ce]['undo'][last_val_idx-1];

               //REDO
               let type = last_val['type']

               let current_type = this.asset_type_label[ce]
               var current_val = this.edit_asset_type_hash[ce][current_type].slice(0);
               
                
                let data = last_val['data']
                this.edit_object[ce]['undo'].splice(-1, 1);

                let _j = 0;
                for (var _i = 0; _i < this.col_count; _i++) {
                    if ( _i>=this.asset_index_hash[ce] && _i<this.asset_index_hash[ce]+this.analysis_horizon){
                        test.push(data[_j]);
                        _j = _j + 1;
                    } else {
                        test.push(this.myForm.controls['my_form_array_vol'].value[_i]);
                    }
                } // end i

                 this.edit_asset_type_hash[ce][type]=data;
                 this.myFormArrayVol.setValue(test);

                 this.edit_object[ce]['redo'].push({'type':current_type,'data':current_val});

                 this.asset_type_label[ce]=type



         }


    }

    redo(ce,i,j){

        //let type = this.myForm.controls['my_form_array_type'].value[i]

         let test = []
         var last_val_idx = this.edit_object[ce]['redo'].length;
         
         if (last_val_idx>0){
                var last_val = this.edit_object[ce]['redo'][last_val_idx-1];
                let type = last_val['type']
                let data = last_val['data']
                
                let current_type = this.asset_type_label[ce]
                var current_val = this.edit_asset_type_hash[ce][current_type].slice(0);

                
                
                this.edit_object[ce]['redo'].splice(-1, 1);

                let _j = 0;
                for (var _i = 0; _i < this.col_count; _i++) {
                    if ( _i>=this.asset_index_hash[ce] && _i<this.asset_index_hash[ce]+this.analysis_horizon){
                        test.push(data[_j]);
                        _j = _j + 1;
                    } else {
                        test.push(this.myForm.controls['my_form_array_vol'].value[_i]);
                    }
                } // end i
                
                 this.edit_asset_type_hash[ce][type]=data;
                 this.myFormArrayVol.setValue(test);

                 this.edit_object[ce]['undo'].push({'type':current_type,'data':current_val});
         }

        

    }

    
    ngOnInit() {
        this.route.params.map(params => params['id'])
            .subscribe(id => { this.model_id=id });

        this.currency_list = [];
        this.route.data.forEach((data) => {

                if (data['results'].hasOwnProperty('ceexp')===false){
                    this.crud='new';
                } else {
                    this.crud='edit';
                }
 
                //this.analysis_horizon = +data['results']["populate_risk"]['riskparam']["analysis_horizon"][0];

               
                this.analysis_horizon = +data['results']['ceexp']['analysis_horizon']

                this.currency_list = data['results']['ceexp']['asset_list']
                let currency_count = this.currency_list.length;
                this.col_count = this.analysis_horizon*currency_count;

                let count = this.analysis_horizon
                for (var _i = 0; _i < currency_count; _i++) {
                    this.asset_index_hash[this.currency_list[_i]]= count - this.analysis_horizon;
                    count = count + this.analysis_horizon;
                }

                for (var _i = 0; _i < this.analysis_horizon; _i++) {
                    this.currency_col_array.push('M'+(_i+1));
                } 
                
                if (data['results'].hasOwnProperty('commr')===false){
                    this.is_comm=false;
                    this.forward_title='Correlation Estimates: Currencies'
                    this.forward_link = 'models/corfx/' +  this.model_id
                } else {
                    this.forward_title='Commodity: Volatilities '
                    this.forward_link = 'models/comvol/' +  this.model_id
                }
                


                for (var _i = 0; _i < currency_count; _i++) {
                    this.edit_object[this.currency_list[_i]]={}
                    this.edit_object[this.currency_list[_i]]={}
                    this.edit_object[this.currency_list[_i]]={}
                    this.edit_object[this.currency_list[_i]]={}

                    this.edit_object[this.currency_list[_i]]['redo']=[]
                    this.edit_object[this.currency_list[_i]]['redo']=[]
                    this.edit_object[this.currency_list[_i]]['redo']=[]

                    this.edit_object[this.currency_list[_i]]['undo']=[]
                    this.edit_object[this.currency_list[_i]]['undo']=[]
                    this.edit_object[this.currency_list[_i]]['undo']=[]
            }
             
            /*
                this.edit_fx_estresult_exps_spot = data['results']["populate_risk"]["fx_estresult_exps_spot"];
                this.edit_fx_estresult_exps_fwd = data['results']["populate_risk"]["fx_estresult_exps_fwd"];

                let fx_estresult_exps_spot_last = data['results']["populate_risk"]["fx_estresult_exps_spot"];
            */
            
                
                /*

                //Initialize asset array
                this.fx_estresult_exps_fwd = data['results']["populate_risk"]['fx_estresult_exps_fwd'];

                
                for (var _i = 0; _i < this.fx_estresult_exps_fwd.length; _i++) {
                    if (this.fx_estresult_exps_fwd[_i]!='NA'){
                        this.fx_estresult_exps_fwd[_i]=this.fx_estresult_exps_fwd[_i].toFixed(3);
                    }
                }
                
                for (var _i = 0; _i < currency_count; _i++) {
                        for (var _j = 0; _j < this.analysis_horizon; _j++) {
                            if (_j<this.analysis_horizon-1){
                                this.fx_estresult_exps_spot.push('NA');
                            } else {
                                this.fx_estresult_exps_spot.push(fx_estresult_exps_spot_last[_i]);
                            }
                        }
                }

                */
                


                 if (data['results'].hasOwnProperty('ceexp')){ //Edit

                    this.edit_asset_type_hash = data['results']['ceexp']['edit_asset_type_hash'];
                    this.orig_asset_type_hash = data['results']['ceexp']['orig_asset_type_hash'];

                

                    let type = data['results']['ceexp']['type']
                    let vol = data['results']['ceexp']['vol']
                    for (var _i = 0; _i < vol.length; _i++) {
                        this.myFormArrayVol.push(new FormControl(vol[_i],[Validators.required]) );
                    }

                    for (var _i = 0; _i < type.length; _i++) {
                        this.myFormArrayType.push(new FormControl(type[_i]));
                        this.asset_type_label[this.currency_list[_i]]=type[_i];
                    }
                 } else { //New


                       this.createEditHash();
                       
                        for (var key in this.edit_asset_type_hash) {
                            for (var _i = 0; _i < this.edit_asset_type_hash[key]['forward'].length; _i++) {
                                let v:any;
                                v = this.edit_asset_type_hash[key]['forward'][_i]
                                this.myFormArrayVol.push(new FormControl(v,[Validators.required]) );
                            }
                        } // end for (var key in this.edit_asset_type_hash) {

                        /*
                        for (var _i = 0; _i < this.fx_estresult_exps_fwd.length; _i++) {
                            let v:any;
                            if (this.fx_estresult_exps_fwd[_i]!='NA'){
                                    v = this.fx_estresult_exps_fwd[_i];
                            } else {
                                v = 'NA';
                            }
                            this.myFormArrayVol.push(new FormControl(v,[Validators.required]) );
                         }
                         */

                         
                      //Set the radio 
                        for (var _i = 0; _i < currency_count; _i++) {
                                
                                let test = this.currency_array.filter(x => x.pair === this.currency_list[_i])[0];
                                if (test.forward==1){
                                    this.myFormArrayType.push(new FormControl("forward"));
                                    this.getAssetArray('forward',this.currency_list[_i]);
                                } else {
                                    this.myFormArrayType.push(new FormControl("spot"));
                                    this.getAssetArray('spot',this.currency_list[_i]);
                                }
                                
                                     
                        }
                        this.orig_asset_type_hash = _.cloneDeep(this.edit_asset_type_hash)

                        
                 } //end new
                
            


        });

          
    }

/*
    onInputChange(ce,i,j){

        let idx = ((i+1)*this.currency_col_array.length)-this.currency_col_array.length+j;
      
        let type = this.myForm.controls['my_form_array_type'].value[i]
        this.edit_asset_type_hash[ce][type][j]=this.myForm.controls['my_form_array_vol'].value[idx];
       
    }
    */

    onInputChange(ce,i,j){

     
        let type = this.asset_type_label[ce];
        var prior_val = this.edit_asset_type_hash[ce][type].slice(0);

        //let type = this.myForm.controls['my_form_array_type'].value[i]
        let idx = ((i+1)*this.currency_col_array.length)-this.currency_col_array.length+j;
        let val = this.myForm.controls['my_form_array_vol'].value[idx];
        
        
        this.edit_asset_type_hash[ce]['user']=this.edit_asset_type_hash[ce][type].slice(0);
        this.edit_asset_type_hash[ce]['user'][j]=val;
        
        
        if (this.asset_type_label[ce]!='user'){
            this.getAssetArrayUndoRedo(type,ce,prior_val)
        } else {
             this.getAssetArrayUndoRedo('user',ce,prior_val)
        }

        this.asset_type_label[ce]='user';

      
        for (var asset_pair in this.edit_asset_type_hash) {
            this.isUser(asset_pair,this.edit_asset_type_hash[asset_pair]['user'])
        }


        let new_list = []
        for (var _i = 0; _i < this.currency_list.length; _i++) {
            new_list.push(this.asset_type_label[this.currency_list[_i]]) 
        }
        this.myFormArrayType.setValue(new_list)
        
        

    }


    createEditHash(){


       let pair = '';
       for (var _i = 0; _i < this.edit_fx_estresult_exps_fwd.length; _i++) {
            pair = this.edit_fx_estresult_exps_fwd[_i]['_row'];
            this.edit_asset_type_hash[pair]={};
            this.edit_asset_type_hash[pair]['forward']=[];
            this.edit_asset_type_hash[pair]['spot']=[];
            for (var key in this.edit_fx_estresult_exps_fwd[_i]) {
                if (key!='_row'){
                    this.edit_asset_type_hash[pair]['forward'].push(this.edit_fx_estresult_exps_fwd[_i][key])
                }
            }
       }


        for (var _i = 0; _i < this.edit_fx_estresult_exps_spot.length; _i++) {
            pair = this.edit_fx_estresult_exps_spot[_i]['_row'];
            for (var _j = 0; _j < this.analysis_horizon-1; _j++) {
                this.edit_asset_type_hash[pair]['spot'].push('NA');
            }
            this.edit_asset_type_hash[pair]['spot'].push(this.edit_fx_estresult_exps_spot[_i]["fx_spot"]);
        }


     

    }


    ngAfterViewInit() {
        // Your jQuery code goes here
        //$('[data-toggle="tooltip"]').tooltip();
        
    }

    radioChange(type,asset){
       
        this.getAssetArray(type,asset);
    }
    getAssetArray(type,asset){
        let test: any[] = [];
        let test2: any[] = [];

        let temp_list = this.edit_asset_type_hash[asset][type];
        let _j = 0;

      

      
        if (type=='forward'){
            for (var _i = 0; _i < this.col_count; _i++) {
                if ( _i>=this.asset_index_hash[asset] && _i<this.asset_index_hash[asset]+this.analysis_horizon){
                    //test.push(this.fx_estresult_exps_fwd[_i]);
                    test.push(temp_list[_j]);
                    _j = _j + 1;
                } else {
                    test.push(this.myForm.controls['my_form_array_vol'].value[_i]);
                }
            }
        }

        

        
        if (type=='spot'){
            for (var _i = 0; _i < this.col_count; _i++) {
                if ( _i>=this.asset_index_hash[asset] && _i<this.asset_index_hash[asset]+this.analysis_horizon){
                    //test.push(this.fx_estresult_exps_spot[_i]);
                    test.push(temp_list[_j]);
                    _j = _j + 1;
                } else {
                    test.push(this.myForm.controls['my_form_array_vol'].value[_i]);
                }
            }
        }
        
        
        this.myFormArrayVol.setValue(test);
    }

    getAssetArrayUndoRedo(type,asset,prior_val){  //hist, GBP


        let test: any[] = [];
        let test2: any[] = [];
        let iso_asset: any[] = [];

        let temp_list = prior_val;
 
        let _j = 0;
        if (type=='forward'){
            
            for (var _i = 0; _i < this.col_count; _i++) {
                if ( _i>=this.asset_index_hash[asset] && _i<this.asset_index_hash[asset]+this.analysis_horizon){
                    //test.push(this.fx_estresult_vols_imp[_i]);
                    test.push(temp_list[_j]);
                    iso_asset.push(temp_list[_j]);
                    _j = _j + 1;
                } else {
                    test.push(this.myForm.controls['my_form_array_vol'].value[_i]);
                }
            }
        } 

        if (type=='spot'){
            for (var _i = 0; _i < this.col_count; _i++) {
                if ( _i>=this.asset_index_hash[asset] && _i<this.asset_index_hash[asset]+this.analysis_horizon){
                    //test.push(this.fx_estresult_vols_hist[_i]);
                    test.push(temp_list[_j]);
                    iso_asset.push(temp_list[_j]);
                    _j = _j + 1;
                } else {
                    test.push(this.myForm.controls['my_form_array_vol'].value[_i]);
                }
            }
        }

        if (type=='user'){
            for (var _i = 0; _i < this.col_count; _i++) {
                if ( _i>=this.asset_index_hash[asset] && _i<this.asset_index_hash[asset]+this.analysis_horizon){
                    //test.push(this.fx_estresult_vols_exp[_i]);
                    test.push(temp_list[_j]);
                    iso_asset.push(temp_list[_j]);
                    _j = _j + 1;
                } else {
                    test.push(this.myForm.controls['my_form_array_vol'].value[_i]);
                }
            }
        }
    

        //this.myFormArrayVol.setValue(test);

        
        this.edit_object[asset]['undo'].push({'type':type,'data':iso_asset,'base':type});
       
    }





    selectAll(type){
       
        let test: any[] = [];
        for (var _i = 0; _i < this.currency_list.length; _i++) {
            this.getAssetArray(type,this.currency_list[_i])
            test.push(type);
        }
        this.myFormArrayType.setValue(test);
    }

     clearRow(event){
         

        let _j = this.col_count;
        let test: any[] = [];
        for (var _i = 0; _i < this.col_count; _i++) {
        
           if ( _i>=event*this.analysis_horizon && _i<(event+1)*this.analysis_horizon){
                test.push('');
           } else{
               test.push(this.myForm.controls['my_form_array_vol'].value[_i]); 
           }
        }
        this.myFormArrayVol.setValue(test);
        


        let ce = this.currency_list[event]
        let current_type = this.asset_type_label[ce];
        var current_val = this.edit_asset_type_hash[ce][current_type].slice(0);

        this.asset_type_label[ce]='user';
        this.edit_object[ce]['undo'].push({'type':current_type,'data':current_val});

    }

    refreshRow(event){
        //Reset from model run
        let type = this.myForm.controls['my_form_array_type'].value[event];
        let asset = this.currency_list[event];
        this.getAssetArray(type,asset);
    }


    clearAll(){
        let test: any[] = [];
        for (var _i = 0; _i < this.col_count; _i++) {
            test.push('');
        }
        this.myFormArrayVol.setValue(test);
    }

    refreshAll(){
         //Reset from model run
         for (var _i = 0; _i < this.currency_list.length; _i++) {
                let type = this.myForm.controls['my_form_array_type'].value[_i];
                let asset = this.currency_list[_i];
                this.getAssetArray(type,asset);
         }
    }

    back(){
        this._router.navigateByUrl('models/cevol/'+  this.model_id);
    }
 
    onSubmit() {
        var result:any;
       
        
        var data = {'type':this.myForm.controls['my_form_array_type'].value,
                     'vol':this.myForm.controls['my_form_array_vol'].value,
                     'edit_asset_type_hash':this.edit_asset_type_hash,
                     'orig_asset_type_hash':this.orig_asset_type_hash,
                     'asset_list':this.currency_list,
                     'analysis_horizon':this.analysis_horizon};

        
        //this.is_comm
        
        if (this.crud=='new'){
            result = this._apiService.addModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    this._router.navigateByUrl(this.forward_link);
                });
        }

        if (this.crud=='edit'){
            result = this._apiService.updateModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    this._router.navigateByUrl(this.forward_link);
            });
        }
         
       
        
        
       

    }
} 
