import {Component, OnInit, Output,EventEmitter,Provider, forwardRef, AfterViewInit,ViewChild} from '@angular/core';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { FormComponent } from '../can_deactivate/prevent_unsaved_changes_gaurd.service';
import { LoggedinService } from '../loggedin.service';
import { BusyService } from '../busy.service';
import { MatDatepicker } from '@angular/material';
import { PollingService } from '../polling.service';
import * as moment from 'moment/moment';
import {MatSnackBar} from '@angular/material';
import { ModelValidators } from '../validators/validators';
const FLOAT_REGEX = /^-?\d*(\.\d+)?$/;
const POSITIVE_FLOAT_REGEX = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
 
/*

ModelValidators.validPercentage
Validators.pattern(FLOAT_REGEX)
*/

              


import * as _ from "lodash";
declare var $: any;
 
@Component({
  selector: 'app-mesa-output-var',
  templateUrl: './mesa-output-var.component.html',
  styleUrls: ['./mesa-output-var.component.css']
})
export class MesaOutputVarComponent implements OnInit {


  @ViewChild(MatDatepicker) picker: MatDatepicker<Date>;
  @ViewChild(MatDatepicker) start_picker: MatDatepicker<Date>;
  @ViewChild(MatDatepicker) end_picker: MatDatepicker<Date>;


  this_model = {}
  crud:string;
  models: any[];
  models_risk: any[] = [];
  models_hedging: any[] = [];
  models_exposures: any[] = [];
  models_hedging_list: any[] = [];

  models_hedging_mid_list: any[] = [];
  model_hedge_name_hash: any[] = [];
  exposure_risk_hash = {}
  risk_exposure_hash = {}

  models_hedging_list_filtered: any[] = [];
  models_risk_list_filtered: any[] = [];

  hedging_list_risk_hash = {}
  hedging_list_exposure_hash = {}

  model_id_hash = {}

  myForm: FormGroup;



  myFormHedgingListUse = new FormArray([]);
  myFormRiskUse = new FormArray([]); 
  myFormHistoricalUse = new FormArray([]); 

  options_analysis_date_model:any;
  historical_start_date_model:any;
  historical_end_date_model:any;

  endpoint='/test/model/outputvar';
  idx: number;
  edit_var_model = {}

  startDate = new Date(2017, 0, 1); //set the control
  minDate = new Date(1990, 0, 1); //set the control
  maxDate = new Date(2030, 0, 1); //set the control
  //assu = moment("12/25/1995");
  wtf=moment("1995-12-25"); //set the input
  myFilter = (d: Date) =>  d.getFullYear() >= 1990  


  start_date:any;
  isError = true;

  // (d.getFullYear() === parseInt(d.getFullYear(), 10))    
  //  d.getFullYear() >= 2017  
  //  Number.isInteger(d.getFullYear()) == true

  //myFilter1 = (d: Date) => d. >= 2017

  

  duder(){
    
  }

  onChangeDuder(){
      
  }
  

  constructor(  private loggedinService: LoggedinService,
                private busyService: BusyService,
                private route: ActivatedRoute,
                private fb: FormBuilder,
                private _router: Router,
                public snackBar: MatSnackBar,
                private pollingService: PollingService,
                private _apiService: ApiService) { 


             this.myForm = fb.group({
                  my_form_hedging_list_use: this.myFormHedgingListUse,
                  my_form_risk_use: this.myFormRiskUse,
                  my_form_historical_use: this.myFormHistoricalUse,
                  options_name: ['',[Validators.required]],
                  /*
                  options_var_level: ['',[Validators.required]],
                  options_analysis_date: ['',[Validators.required]],
                  options_seed: ['',[Validators.required]],
                  */
                  historical_start_date: [''],
                  historical_end_date: ['']
                 
              })
  }
 
  ngOnInit() {
   
   this.route.params.map(params => params['idx'])
            .subscribe(idx => { this.idx=idx });

      //this.idx = 1
      if (this.idx){
        this.crud = 'edit';
      } else{
          this.crud = 'new';
          this.idx = -999;
      }
    

     //http://localhost:4200/#/output/(output:var;idx=111)
     /*
     back(){
        this._router.navigateByUrl('/dashboard(content:models/edit/' +  this.model_id + ';idx=111)');
    }
    */
     


    this.route.data.forEach((data) => {
      
      
      let models = data['results'];

      this.models = models
      this.models_exposures = []
      this.models_hedging = []
      this.models_risk = [];
      this.models_hedging_list = [];


      
       
       for (var _i = 0; _i < models.length; _i++) {

          this.model_id_hash[this.models[_i]['model_id']]=models[_i]
          

          if (models[_i]['data'].hasOwnProperty('var')){
              let var_hash_list = models[_i]['data']['var'];
              for (var _j = 0; _j < var_hash_list.length; _j++) {
                  if (models[_i]['data']['var'][_j]['idx']==this.idx){
                      this.edit_var_model = models[_i]['data']['var'][_j]
                  }
              }
          }

           

          if (models[_i]['data']['type']=='risk'){
              this.models_risk.push(models[_i]);
              this.myFormRiskUse.push(new FormControl(false));
              this.risk_list.push(models[_i])
              this.model_name_hash[models[_i]['model_id']]=models[_i]['data']['name']




              //this.myFormRiskUse.push(new FormControl(false));
              //this.risk_list.push(this.model_id_hash[rid])
              /*
              if (models[_i]['data']['data'].hasOwnProperty('corcross')){
                  models[_i]['data']['status']='complete';
              }
              */
          }

          if (models[_i]['data']['type']=='hedging'){
           
            if (models[_i]['data']['data'].hasOwnProperty('hedgetailrisk')){
                models[_i]['data']['status']='complete';
            }
            this.models_hedging_list.push(models[_i]);
            this.models_hedging.push(models[_i]);

            this.model_hedge_name_hash[models[_i]['model_id']]=models[_i]['data']['name']
          }


          if (models[_i]['data']['type']=='exposure'){
              if (models[_i]['data']['data'].hasOwnProperty('hedgeexpcom')){
                  models[_i]['data']['status']='complete';
              }
              this.models_exposures.push(models[_i]);
          }

          if (models[_i]['data']['type']=='hedging_list'){
              if (models[_i]['data']['data'].hasOwnProperty('dude')){
                  models[_i]['data']['status']='complete';
              }
              this.models_hedging_list.push(models[_i]);
          }

       } // end for

     });// end this.route.data.forEach
       

       if (this.crud=='edit'){
         
            this.myForm.controls['options_name'].setValue(this.edit_var_model['options_name']);
            this.myForm.controls['options_var_level'].setValue(this.edit_var_model['options_var_level']);
            this.myForm.controls['options_seed'].setValue(this.edit_var_model['options_seed']);
            this.myForm.controls['options_analysis_date'].setValue(this.edit_var_model['options_analysis_date']);
            this.options_analysis_date_model = this.edit_var_model['options_analysis_date']

            this.myForm.controls['historical_start_date'].setValue(this.edit_var_model['historical_start_date']);
            this.myForm.controls['historical_end_date'].setValue(this.edit_var_model['historical_end_date']);


       }
        
       if (this.crud=='new'){
         for (var _i = 0; _i < this.models_hedging.length; _i++) {
                this.myFormHedgingListUse.push(new FormControl(false));
          }
         this.myFormHistoricalUse.push(new FormControl(false));
       }


      //Get all risk models associated with exposure models  {"risk_1"=[exp_1,exp_2]}
      for (var _i = 0; _i < this.models_risk.length; _i++) {
        this.risk_exposure_hash[this.models_risk[_i]['model_id']]=this.models_risk[_i].data.models_id_exposure
      }

      for (var _i = 0; _i < this.models_hedging_list.length; _i++) {
        this.models_hedging_mid_list.push(this.models_hedging_list[_i]['model_id']);
        if (this.models_hedging_list[_i].hasOwnProperty('data') && this.models_hedging_list[_i]['data'].hasOwnProperty('data') &&
            this.models_hedging_list[_i]['data']['data'].hasOwnProperty('run_ids')){
              this.hedging_list_risk_hash[this.models_hedging_list[_i]['model_id']]=[]
              this.hedging_list_exposure_hash[this.models_hedging_list[_i]['model_id']]=[]
              let exp_id_temp = this.models_hedging_list[_i].data.data.run_ids.my_form_exposure_model_use.model_id;
              this.hedging_list_exposure_hash[this.models_hedging_list[_i]['model_id']].push(exp_id_temp)
              if (this.exposure_risk_hash.hasOwnProperty(exp_id_temp)){
                this.hedging_list_risk_hash[this.models_hedging_list[_i]['model_id']].push(this.exposure_risk_hash[exp_id_temp])
              }
        } //emnd big if 
      }//end for    hedging_list_exposure_hash

      if (this.crud=='edit'){
         for (var _i = 0; _i < this.models_hedging_list.length; _i++) {
            let mid = this.models_hedging_list[_i]['model_id']
           
            if (this.edit_var_model['hedge_list_hash'].hasOwnProperty(mid)){
                this.myFormHedgingListUse.push(new FormControl(this.edit_var_model['hedge_list_hash'][mid]));
                this.onHedgingListChange(mid)
            } else {
                this.myFormHedgingListUse.push(new FormControl(false));
            }
         }

         
         
         this.myFormHistoricalUse.push(new FormControl(this.edit_var_model['my_form_historical_use'][0]));
         if (this.edit_var_model['my_form_historical_use'][0]==true){
            //add dates 
            //this.myForm.controls['historical_start_date'].setValue(this.edit_var_model['historical_start_date']);
            //this.myForm.controls['historical_end_date'].setValue(this.edit_var_model['historical_end_date']);
         }


         //after selectijng edit hedge list get the saved Risk Models
         let test = []
         for (var _i = 0; _i < this.risk_list.length; _i++) {
             let mid = this.risk_list[_i]['model_id'];
             if (this.edit_var_model['risk_list_hash'].hasOwnProperty(mid)){
                test.push(this.edit_var_model['risk_list_hash'][mid])
             } else {
                 test.push(false)
             }
         }
         this.myFormRiskUse.setValue(test);
       } //end edit


      /*
      $('#datepicker').datepicker({
            format: 'yyyy-mm-dd',
            //startDate: this.testme,
            autoclose: true
            }).on('changeDate', (ev) => {
                var newDate = new Date(ev.date);

                var d = new Date(ev.date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;

                this.options_analysis_date_model = [year, month, day].join('-');
              


                //this.basic_setup.analysis_date = newDate.toLocaleDateString();//ev.date;
                //var splitted = newDate.toLocaleDateString().split("/");
                //this.basic_setup.analysis_date = splitted[2] + '-' + splitted[1] + '-' + splitted[0]
        });
        */

        /*
        $('#datepicker_historical_start').datepicker({
            format: 'yyyy-mm-dd',
            //startDate: this.testme,
            autoclose: true
            }).on('changeDate', (ev) => {
                var newDate = new Date(ev.date);

                var d = new Date(ev.date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;

                this.historical_start_date_model = [year, month, day].join('-');
            


                //this.basic_setup.analysis_date = newDate.toLocaleDateString();//ev.date;
                //var splitted = newDate.toLocaleDateString().split("/");
                //this.basic_setup.analysis_date = splitted[2] + '-' + splitted[1] + '-' + splitted[0]
        });

        $('#datepicker_historical_end').datepicker({
            format: 'yyyy-mm-dd',
            //startDate: this.testme,
            autoclose: true
            }).on('changeDate', (ev) => {
                var newDate = new Date(ev.date);

                var d = new Date(ev.date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;

                this.historical_end_date_model = [year, month, day].join('-');
               


                //this.basic_setup.analysis_date = newDate.toLocaleDateString();//ev.date;
                //var splitted = newDate.toLocaleDateString().split("/");
                //this.basic_setup.analysis_date = splitted[2] + '-' + splitted[1] + '-' + splitted[0]
        });
        */

      
     

     
  
  
} //end ngOnInit()

 
     selectedList = {}

     risk_list: any[] = [];

     isEidInRisk(eid){

       for (var key in this.risk_exposure_hash) {
         let hle = this.risk_exposure_hash[key];
          for (var _i = 0; _i < hle.length; _i++) {
            if (eid==hle[_i]){
              return key
            }
          }
       }

       return 0;

     } //end isEidInRisk


    historical = {}


    model_name_hash = {}
    
    onHedgingListChange(model_id){
      
     /*
      this.risk_list = []
      this.selectedList =  _.zipObject(this.models_hedging_mid_list, this.myForm.controls['my_form_hedging_list_use'].value);
      var filtered_hedged_list_mid = _.pickBy(this.selectedList);
      for (var key in filtered_hedged_list_mid) {
          let hle = this.hedging_list_exposure_hash[key];
          for (var _i = 0; _i < hle.length; _i++) {
            let eid = hle[_i]
            let rid = this.isEidInRisk(eid)
            if (rid!=0){
              this.myFormRiskUse.push(new FormControl(false));
              this.risk_list.push(this.model_id_hash[rid])


               this.model_name_hash[rid]=this.model_id_hash[rid]['data']['name']

            } //end rid!=0
          } // for (var _i = 0; _i < hle.length; _i++) 
      } //end for (var key in this.hedging_list_exposure_hash)
      */
    } //end onHedgingListChange

    

    onFocusOptionDate(){
        this.picker.open();     
    }
    onFocusHistoricalStartDate(){
        this.start_picker.open();
    }
    onFocusHistoricalEndDate(){
        this.end_picker.open();
    }

    dude = {}
    onSubmit(){


       let data = this.myForm.value
       //'yyyy-mm-dd'
       //data['options_analysis_date']=moment(this.myForm.controls["options_analysis_date"].value).format('DD-MM-YYYY')
       data['historical_start_date']=moment(this.myForm.controls["historical_start_date"].value).format('DD-MM-YYYY')
       data['historical_end_date']=moment(this.myForm.controls["historical_end_date"].value).format('DD-MM-YYYY')
       
     
       let hedge_list_mid = [];
       //for (var _i = 0; _i < this.models_hedging_list.length; _i++) {
       //  hedge_list_mid.push(this.models_hedging_list[_i]['model_id'])
        //}

        for (var _i = 0; _i < this.models_hedging.length; _i++) {
            hedge_list_mid.push(this.models_hedging[_i]['model_id'])
        }
        
    
        let model_id_hash_use = {}
        for (var _i = 0; _i < hedge_list_mid.length; _i++) {
            model_id_hash_use[hedge_list_mid[_i]]=this.myForm.controls["my_form_hedging_list_use"].value[_i]
        }

        
       let hedge_list_hash =  _.zipObject(hedge_list_mid,this.myForm.controls["my_form_hedging_list_use"].value)

      


       let risk_list_mid = [];
       for (var _i = 0; _i < this.risk_list.length; _i++) {
         risk_list_mid.push(this.risk_list[_i]['model_id'])
        }
        let risk_list_hash =  _.zipObject(risk_list_mid,this.myForm.controls["my_form_risk_use"].value)
       

         data['hedge_list_hash']=hedge_list_hash;
         data['risk_list_hash']=risk_list_hash;
         data['idx']=this.idx
         data['tab']='var'
         data['error']=false  //dislable if status if runnimg or error
         data['status']='running';

         data['var_name_list']=[] 
         for (var key in risk_list_hash) {
             if (risk_list_hash[key]==true){
                data['var_name_list'].push(this.model_name_hash[key])
             } 
         }


         data['var_hedge_name_list']=[] 
         for (var key in hedge_list_hash) {
             if (hedge_list_hash[key]==true){ //&& model_id_hash_use[key]==true
                data['var_hedge_name_list'].push(this.model_hedge_name_hash[key])
             } 
         }

         

         this.dude = data

      

         var result:any;
        
        
         //localStorage.setItem('id_token', response.json().access_token);
      

        

        if (this.crud=='new'){
            result = this._apiService.addModel(this.endpoint,data).subscribe(x => {
                    //this._router.navigateByUrl('models/corcom/' +  this.model_id);
                    this.pollingService.announcePolling({'model_id':x['model_id'],'is_output':true}); 
                });
        }

        if (this.crud=='edit'){
            
            result = this._apiService.updateModel(this.endpoint,data).subscribe(x => {
                    this.pollingService.announcePolling({'model_id':x['model_id'],'is_output':true}); 
                    //this._router.navigateByUrl('models/corcom/' +  this.model_id);
            });
        }

        /*
        this.myForm.reset()
        this.myForm.controls['options_name'].markAsUntouched()
        this.myForm.controls['options_name'].markAsPristine()
        */
        this.openSnackBar()

      
    } //end onSubmit

    openSnackBar() {
        this.snackBar.openFromComponent(OutputVarRunningComponent, {
          duration: 5000,
        });
      }

}



@Component({
    selector: 'snack-bar-component-running-snack',
    templateUrl: 'snack-bar-component-running-snack.html',
    styles: [`.example-pizza-party { color: #0C7238; }`],
  })
  export class OutputVarRunningComponent {}