export class Model {
    id: string;
    model_id: string;
    name: string;
    type: string;
    status: string;
    my_form_exposure_model_use: any;
    models_id_exposure:any;
    my_form_exposure_hedging_model_use:any;
    models_exposure_radio:any;
    cb_hash:any;
    my_form_exposure_hedging_model_id_use:any;
    is_used_hash:any;
}