import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class BusyService {

  constructor() { }

  private busyAnnouncedSource = new Subject<boolean>();
  // Observable string streams
  busyAnnounced$ = this.busyAnnouncedSource.asObservable();
  // Service message commands
  announceBusy(busy: boolean) {
    this.busyAnnouncedSource.next(busy);
  }

}
