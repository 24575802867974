import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
@Injectable()
export class ModelRefreshService {

  constructor() { }

  private modelrefreshAnnouncedSource = new Subject<boolean>();
  modelrefreshAnnounced$ = this.modelrefreshAnnouncedSource.asObservable();
  announceModelrefresh(modelrefresh: boolean) {
    this.modelrefreshAnnouncedSource.next(modelrefresh);
  }

} 