
import { Component, OnInit, ViewChild } from '@angular/core';
import { HostListener } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink,} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import * as _ from "lodash";
declare var $: any;
declare var require: any;

@Component({
  selector: 'app-output-monthlyrisk',
  templateUrl: './output-monthlyrisk.component.html',
  styleUrls: ['./output-monthlyrisk.component.css']
})
export class OutputMonthlyriskComponent implements OnInit {
  
  show_table=false;
  isLoading=false;
  id:string; 
  data={};
  sub: any;
  analysis_horizon_keys = []; 
  selectedAnalysisHorizon:any;
  analysis_horizon:any;
 
  model_names_list = []
  selected_model_list = []
  tax_list = ["aftertax", "pretax"]

  //percentile_filter = ["ES_01", "ES_05", "ES_10", "ES_20", "exp_value", "q_01", "q_05", "q_10", "q_20"]
  //percentile_filter = ["ES_01", "ES_05", "ES_10", "ES_20", "q_01", "q_05", "q_10", "q_20"]

  //percentile_filter = ['ExpValue','Quantile','ES']
  percentile_filter = ['Exp. Value','at Risk','ES']
 
  tax_array = [{'name':'Pre Tax','id':'pretax'}]
  type_array = [{'name':'Earnings','id':'E'},{'name':'Cash flow','id':'CF'}]
  percentile_array = [
                      {'name':'05','id':'05'},
                      {'name':'10','id':'10'}
                      ]
  selectedPercentile='05';
  selectedTypeName='Earnings';
  selectedType='Earnings';
  selectTaxType = 'pretax'
  selectedPercentileFilter = "Exp. Value"




  endpoint='/output'

  selectedChartType = 'dist'

  color_array = ['#9C27B0','#FF9800','#009688','#CDDC39']

  myForm: FormGroup;
  myFormModels = new FormArray([]);

  constructor(private route: ActivatedRoute,
              private _router: Router,
              private _apiService: ApiService,
              private fb: FormBuilder) { 

              this.myForm = fb.group({
                  selected_models_list: this.myFormModels
              })

              this.route.params.map(params => params['id']).subscribe(id => { 
                                    this.id=id
                                   
              });

              let id = this.id
              let type = 'monthlyrisktables'
              this.makeDistCharts(id,type)


  } //end constructor

  ngOnInit() {


  } //end ngOnInit



  cardwidth =  510;
  newInnerWidth:any;
  newInnerHeight:any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerHeight = event.target.innerHeight;
    this.newInnerWidth = event.target.innerWidth;
    this.cardwidth =  +this.newInnerWidth - 510 - 20;
  }




  test={}
   makeDistCharts(id,type){

      this._apiService.getOutput(this.endpoint,id,type)
                      .subscribe(data => {
                          this.data = data
                        
                          
                         
                          let temp_data = this.data["pretax"]["Earnings"]["exp_value"]
                          for (var _i = 0; _i < temp_data.length; _i++) {
                            this.model_names_list.push(temp_data[_i]["Model Name"])
                          } 
                          this.model_names_list = _.sortedUniq(this.model_names_list);
                          

                         

                          
                          //this.model_names_list = Object.keys(this.data);
                          //this.selected_model_list = this.model_names_list;

                           for (var _i = 0; _i < this.model_names_list.length; _i++) {
                               this.myFormModels.push(new FormControl(true));
                               this.selected_model_list.push(this.model_names_list[_i])
                               /*
                               if (_i<3){
                                    this.myFormModels.push(new FormControl(true));
                                    this.selected_model_list.push(this.model_names_list[_i])
                                } else {
                                    this.myFormModels.push(new FormControl(false));
                                }
                                */
                            }

                          let first_model = this.model_names_list[0]
                          let tax_type = this.selectTaxType
                          //let analysis_horizon_keys = Object.keys(this.data[first_model][tax_type]);

                      
                          

                          let tt = Object.keys(temp_data[0])
                          let temp = []
                          for (var _i = 0; _i < tt.length-1; _i++) {
                            temp.push(`M${_i+1}`)
                          }
                          this.analysis_horizon_keys = temp
                          this.selectedAnalysisHorizon = this.analysis_horizon_keys.slice(-1)[0];

                          let ah = this.selectedAnalysisHorizon;

                          
                          this.updateData()

                      }
                      ,null,() => { this.isLoading = false;
                                    this.show_table=true;
                        //this.busyService.announceBusy(false); 
                      
                      });


    } //end makeDistCharts

    top_left = {}
    bottom_left = {}

    updateData(){
     
      //let tl = this.data[this.selectTaxType][this.selectedType][this.selectedPercentileFilter]

      /*
        percentile_filter = ['ExpValue','Quantile','ES']
        selectedPercentile='01';
        selectedType='Earnings';
      */

      let flit = ''
      if (this.selectedPercentileFilter=='Exp. Value'){
        flit = "exp_value"
      }
      if (this.selectedPercentileFilter=='at Risk'){
        flit = `q_${this.selectedPercentile}`
      }
      if (this.selectedPercentileFilter=='ES'){
        flit = `ES_${this.selectedPercentile}`
      }


     



      /* ---------------------------TOP ------------------------- */
      let tl = this.data[this.selectTaxType]["Earnings"][flit]
      
      for (var _i = 0; _i < this.analysis_horizon_keys.length; _i++) {
        this.top_left[this.analysis_horizon_keys[_i]]={}
        for (var _j = 0; _j < this.selected_model_list.length; _j++) {
          this.top_left[this.analysis_horizon_keys[_i]][this.selected_model_list[_j]]=0
        }
      }

      for (var _i = 0; _i < tl.length; _i++) {
        let model_name = tl[_i]["Model Name"];
        for (var _j = 0; _j < this.analysis_horizon_keys.length; _j++) {
          this.top_left[this.analysis_horizon_keys[_j]][model_name]= tl[_i][this.analysis_horizon_keys[_j]]
        }
      }



       /* ---------------------------BOTTOM------------------------- */

       /*
        tl = this.data[this.selectTaxType]["Cash flow"][flit]
      
        for (var _i = 0; _i < this.analysis_horizon_keys.length; _i++) {
            this.bottom_left[this.analysis_horizon_keys[_i]]={}
            for (var _j = 0; _j < this.selected_model_list.length; _j++) {
            this.bottom_left[this.analysis_horizon_keys[_i]][this.selected_model_list[_j]]=0
            }
        }

        for (var _i = 0; _i < tl.length; _i++) {
            let model_name = tl[_i]["Model Name"];
            for (var _j = 0; _j < this.analysis_horizon_keys.length; _j++) {
            this.bottom_left[this.analysis_horizon_keys[_j]][model_name]= tl[_i][this.analysis_horizon_keys[_j]]
            }
        }
        */





      

    }

    onTaxTypeChange(){
        this.updateData()
    }
    onPercentileChange(){
         this.updateData()
    }

    onPercentileFilterChange(){
        this.updateData()
    }

    onTypeChange(){
        this.updateData()
    }

    onCbChange(i){
        
     
        this.selected_model_list = []
        let sel = this.myForm.controls['selected_models_list'].value
        for (var _i = 0; _i < sel.length; _i++) {
            if (sel[_i]==true){
                this.selected_model_list.push(this.model_names_list[_i])
            }
        }
        

        //must have one value checked
        if (this.selected_model_list.length>0){
            this.updateData()
        } else {
            let tt = []
            for (var _i = 0; _i < sel.length; _i++) {
                tt.push(false)
            }
            tt[i]=true
            this.myForm.controls['selected_models_list'].setValue(tt)
        }
    } 



}
 



































 /*


  data = [
    {id: 1, flag: 'EUR', currencyCode: 'EUR', currency: 'Euro',	level: 0.9033, units: 'EUR / USD', asOf: '08/19/2015', onedChng: 0.0026},
    {id: 2, flag: 'JPY', currencyCode: 'JPY', currency: 'Japanese Yen', level: 124.3870, units: 'JPY / USD', asOf: '08/19/2015', onedChng: 0.0001},
    {id: 3, flag: 'GBP', currencyCode: 'GBP', currency: 'Pound Sterling', level: 0.6396, units: 'GBP / USD', asOf: '08/19/2015', onedChng: 0.00},
    {id: 4, flag: 'CHF', currencyCode: 'CHF', currency: 'Swiss Franc',	level: 0.9775, units: 'CHF / USD', asOf: '08/19/2015', onedChng: 0.0008},
    {id: 5, flag: 'CAD', currencyCode: 'CAD', currency: 'Canadian Dollar',	level: 1.3097, units: 'CAD / USD', asOf: '08/19/2015', onedChng: -0.0005},
    {id: 6, flag: 'AUD', currencyCode: 'AUD', currency: 'Australian Dollar',	level: 1.3589, units: 'AUD / USD', asOf: '08/19/2015', onedChng: 0.0020},
    {id: 7, flag: 'NZD', currencyCode: 'NZD', currency: 'New Zealand Dollar',	level: 1.5218, units: 'NZD / USD', asOf: '08/19/2015', onedChng: -0.0036},
    {id: 8, flag: 'SEK', currencyCode: 'SEK', currency: 'Swedish Krona',	level: 8.5280, units: 'SEK / USD', asOf: '08/19/2015', onedChng: 0.0016},
    {id: 9, flag: 'NOK', currencyCode: 'NOK', currency: 'Norwegian Krone',	level: 8.2433, units: 'NOK / USD', asOf: '08/19/2015', onedChng: 0.0008},
    {id: 10, flag: 'BRL', currencyCode: 'BRL', currency: 'Brazilian Real',	level: 3.4806, units: 'BRL / USD', asOf: '08/19/2015', onedChng: -0.0009},
    {id: 11, flag: 'CNY', currencyCode: 'CNY', currency: 'Chinese Yuan',	level: 6.3961, units: 'CNY / USD', asOf: '08/19/2015', onedChng: 0.0004},
    {id: 12, flag: 'RUB', currencyCode: 'RUB', currency: 'Russian Rouble',	level: 65.5980, units: 'RUB / USD', asOf: '08/19/2015', onedChng: 0.0059},
    {id: 13, flag: 'INR', currencyCode: 'INR', currency: 'Indian Rupee',	level: 65.3724, units: 'INR / USD', asOf: '08/19/2015', onedChng: 0.0026},
    {id: 14, flag: 'TRY', currencyCode: 'TRY', currency: 'New Turkish Lira',	level: 2.8689, units: 'TRY / USD', asOf: '08/19/2015', onedChng: 0.0092},
    {id: 15, flag: 'THB', currencyCode: 'THB', currency: 'Thai Baht',	level: 35.5029, units: 'THB / USD', asOf: '08/19/2015', onedChng: 0.0044},
    {id: 16, flag: 'IDR', currencyCode: 'IDR', currency: 'Indonesian Rupiah',	level: 13.83, units: 'IDR / USD', asOf: '08/19/2015', onedChng: -0.0009},
    {id: 17, flag: 'MYR', currencyCode: 'MYR', currency: 'Malaysian Ringgit',	level: 4.0949, units: 'MYR / USD', asOf: '08/19/2015', onedChng: 0.0010},
    {id: 18, flag: 'MXN', currencyCode: 'MXN', currency: 'Mexican New Peso',	level: 16.4309, units: 'MXN / USD', asOf: '08/19/2015', onedChng: 0.0017},
    {id: 19, flag: 'ARS', currencyCode: 'ARS', currency: 'Argentinian Peso',	level: 9.2534, units: 'ARS / USD', asOf: '08/19/2015', onedChng: 0.0011},
    {id: 20, flag: 'DKK', currencyCode: 'DKK', currency: 'Danish Krone',	level: 6.7417, units: 'DKK / USD', asOf: '08/19/2015', onedChng: 0.0025},
    {id: 21, flag: 'ILS', currencyCode: 'ILS', currency: 'Israeli New Sheqel',	level: 3.8262, units: 'ILS / USD', asOf: '08/19/2015', onedChng: 0.0084},
    {id: 22, flag: 'PHP', currencyCode: 'PHP', currency: 'Philippine Peso',	level: 46.3108, units: 'PHP / USD', asOf: '08/19/2015', onedChng: 0.0012}
  ];
  currencyCodes = ['EUR', 'JPY', 'GBP', 'CHF', 'CAD', 'AUD', 'NZD', 'SEK', 'NOK', 'BRL', 'CNY', 'RUB', 'INR', 'TRY', 'THB', 'IDR', 'MYR', 'MXN', 'ARS', 'DKK', 'ILS', 'PHP'];
  columns: [
        {
            data: 'id',
            type: 'numeric',
            width: 40
        },
        {
            data: 'flag',
			       type: 'text'
        },
        {
            data: 'currencyCode',
            type: 'text'
        },
        {
            data: 'currency',
            type: 'text'
        },
        {
            data: 'level',
            type: 'numeric',
            format: '0.0000'
        },
        {
            data: 'units',
            type: 'text'
        },
        {
            data: 'asOf',
            type: 'date',
            dateFormat: 'MM/DD/YYYY'
        },
        {
            data: 'onedChng',
            type: 'numeric',
            format: '0.00%'
        }
    ]

  colHeaders: [
        'ID',
        'Country',
        'Code',
        'Currency',
        'Level',
        'Units',
        'Date',
        'Change'
    ]
 
  private colWidths: number[] = [null, null, null, null, null, null, 30];


  options:any = {
    stretchH: 'all',
    columnSorting: true,
    dropdownMenu: true,
    filters: true,
    height: 800,
    contextMenu: [
      'row_above', 'row_below', 'remove_row'
    ]
  };


  options = {
      height: 396,
      colHeaders: true,
      rowHeaders: true,
      stretchH: 'all',
      columnSorting: true,
      contextMenu: true,
      dropdownMenu: true,
      filters: true,
    };


  dataDyn: any[] = [];

  columnsDyn:Array<any> = [
        {data: 0, type: 'text',readOnly: false}, //name
        {data: 1,type: 'text',readOnly: false}, //vs
        {data: 2,type: 'text',readOnly: false}, //ndf
        {data: 3,type: 'numeric',readOnly: false, format: '0.00'}, //etr
        {data: 4,type: 'numeric',readOnly: false}, //lag
        {data: 5,type: 'numeric',readOnly: false}, //init
        {data: 6,type: 'numeric',readOnly: false}, //mat
        {data: 7,type: 'numeric',readOnly: false,format: '0.00'},
        {data: 8,type: 'numeric',readOnly: false,format: '0.00'},
        {data: 9,type: 'text',readOnly: false},
        {data: 10,type: 'text',readOnly: false},
        {data: 11,type: 'numeric',readOnly: false},
        {data: 12,type: 'numeric',readOnly: false,format: '0.00'},
  ];

  colHeadersDyn:Array<string> = [
        'Name',
        'vs',
        'NDF',
        'Tax',
        'Lag', 
        'Start',
        'End',
        'Total Amount',
        'Amount',
        'Type',
        'Info',
        'Strike',
        "Strike %"
  ];

    optionsDyn:any = {
    stretchH: 'all',
    columnSorting: true,
    dropdownMenu: true,
    filters: true,
    height: 800,
    contextMenu: [
      'row_above', 'row_below', 'remove_row'
    ]
  };


 */