import {Component, OnInit, OnDestroy,Output,EventEmitter,Renderer,Provider, forwardRef, AfterViewInit} from '@angular/core';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { FormComponent } from '../can_deactivate/prevent_unsaved_changes_gaurd.service';
import { LoggedinService } from '../loggedin.service';
import { BusyService } from '../busy.service';
import { ModelRefreshService } from '../model-refresh.service';

import * as _ from "lodash";
 
@Component({
  selector: 'app-mesa-output-outputs',
  templateUrl: './mesa-output-outputs.component.html',
  styleUrls: ['./mesa-output-outputs.component.css']
})
export class MesaOutputOutputsComponent implements OnInit, OnDestroy {

   var_hash_list = [];
   endpoint='/test/model/output/output';
   modelrefresh_sub:any;

   //this.router.navigateByUrl('output/(output:details/(data:distributions;id=11))');

   scenarios_name_list = [] 

  constructor(private loggedinService: LoggedinService,
                private busyService: BusyService,
                private route: ActivatedRoute,
                private modelrefreshService: ModelRefreshService,
                private fb: FormBuilder,
                private _router: Router,
                private renderer: Renderer,
                private _apiService: ApiService) { }

  model_id_hash = {}

  existing_hedge_models = {}
  existing_risk_models = {}
  

  createModelHash(data){

    let model_list =  []
    let var_hash_list = []
    let model_id_hash = {}

    let models = data['results'];
       
    for (var _i = 0; _i < models.length; _i++) {
     
         model_id_hash[models[_i]['model_id']]=models[_i]
 
       
         this.model_id_hash[models[_i]['model_id']]=models[_i]

         if (models[_i]['data']['type']=='risk'){
             this.existing_risk_models[models[_i]['model_id']]=models[_i]
         }

         if (models[_i]['data']['type']=='hedging'){
           this.existing_hedge_models[models[_i]['model_id']]=models[_i]
         }
         

         if (models[_i]['data'].hasOwnProperty('var')){
     
           for (var _j = 0; _j < models[_i]['data']['var'].length; _j++) {
               var_hash_list.push(models[_i]['data']['var'][_j])
             }
           }
           
           /*
           if (models[_i]['data'].hasOwnProperty('scenarios')){
             
             for (var _j = 0; _j < models[_i]['data']['scenarios'].length; _j++) {
               this.var_hash_list.push(models[_i]['data']['scenarios'][_j])
             }
   
           }
           */
    } //end for

     //clean hedge
     for (var _i = 0; _i < var_hash_list.length; _i++) {
          let temp = {}
          let var_hedge_name_list = []
          let my_form_hedging_list_use = []
         
          
          for (var key in var_hash_list[_i]['hedge_list_hash']){
            if (this.existing_hedge_models.hasOwnProperty(key)){
              if (var_hash_list[_i]['hedge_list_hash'][key]==true){
                temp[key]=var_hash_list[_i]['hedge_list_hash'][key]
                my_form_hedging_list_use.push(var_hash_list[_i]['hedge_list_hash'][key])
                var_hedge_name_list.push(model_id_hash[key]['data']['name'])
              }
            }
          }
          var_hash_list[_i]['hedge_list_hash']=temp
          var_hash_list[_i]['my_form_hedging_list_use']=my_form_hedging_list_use
          var_hash_list[_i]['var_hedge_name_list']=var_hedge_name_list
      }
    

      //clean risk
      for (var _i = 0; _i < var_hash_list.length; _i++) {
        let temp = {}
        let var_name_list = []
        let my_form_risk_list_use = []
       
         
        for (var key in var_hash_list[_i]['risk_list_hash']){
          if (this.existing_risk_models.hasOwnProperty(key)){
            if (var_hash_list[_i]['risk_list_hash'][key]==true){
              temp[key]=var_hash_list[_i]['risk_list_hash'][key]
              my_form_risk_list_use.push(var_hash_list[_i]['risk_list_hash'][key])
              var_name_list.push(model_id_hash[key]['data']['name'])
            }
          }
        }

     
        var_hash_list[_i]['risk_list_hash']=temp
        var_hash_list[_i]['my_form_risk_list_use']=my_form_risk_list_use
        var_hash_list[_i]['var_name_list']=var_name_list
    }

     
     this.var_hash_list = var_hash_list
     


  } // createModelHash
  
  ngOnInit() {
    

    
    this.modelrefresh_sub = this.modelrefreshService.modelrefreshAnnounced$.subscribe(
      data1 => {
        this._apiService.getModels('/test/model').subscribe(data2 => {
          let data3 = {'results':data2}
          this.createModelHash(data3)
        }
        ,null,() => { }
       );//end apiService

    }); //end modelrefresh
    
    
     this.route.data.forEach((data) => {
         this.createModelHash(data)
      
     }); //end forEach

  
     
} //end ngOnInit

ngOnDestroy(){
  if(this.modelrefresh_sub){
    this.modelrefresh_sub.unsubscribe();
  }
} //end OnDestroy

  editOut(model){
      this._router.navigate(['/output', {outlets: {'output': [model['tab'],{idx:model['idx']}]}}]);
  }

  deleteOut(model,index){

         this.var_hash_list.splice(index, 1);
         this._apiService.deleteModel(this.endpoint,model.idx)
            .subscribe(null, 
              err => {
                alert("Could not delete the user.");
                            // Revert the view back to its original state
                            // by putting the user object at the index
                            // it used to be.
                //this.models.splice(index, 0, model);
              });
  } // end deleteOut

  viewOut(model){
    //this.router.navigateByUrl('output/(output:details/(data:distributions;id=11))');
    //this._router.navigate(['/output', {outlets: {'output': ['details',{idx:model['idx']}]}}]);
    
    this._router.navigate( ['/output', {outlets: {'output': ['details',{'id':model['idx']},{outlets:{'data':['distributions',{'id':model['idx']}]}}]}}] )
  }

  json_list = {}
  download(model){

    let fileName = model['options_name'] 

    let json_list = {}
    //json_list['risk_models']=[]
    //json_list['hedge_models']=[]

    json_list['risk_models']={}
    json_list['hedge_models']={}


    for (var key in model['risk_list_hash']){
      if (model['risk_list_hash'][key]==true){
       
          if (this.model_id_hash.hasOwnProperty(key)==true){
            json_list['risk_models'][key]=this.model_id_hash[key]
          }
       }
    }
    for (var key in model['hedge_list_hash']){
      if (model['hedge_list_hash'][key]==true){
      
          if (this.model_id_hash.hasOwnProperty(key)==true){
            json_list['hedge_models'][key]=this.model_id_hash[key]
          } 
       }
    }

    let temp = JSON.stringify(json_list) 
    let fileName_t = fileName + '.json'
    this.buildDownloader(fileName_t,temp);
  } //download

private getDocumentBody(): any {
  return document.body;
} //end getDocumentBody 

 private buildDownloader(fileName,data) {

    let anchor = this.renderer.createElement(this.getDocumentBody(), 'a');
    this.renderer.setElementStyle(anchor, 'visibility', 'hidden');
    this.renderer.setElementAttribute(anchor, 'href', 'data:application/json;charset=utf-8,' + encodeURIComponent(data));
    this.renderer.setElementAttribute(anchor, 'target', '_blank');
    this.renderer.setElementAttribute(anchor, 'download', fileName);

    setTimeout(() => {
      this.renderer.invokeElementMethod(anchor, 'click');
      this.renderer.invokeElementMethod(anchor, 'remove');
    }, 5);

  } // buildDownloader

}
