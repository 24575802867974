import {Component, OnInit, Output,EventEmitter,Provider, forwardRef, AfterViewInit} from '@angular/core';
import { HostListener } from '@angular/core';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { ModelValidators } from '../validators/validators';
import { CURRENCIES, COMMODITIES } from '../object_lists/assets_object';

import { FormComponent } from '../can_deactivate/prevent_unsaved_changes_gaurd.service';
import { LoggedinService } from '../loggedin.service';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import * as _ from "lodash";

@Component({
  selector: 'app-mesa-hedge-panel',
  templateUrl: './mesa-hedge-panel.component.html',
  styleUrls: ['./mesa-hedge-panel.component.css']
})
export class MesaHedgePanelComponent implements OnInit, FormComponent {

  endpoint='/test/model/hedgepanel';
 
  selectedAsset = "Select Currency";
  exposure_model_id:string;
  model_id:string;
  this_data:any;
  hedge_data:any;
  myForm: FormGroup; 

  crud:string;

  base_currency:string;

  //currency_array = CURRENCIES;
  //commodity_array = COMMODITIES;
  currency_array: any[] = []; // = CURRENCIES;
  commodity_array: any[] = [];

  ndf_na_hash = {};
  alreadySelected:Object;

  myFormArrayExp =  new FormArray([]);
  myFormArrayEtr =  new FormArray([]);
  myFormArrayLag =  new FormArray([]);
  myFormArrayNdf =  new FormArray([]);
  myFormArrayVs =  new FormArray([]);
  myFormArrayRatio =  new FormArray([]);
  myFormArrayBase =  new FormArray([]);

  asset_id_list_fx: any[] = [];
  asset_id_list_comm: any[] = [];
  asset_id_list: any[] = [];

  etr_array: any[] = [];
  ndf_array: any[] = [];
  ratio_array: any[] = [];
  vs_array: any[] = [];

  hideme = {};
  asset_array = {}
  asset_other = {}
  fu_array = {}
  parent_hash = {}
  asset_object: any[] = [];
  is_parent: any[] = [];
  lag_array: any[] = [];

  exposure_array: any[] = [];

  deferred_earnings_array: any[] = [];
  horizon:any;

   myFormArrayLayering =  new FormArray([]);
   
   
   

   navLinks = []

  selectedExposure:string;

  exposure_static_list = []
  policy_object = {}
  instrument_object = {}
  policy_array_object = []
  instrument_array_object = []


  idx:number;
  exposure_idx:number = 0;
  asset_type:string;



  
  L_S = ['Long','Short']
  C_P =  ['Call','Put']

  instrument_fx = ['Forward','Option'] //['Forward','Option','Collar']
  instrument_comm = ['Futures','Option'] //['Futures','Option','Swap']

  policy_fx = ['Forward','Option'] //['Forward','Option','Collar']
  policy_comm = ['Futures','Option']

  selectedInstrumentFx:string;
  selectedInstrumentComm:string
  selectedPolicyFx:string;
  selectedPolicyComm:string;


    instruments_forward_fx: FormArray;
    instruments_option_fx: FormArray;
    instruments_future_comm: FormArray;
    instruments_option_comm: FormArray;

    policy_forward_fx: FormArray;
    policy_option_fx: FormArray;
    policy_future_comm: FormArray;
    policy_option_comm: FormArray;


     

  model_name:string;
  constructor( private loggedinService: LoggedinService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private _apiService: ApiService) { 

        this.myForm = this.fb.group({
             instruments_forward_fx: this.buildArray(),
             instruments_option_fx: this.buildOptionArray(),
             instruments_future_comm: this.buildFutureCommArray(),
             instruments_option_comm: this.buildOptionCommArray(),

             policy_forward_fx: this.buildPolicyForwardFxArray(),
             policy_option_fx: this.buildPolicyOptionFxArray(),
             policy_future_comm: this.buildPolicyFutureCommArray(),
             policy_option_comm: this.buildPolicyOptionCommArray()
         })   

         this.model_name = localStorage.getItem('model_name');
  } // end constructor

  cardwidth =  1100;
  //[style.width.px]="parentcardwidth"
  parentcardwidth = 1292;
  

  newInnerWidth:any;
  newInnerHeight:any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerHeight = event.target.innerHeight;
    this.newInnerWidth = event.target.innerWidth;
    this.cardwidth =  +this.newInnerWidth - 192 - 20;
  }





    myFormArrayHorizon: FormArray;
    buildPolicyForwardFxArray(){
        return this.policy_forward_fx = this.fb.array([])
    }
    buildPolicyForwardFxGroup(){
        let fc = []
        for (var _j = 0; _j < this.horizon; _j++) {
            fc.push(new FormControl())
        }
        return this.fb.group({
            exposure:'',
            vs: 'USD',
            ndf: [false],
            etr: '',
            lag:'',
            tag:'',
            my_form_array_horizon:  new FormArray(fc)
         })
        // my_form_array_exp:  new FormArray([new FormControl(),new FormControl()])
    }

    
    buildPolicyOptionFxArray(){
        return this.policy_option_fx = this.fb.array([])
    }
    buildPolicyOptionFxGroup(){
        let fc = []
        for (var _j = 0; _j < this.horizon; _j++) {
            fc.push(new FormControl())
        }
        return this.fb.group({
            exposure:'',
            vs: 'USD',
            ndf: [false],
            etr: '',
            lag:'',
            tag:'',
            strike:[''],
            strike_p:[''],
            my_form_array_horizon:  new FormArray(fc)
         })
    }
    buildPolicyFutureCommArray(){
        return this.policy_future_comm = this.fb.array([])
    } buildPolicyFutureCommGroup(){
        let fc = []
        for (var _j = 0; _j < this.horizon; _j++) {
            fc.push(new FormControl())
        }
        return this.fb.group({
            exposure:'',
            vs: 'USD',
            etr: '',
            lag:'',
            tag:'',
            my_form_array_horizon:  new FormArray(fc)
         })
    }
    buildPolicyOptionCommArray(){
        return this.policy_option_comm = this.fb.array([])
    }
    buildPolicyOptionCommGroup(){
        let fc = []
        for (var _j = 0; _j < this.horizon; _j++) {
            fc.push(new FormControl())
        }
         return this.fb.group({
            exposure:'',
            vs: 'USD',
            etr: '',
            lag:'',
            tag:'',
            strike:[''],
            strike_p:[''],
            my_form_array_horizon:  new FormArray(fc)
         })
    }
  



  buildFutureCommArray(){
    return this.instruments_future_comm = this.fb.array([
            //this.buildGroup()
    ])
  }

  buildFutureCommGroup(){
        return this.fb.group({
            exposure:'',
            vs: 'USD',
            etr: '',
            lag:'',
            tag:'',
            origination: '',
            maturity:'',
            notional:'',
            price:[''],
            contract_name:[''],
        });
  }

  buildOptionCommArray(){
      return this.instruments_option_comm = this.fb.array([
            //this.buildGroup()
        ])
      
  
    }

   buildOptionCommGroup(){
        return this.fb.group({
            exposure:'',
            vs: 'USD',
            etr: '',
            lag:'',
            tag:'',
            origination: '',
            maturity:'',
            notional:'',
            premium:[''],
            strike:[''],
            strike_p:[''],
            contract_name:['']
        });
  }

  buildOptionArray(): FormArray {


        this.instruments_option_fx = this.fb.array([
            //this.buildGroup()
        ])
        return this.instruments_option_fx
    }

  buildArray(): FormArray {
        this.instruments_forward_fx = this.fb.array([
            //this.buildGroup()
        ])
        return this.instruments_forward_fx
    }
  
buildOptionGroup(): FormGroup {
    return this.fb.group({
        exposure:'',
        vs: 'USD',
        ndf: [false],
        etr: '',
        lag:'',
        tag:'',
        l_s:'Long',
        c_p:'Call',
        origination: '',
        maturity:'',
        notional:'',
        premium:[''],
        strike:[''],
        strike_p:['']
    });
}

  buildGroup(): FormGroup {
        return this.fb.group({
            exposure:'',
            vs: 'USD',
            ndf: [false],
            etr: '',
            lag:'',
            tag:'',
            origination: '',
            maturity:'',
            notional:'',
            price:['']
        });
    }


  exposure_tag_array = []
  exposure_rand_array = []
  currency_list = []
  data_results:any;
  ngOnInit() {

    this.currency_array = JSON.parse(localStorage.getItem('currency_list'));
    this.commodity_array = JSON.parse(localStorage.getItem('commodity_list'));
    
    this.alreadySelected = {};
    this.route.params.map(params => params['id']).subscribe(id => { this.model_id=id});
    this.route.params.map(params => params['exposuremodelid']).subscribe(exposuremodelid => { this.exposure_model_id=exposuremodelid });
    this.route.params.map(params => params['idx']).subscribe(idx => { this.idx=idx });

    this.route.data.forEach((data) => {

                console.log("data['results']",data['results'])
              
                for (var key in data['results']) {
                  
                    console.log('aa')
                    if (this.model_id==data['results'][key]['model_id']){
                        console.log('bb')
                            this.this_data = data['results'][key]
                            console.log('cc')
                    }
                   
                    console.log('dd')
                    if (this.exposure_model_id==data['results'][key]['data']['model_id']){
                        console.log('ee')
                            this.exposure_model_id = data['results'][key]['model_id'];
                            console.log('ff')
                            this.hedge_data=data['results'][key];
                            console.log('gg')
                            this.horizon = +data['results'][key]['data']['data']['hedgeinitial']["analysis_horizon"]
                            console.log('hh')
                    }
                    console.log('ii')
                } // var key in data['results']
                
               console.log('a')
               
                if (this.this_data['data']['data'].hasOwnProperty('hedgepanel')===false){
                    this.crud='new'; 
                } else {
                    this.crud='edit';

                    
                    let etr_currencies = this.hedge_data['data']['data']["hedgeexpfx"]["etr"];
                    let etr_commodities = this.hedge_data['data']['data']["hedgeexpcom"]["etr"];
                    let lag_currencies = this.hedge_data['data']['data']["hedgeexpfx"]["lag"];
                    let lag_commodities = this.hedge_data['data']['data']["hedgeexpcom"]["lag"];
                    let horizon_currencies_array = this.hedge_data['data']['data']["hedgeexpfx"]["exp"];
                    let horizon_commodities_array = this.hedge_data['data']['data']["hedgeexpcom"]["exp"];
                    let chunked_currencies = _.chunk(horizon_currencies_array, this.horizon); 
                    let chunked_commodities = _.chunk(horizon_commodities_array, this.horizon);
                 

                    let used_currencies = this.hedge_data['data']['data']["hedgeexpfx"]["currencies"];
                    let used_commodities = this.hedge_data['data']['data']["hedgeexpcom"]["commodities"];
                    this.exposure_array = used_currencies.concat(used_commodities);
                     
                    let tag_currencies = this.hedge_data['data']['data']["hedgeexpfx"]["tag"];
                    let tag_commodities = this.hedge_data['data']['data']["hedgeexpcom"]["tag"];
                    this.exposure_tag_array = tag_currencies.concat(tag_commodities);

                    let rand_currencies = this.hedge_data['data']['data']["hedgeexpfx"]["rand"];
                    let rand_commodities = this.hedge_data['data']['data']["hedgeexpcom"]["rand"];
                    for (var _i = 0; _i < this.exposure_array.length; _i++) {
                        let ttt = this.exposure_array[_i] + '_' + this.exposure_tag_array[_i];
                        this.exposure_rand_array.push(ttt);
                    }

                    console.log('b')
                   
                    //this.exposure_tag_array = this.this_data['data']['data']['hedgepanel']['exposure_tag_array']
                    //this.exposure_rand_array = this.this_data['data']['data']['hedgepanel']['exposure_rand_array']
                    this.exposure_static_list = this.this_data['data']['data']['hedgepanel']['exposure_static_list']
                    this.policy_object = this.this_data['data']['data']['hedgepanel']['policy_object']
                    this.instrument_object = this.this_data['data']['data']['hedgepanel']['instrument_object']
                    this.policy_array_object = this.this_data['data']['data']['hedgepanel']['policy_object']
                    this.instrument_array_object = this.this_data['data']['data']['hedgepanel']['instrument_object']

                  
                    /*
                    find if new tag
                    THIS section will fnd if a new currency needs to be added then adds 
                    */
                    let temp_tag = []
                    let temp_exp = []
                    for (var _i = 0; _i < this.exposure_static_list.length; _i++) {
                        temp_tag.push(this.exposure_static_list[_i]['exposure']+'_'+this.exposure_static_list[_i]['tag'])
                    }

                    var dif_rand = _.differenceWith(this.exposure_rand_array,temp_tag, _.isEqual);
                  
                    //create {'EUR_1':1,'EUR_2':2} from list tp creat 
                    let index_hash = {}
                    for (var _i = 0; _i < this.exposure_rand_array.length; _i++) {
                        index_hash[this.exposure_rand_array[_i]]=_i
                    }

                    console.log('c')

                    if (dif_rand){
                        for (var _i = 0; _i < dif_rand.length; _i++) {
                           
                            let tt = {}
                            let exp_ = _.split(dif_rand[_i], '_')[0]
                            let tag_ = _.split(dif_rand[_i], '_')[1]
                            tt['exposure']=exp_; 
                            tt['vs']='USD' 
                            tt['etr']=etr_currencies[index_hash[dif_rand[_i]]] 
                            tt['lag']=lag_currencies[index_hash[dif_rand[_i]]] 
                            tt['exp']=chunked_currencies[index_hash[dif_rand[_i]]] 
                            tt['ndf']=false;
                            tt['tag']=tag_;
                            this.exposure_static_list.push(tt)
                        }
                    }

                    /*
                    end find new currencies
                    */


                   console.log('d')



                  
                    //let used_currencies = this.hedge_data['data']['data']["hedgeexpfx"]["currencies"];
                    //let used_commodities = this.hedge_data['data']['data']["hedgeexpcom"]["commodities"];
                    //this.exposure_array = used_currencies.concat(used_commodities);
                    for (var _j = 0; _j < this.horizon; _j++) {
                        this.deferred_earnings_array.push(1+_j);
                    } 
                   
                    this.selectedExposure = this.exposure_static_list[0]['exposure']
                    this.exposure_idx= this.exposure_array.indexOf(this.selectedExposure );

                }

                console.log('e')

                for (var _i = 0; _i < this.currency_array.length; _i++) {
                    this.ndf_na_hash[this.currency_array[_i].displayname]=this.currency_array[_i].NDF;
                    this.currency_list.push(this.currency_array[_i].displayname)
                }

                this.currency_list=['USD']

                
             




               
                this.getNextExposure()
    }) // end this.route
  } //end ngOnInit 

  isShowTableFx(ints_or_policy,value){
    return false
  }
   

  is_showtable_instrument_fx_foward = false;
  is_showtable_instrument_fx_option = false;
  is_showtable_policy_fx_foward = false;
  is_showtable_policy_fx_option = false;
  isShowTableFxForward(ints_or_policy,value){


        try {
                let test = this.instrument_object[this.selectedExposure]['fx']['Forward']
                if (test.length>0){
                    return true
                }     else {
                    return false
                }

        } catch (e) {
            return false
        }

  } //isShowTable()
  isShowTableFxOption(ints_or_policy,value){

        try {
                let test = this.instrument_object[this.selectedExposure]['fx']['Option']
                if (test.length>0){
                    return true
                } else {
                    return false
                }

        } catch (e) {
            return false
        }
 

  } //isShowTable()
  isShowTableComm(ints_or_policy,value){

    if (this.asset_type=='comm' && ints_or_policy=='Instrument' && value=='Futures'){
        try {
                let test = this.instrument_object[this.selectedExposure][this.asset_type][value]
                if (test.length>0){
                    return true
                
                }     else {
                    return false
                }

        } catch (e) {
            return false
        }
    }

    if (this.asset_type=='comm' && ints_or_policy=='Instrument' && value=='Option'){
        try {
                let test = this.instrument_object[this.selectedExposure][this.asset_type][value]
                if (test.length>0){
                    return true
                
                } else {
                    return false
                }

        } catch (e) {
            return false
        }
    }

    return false
  } //isShowTable()

  deleteModel(object_type,idx){
    
    //"deleteModel('instruments_option_fx',i)"

    /*
    <tbody formArrayName="instruments_option_fx">
        <tr *ngFor="let instrument of instruments_option_fx.controls;let i=index" [formGroupName]="i">

    */

    //INSTRUMENT
    if (object_type=='instruments_forward_fx'){
        let rand = this.exposure_rand_array[this.exposure_idx]
        let tt = this.instrument_object[rand][this.selectedExposure]['fx']['Forward']
        tt.splice(idx, 1)
        this.instrument_object[rand][this.selectedExposure]['fx']['Forward']=tt
        this.instruments_forward_fx.removeAt(idx)
    }

    if (object_type=='instruments_option_fx'){
        let rand = this.exposure_rand_array[this.exposure_idx]
        let tt = this.instrument_object[rand][this.selectedExposure]['fx']['Option']
        tt.splice(idx, 1)
        this.instrument_object[rand][this.selectedExposure]['fx']['Option']=tt
        this.instruments_option_fx.removeAt(idx)
    }

    if (object_type=='instruments_future_comm'){
        let rand = this.exposure_rand_array[this.exposure_idx]
        let tt = this.instrument_object[rand][this.selectedExposure]['comm']['Future']
        tt.splice(idx, 1)
        this.instrument_object[rand][this.selectedExposure]['comm']['Future']=tt
        this.instruments_future_comm.removeAt(idx)
    }

    if (object_type=='instruments_option_comm'){
        let rand = this.exposure_rand_array[this.exposure_idx]
        let tt = this.instrument_object[rand][this.selectedExposure]['comm']['Option']
        tt.splice(idx, 1)
        this.instrument_object[rand][this.selectedExposure]['comm']['Option']=tt
        this.instruments_option_comm.removeAt(idx)
    }

     //POLICY
     if (object_type=='policy_forward_fx'){
        let rand = this.exposure_rand_array[this.exposure_idx]
        let tt = this.policy_object[rand][this.selectedExposure]['fx']['Forward']
        tt.splice(idx, 1)
        this.policy_object[rand][this.selectedExposure]['fx']['Forward']=tt
        this.policy_forward_fx.removeAt(idx)
    }

    if (object_type=='policy_option_fx'){
        let rand = this.exposure_rand_array[this.exposure_idx]
        let tt = this.policy_object[rand][this.selectedExposure]['fx']['Option']
        tt.splice(idx, 1)
        this.policy_object[rand][this.selectedExposure]['fx']['Option']=tt
        this.policy_option_fx.removeAt(idx)
    }

    if (object_type=='policy_future_comm'){
        let rand = this.exposure_rand_array[this.exposure_idx]
        let tt = this.policy_object[rand][this.selectedExposure]['comm']['Future']
        tt.splice(idx, 1)
        this.policy_object[rand][this.selectedExposure]['comm']['Future']=tt
        this.policy_future_comm.removeAt(idx)
    }

    if (object_type=='policy_option_comm'){
        let rand = this.exposure_rand_array[this.exposure_idx]
        let tt = this.policy_object[rand][this.selectedExposure]['comm']['Option']
        tt.splice(idx, 1)
        this.policy_object[rand][this.selectedExposure]['comm']['Option']=tt
        this.policy_option_comm.removeAt(idx)
    }

    
    if (this.crud=='edit'){
        this.onSubmitNext(false,this.exposure_idx,false)
    }
    
  
  }
  
  addInstrument(){

    //this.exposure_idx
    //this.exposure_tag_array[this.exposure_idx],
    
    let rand = this.exposure_rand_array[this.exposure_idx]

    this.instrument_object[rand]={}
    this.instrument_object[rand][this.selectedExposure]={}
    this.instrument_object[rand][this.selectedExposure][this.asset_type]={}

    if (this.asset_type=='fx' && this.selectedInstrumentFx=="Forward"){
        this.instrument_object[rand][this.selectedExposure]["fx"]["Forward"]={}
        let testme = this.buildGroup();
        testme.setValue({   exposure:this.selectedExposure,
                            vs: 'USD',
                            ndf: [false],
                            etr: this.exposure_static_list[this.exposure_idx]['etr'],
                            lag:this.exposure_static_list[this.exposure_idx]['lag'],
                            tag: this.exposure_tag_array[this.exposure_idx],
                            origination: '',
                            maturity:'',
                            notional:'',
                            price:['']
        });
        this.instruments_forward_fx.push(testme);
        
        this.instrument_object[rand][this.selectedExposure]["fx"]["Forward"]=this.myForm.controls['instruments_forward_fx'].value
        //this.instrument_array_object.push(testme)

       
        //this.myForm.controls['instruments_forward_fx']
    }

    if (this.asset_type=='fx' && this.selectedInstrumentFx=="Option"){
        this.instrument_object[rand][this.selectedExposure]["fx"]["Option"]={}
        let testme = this.buildOptionGroup();
        testme.setValue({   exposure:this.selectedExposure,
                            vs: 'USD',
                            ndf: [false],
                            etr: this.exposure_static_list[this.exposure_idx]['etr'],
                            lag:this.exposure_static_list[this.exposure_idx]['lag'],
                            tag: this.exposure_tag_array[this.exposure_idx],
                            origination: '',
                            l_s: 'Long',
                            c_p: 'Call',
                            maturity:'',
                            notional:'',
                            premium:[''],
                            strike:[''],
                            strike_p:['']
                        });
        this.instruments_option_fx.push(testme);
        this.instrument_object[rand][this.selectedExposure]["fx"]["Option"]=this.myForm.controls['instruments_option_fx'].value
    }

    if (this.asset_type=='comm' && this.selectedInstrumentComm=="Futures"){
        this.instrument_object[rand][this.selectedExposure]["comm"]["Future"]={}
        let testme = this.buildFutureCommGroup();
        testme.setValue({
            exposure:this.selectedExposure,
            vs: 'USD',
            etr: this.exposure_static_list[this.exposure_idx]['etr'],
            lag:this.exposure_static_list[this.exposure_idx]['lag'],
            tag: this.exposure_tag_array[this.exposure_idx],
            origination: '',
            maturity:'',
            notional:'',
            price:[''],
            contract_name:[''],
        });
        this.instruments_future_comm.push(testme);
        this.instrument_object[rand][this.selectedExposure]["comm"]["Future"]=this.myForm.controls['instruments_future_comm'].value
    }

    if (this.asset_type=='comm' && this.selectedInstrumentComm=="Option"){
        this.instrument_object[rand][this.selectedExposure]["comm"]["Option"]={}
        let testme = this.buildOptionCommGroup();
        testme.setValue({
            exposure:this.selectedExposure,
            vs: 'USD',
            etr: this.exposure_static_list[this.exposure_idx]['etr'],
            lag:this.exposure_static_list[this.exposure_idx]['lag'],
            tag: this.exposure_tag_array[this.exposure_idx],
            origination: '',
            maturity:'',
            notional:'',
            premium:[''],
            strike:[''],
            strike_p:[''],
            contract_name:['']
        });
        this.instruments_option_comm.push(testme);
        this.instrument_object[rand][this.selectedExposure]["comm"]["Option"]=this.myForm.controls['instruments_option_comm'].value
    }
    

    /*
    if (this.instrument_object.hasOwnProperty(this.selectedExposure)==false){
        this.instrument_object[this.selectedExposure]=[];
    }
    */

  } //end addInstrument()

  addPolicy(){
     
    /*
    let testme = this.buildPolicyForwardFxGroup();
    testme.setValue({   exposure:'',
                        vs: '',
                        ndf: '',
                        etr: '',
                        lag:'',
                        my_form_array_horizon:  [11,22]
    });
    this.policy_forward_fx.push(testme);
    // this.myFormArrayLayering.push(new FormControl(this.layering_array[_i]));
    //this.policy_object[this.selectedExposure]["fx"]["Forward"]=this.myForm.controls['policy_forward_fx'].value
  
    */

    let rand = this.exposure_rand_array[this.exposure_idx]
    this.policy_object[rand]={}
    this.policy_object[rand][this.selectedExposure]={}
    this.policy_object[rand][this.selectedExposure][this.asset_type]={}

    let fc = []
    for (var _j = 0; _j < this.horizon; _j++) {
        fc.push(0)
    }

    if (this.asset_type=='fx' && this.selectedPolicyFx=="Forward"){
        this.policy_object[rand][this.selectedExposure]["fx"]["Forward"]={}
        let testme = this.buildPolicyForwardFxGroup();
        testme.setValue({   exposure:this.selectedExposure,
                            vs: 'USD',
                            ndf: [false],
                            etr: this.exposure_static_list[this.exposure_idx]['etr'],
                            lag:this.exposure_static_list[this.exposure_idx]['lag'],
                            tag: this.exposure_tag_array[this.exposure_idx],
                            my_form_array_horizon:fc
        });
        this.policy_forward_fx.push(testme);
        this.policy_object[rand][this.selectedExposure]["fx"]["Forward"]=this.myForm.controls['policy_forward_fx'].value
    }

    if (this.asset_type=='fx' && this.selectedPolicyFx=="Option"){
        this.policy_object[rand][this.selectedExposure]["fx"]["Option"]={}
        let testme = this.buildPolicyOptionFxGroup();
        testme.setValue({   exposure:this.selectedExposure,
                            vs: 'USD',
                            ndf: [false],
                            etr: this.exposure_static_list[this.exposure_idx]['etr'],
                            lag:this.exposure_static_list[this.exposure_idx]['lag'],
                            tag: this.exposure_tag_array[this.exposure_idx],
                            strike:[''],
                            strike_p:[''],
                            my_form_array_horizon:fc
                        });
        this.policy_option_fx.push(testme);
        this.policy_object[rand][this.selectedExposure]["fx"]["Option"]=this.myForm.controls['policy_option_fx'].value
    }

    if (this.asset_type=='comm' && this.selectedPolicyComm=="Futures"){
        this.policy_object[rand][this.selectedExposure]["comm"]["Future"]={}
        let testme = this.buildPolicyFutureCommGroup();
        testme.setValue({
            exposure:this.selectedExposure,
            vs: 'USD',
            etr: this.exposure_static_list[this.exposure_idx]['etr'],
            lag:this.exposure_static_list[this.exposure_idx]['lag'],
            tag: this.exposure_tag_array[this.exposure_idx],
            my_form_array_horizon:fc
        });
        this.policy_future_comm.push(testme);
        this.policy_object[rand][this.selectedExposure]["comm"]["Future"]=this.myForm.controls['policy_future_comm'].value
    }

    if (this.asset_type=='comm' && this.selectedPolicyComm=="Option"){
        this.policy_object[rand][this.selectedExposure]["comm"]["Option"]={}
        let testme = this.buildPolicyOptionCommGroup();
        testme.setValue({
            exposure:this.selectedExposure,
            vs: 'USD',
            etr: this.exposure_static_list[this.exposure_idx]['etr'],
            lag:this.exposure_static_list[this.exposure_idx]['lag'],
            tag: this.exposure_tag_array[this.exposure_idx],
            strike:[''],
            strike_p:[''],
            my_form_array_horizon:fc
        });
        this.policy_option_comm.push(testme);
        this.policy_object[rand][this.selectedExposure]["comm"]["Option"]=this.myForm.controls['policy_option_comm'].value
    }

    /*
     if (this.policy_object.hasOwnProperty(this.selectedExposure)==false){
        this.policy_object[this.selectedExposure]=[];
    }
    */ 
      
  } //end addPolicy()

  back(){
      this._router.navigateByUrl('models/edit/' +  this.model_id + ';model=hedging');
  }

    show=false;
    hidden = false;
    onToggleClick(event){
        Object.keys(this.hideme).forEach(h => {
                if(event!=h){
                    this.hideme[h] = true;
                }
        });
        this.hideme[event] = !this.hideme[event];
      
    }

    onChangeOther(ce,i){

        let hash_item = this.asset_id_list[i];
        this.asset_id_list.splice(i+1, 0, hash_item);

        let event = this.selectedAsset;
       
        var index = this.asset_array[ce].indexOf(event, 0);
        this.asset_array[ce].splice(index, 1);
        this.asset_other[ce].push(event);
        this.selectedAsset = "Select Currency";

        //get index of parent
        let parent_index = this.parent_hash[ce];
      
        this.exposure_array.splice(i+1, 0, ce);
        this.myFormArrayExp.insert(i+1,new FormControl({value: ce, disabled: true}));
        this.myFormArrayEtr.insert(i+1,new FormControl(this.etr_array[i]));    
        this.myFormArrayLag.insert(i+1,new FormControl(this.lag_array[i]));  
        this.myFormArrayNdf.insert(i+1,new FormControl(false));      
        this.myFormArrayVs.insert(i+1,new FormControl(event));
        this.myFormArrayRatio.insert(i+1,new FormControl());  
        
        this.is_parent.splice(i+1, 0, false);
      
        
    } // onChangeOther

  
    getNextExposure(){

        
        if (this.exposure_array.length>0){

                    let rand = this.exposure_rand_array[this.exposure_idx]
                 
                   
                    //GET THE ASSET TYPE
                    this.selectedExposure = this.exposure_array[this.exposure_idx]
                    let temp = this.currency_array.filter(x => x.displayname === this.selectedExposure)[0];
                    if (temp){
                        this.asset_type = temp.type
                    } else {
                        let temp2 = this.commodity_array.filter(x => x.displayname === this.selectedExposure)[0];
                        this.asset_type = temp2.type
                    }

                    ////////////////////////////////////////////
                    //INSTRUMENT Load the forms from object
                    ////////////////////////////////////////////
                    if (this.asset_type=='fx'){
                        try{
                            let tt = this.instrument_object[rand][this.selectedExposure]['fx']['Forward']
                            if (tt) {
                                for (var _i = 0; _i < tt.length; _i++) { 
                                    let testme = this.buildGroup();
                                    testme.setValue(tt[_i]);
                                    this.instruments_forward_fx.push(testme);
                                }
                        }
                        } catch (e) {}


                        try{
                            let tt = this.instrument_object[rand][this.selectedExposure]['fx']['Option']
                            if (tt) {
                                for (var _i = 0; _i < tt.length; _i++) { 
                                    let testme = this.buildOptionGroup();
                                    testme.setValue(tt[_i]);
                                    this.instruments_option_fx.push(testme);
                                }
                        }
                        } catch (e) {}
                    }
                    if (this.asset_type=='comm'){
                        try{
                            let tt = this.instrument_object[rand][this.selectedExposure]['comm']['Future']
                            if (tt) {
                                for (var _i = 0; _i < tt.length; _i++) { 
                                    let testme = this.buildFutureCommGroup();
                                    testme.setValue(tt[_i]);
                                    this.instruments_future_comm.push(testme);
                                }
                        }
                        } catch (e) {}

                        try{
                            let tt = this.instrument_object[rand][this.selectedExposure]['comm']['Option']
                            if (tt) {
                                for (var _i = 0; _i < tt.length; _i++) { 
                                    let testme = this.buildOptionCommGroup();
                                    testme.setValue(tt[_i]);
                                    this.instruments_option_comm.push(testme);
                                }
                        }
                        } catch (e) {}
                    }


                    ////////////////////////////////////////////
                    //POLICY Load the forms from object
                    ////////////////////////////////////////////
                    if (this.asset_type=='fx'){
                        try{
                            let tt = this.policy_object[rand][this.selectedExposure]['fx']['Forward']
                            if (tt) {
                                for (var _i = 0; _i < tt.length; _i++) { 
                                    let testme = this.buildPolicyForwardFxGroup();
                                    testme.setValue(tt[_i]);
                                    this.policy_forward_fx.push(testme);
                                }
                        }
                        } catch (e) {}


                        try{
                            let tt = this.policy_object[rand][this.selectedExposure]['fx']['Option']
                            if (tt) {
                                for (var _i = 0; _i < tt.length; _i++) { 
                                    let testme = this.buildPolicyOptionFxGroup();
                                    testme.setValue(tt[_i]);
                                    this.policy_option_fx.push(testme);
                                }
                        }
                        } catch (e) {}
                    }

                    if (this.asset_type=='comm'){
                        try{
                            let tt = this.policy_object[rand][this.selectedExposure]['comm']['Future']
                            if (tt) {
                                for (var _i = 0; _i < tt.length; _i++) { 
                                    let testme = this.buildPolicyFutureCommGroup();
                                    testme.setValue(tt[_i]);
                                    this.policy_future_comm.push(testme);
                                }
                        }
                        } catch (e) {}

                        try{
                            let tt = this.policy_object[rand][this.selectedExposure]['comm']['Option']
                            if (tt) {
                                for (var _i = 0; _i < tt.length; _i++) { 
                                    let testme = this.buildPolicyOptionCommGroup();
                                    testme.setValue(tt[_i]);
                                    this.policy_option_comm.push(testme);
                                }
                        }
                        } catch (e) {}
                    }


        } // this.exposure_array.length>0

        
        if (this.exposure_array.length==0){
                    let temp_object = {}
                    let temp_better_object = {}
                    for (var _i = 0; _i < this.currency_array.length; _i++) {
                                //temp_object[this.currency_array[_i].displayname]['ndf']=this.currency_array[_i].NDF
                                //temp_object[this.currency_array[_i].displayname]['base_currency']='USD'
                                this.ndf_na_hash[this.currency_array[_i].displayname]=this.currency_array[_i].NDF;
                    }
                    for (var _i = 0; _i < this.commodity_array.length; _i++) {
                        //temp_object[this.commodity_array[_i].displayname]['ndf']=this.commodity_array[_i].NDF
                        //temp_object[this.commodity_array[_i].displayname]['base_currency']='USD'
                        this.ndf_na_hash[this.commodity_array[_i].displayname]=this.commodity_array[_i].NDF;
                    }
                    this.base_currency = this.hedge_data['data']['data']["hedgeinitial"]["base_currency"];
                    this.base_currency = 'USD';


                   

                    if (this.crud=='new'){
                       
                        
                            let tag_currencies = this.hedge_data['data']['data']["hedgeexpfx"]["tag"];
                            let tag_commodities = this.hedge_data['data']['data']["hedgeexpcom"]["tag"];
                            let rand_currencies = this.hedge_data['data']['data']["hedgeexpfx"]["rand"];
                            let rand_commodities = this.hedge_data['data']['data']["hedgeexpcom"]["rand"];

                            this.exposure_tag_array = tag_currencies.concat(tag_commodities);
                            //this.exposure_rand_array = rand_currencies.concat(rand_commodities);
                            //this.exposure_rand_array = rand_currencies.concat(rand_commodities);

                            

                            let etr_currencies = this.hedge_data['data']['data']["hedgeexpfx"]["etr"];
                            let etr_commodities = this.hedge_data['data']['data']["hedgeexpcom"]["etr"];
                            let lag_currencies = this.hedge_data['data']['data']["hedgeexpfx"]["lag"];
                            let lag_commodities = this.hedge_data['data']['data']["hedgeexpcom"]["lag"];
                            let horizon_currencies_array = this.hedge_data['data']['data']["hedgeexpfx"]["exp"];
                            let horizon_commodities_array = this.hedge_data['data']['data']["hedgeexpcom"]["exp"];
                            let used_currencies = this.hedge_data['data']['data']["hedgeexpfx"]["currencies"];
                            let used_commodities = this.hedge_data['data']['data']["hedgeexpcom"]["commodities"];
                            let chunked_currencies = _.chunk(horizon_currencies_array, this.horizon); 
                            let chunked_commodities = _.chunk(horizon_commodities_array, this.horizon);
                            this.asset_id_list_fx = this.hedge_data['data']['data']["hedgeexpfx"]["asset_id_list"];
                            this.asset_id_list_comm = this.hedge_data['data']['data']["hedgeexpcom"]["asset_id_list"];
                            this.asset_id_list = this.asset_id_list_fx.concat(this.asset_id_list_comm);
                            this.exposure_array = used_currencies.concat(used_commodities);
                            this.exposure_rand_array = []
                            for (var _i = 0; _i < this.exposure_array.length; _i++) {
                                let ttt = this.exposure_array[_i] + '_' + this.exposure_tag_array[_i];
                                this.exposure_rand_array.push(ttt);
                            }
                          



                            this.selectedExposure = this.exposure_array[this.exposure_idx]


                            //temp_better_object
                          
                            for (var _i = 0; _i < this.asset_id_list.length; _i++) {
                                temp_better_object[this.asset_id_list[_i]]={'exposure':this.exposure_array[_i]}
                            }
                            

                            
                            

                            for (var _i = 0; _i < this.exposure_array.length; _i++) {
                                temp_object[this.exposure_array[_i]]={'exposure':this.exposure_array[_i]}
                            }

                         
                            let temp = this.currency_array.filter(x => x.displayname === this.selectedExposure)[0];
                            
                            if (temp){
                                this.asset_type = temp.type
                            } else {
                                let temp2 = this.commodity_array.filter(x => x.displayname === this.selectedExposure)[0];
                                this.asset_type = temp2.type
                            }
                           
                             

                            let used_currencies_etr = this.hedge_data['data']['data']["hedgeexpfx"]["etr"];
                            let used_commodities_etr = this.hedge_data['data']['data']["hedgeexpcom"]["etr"];
                            this.etr_array = used_currencies_etr.concat(used_commodities_etr);

                            let used_currencies_lag = this.hedge_data['data']['data']["hedgeexpfx"]["lag"];
                            let used_commodities_lag = this.hedge_data['data']['data']["hedgeexpcom"]["lag"];
                            this.lag_array = used_currencies_lag.concat(used_commodities_lag);

                            //here is the problem
                            
                          
                            for (var _i = 0; _i < used_currencies.length; _i++) {

                                temp_object[used_currencies[_i]]['exposure']=used_currencies[_i]
                                temp_object[used_currencies[_i]]['vs']='USD'
                                temp_object[used_currencies[_i]]['etr']=etr_currencies[_i]
                                temp_object[used_currencies[_i]]['lag']=lag_currencies[_i]
                                temp_object[used_currencies[_i]]['exp']=chunked_currencies[_i]
                                temp_object[used_currencies[_i]]['tag']=tag_currencies[_i]

                                temp_better_object[this.asset_id_list_fx[_i]]['exposure']=used_currencies[_i]
                                temp_better_object[this.asset_id_list_fx[_i]]['vs']='USD'
                                temp_better_object[this.asset_id_list_fx[_i]]['etr']=etr_currencies[_i]
                                temp_better_object[this.asset_id_list_fx[_i]]['lag']=lag_currencies[_i]
                                temp_better_object[this.asset_id_list_fx[_i]]['exp']=chunked_currencies[_i]
                                temp_better_object[this.asset_id_list_fx[_i]]['tag']=tag_currencies[_i]

                                let temp = this.currency_array.filter(x => x.displayname === used_currencies[_i])[0];
                                if (temp.NDF==1){
                                    this.ndf_array.push(true);
                                    this.myFormArrayNdf.push(new FormControl(true)); 
                                    temp_object[used_currencies[_i]]['ndf']=true
                                    temp_better_object[this.asset_id_list_fx[_i]]['ndf']=true
                                } else { 
                                    this.ndf_array.push(false);
                                    this.myFormArrayNdf.push(new FormControl({value: false})); 
                                    temp_object[used_currencies[_i]]['ndf']=false
                                    temp_better_object[this.asset_id_list_fx[_i]]['ndf']=false
                                }
                            }

                        


                            for (var _i = 0; _i < used_commodities.length; _i++) {

                                temp_object[used_commodities[_i]]['exposure']=used_commodities[_i]
                                temp_object[used_commodities[_i]]['vs']='USD'
                                temp_object[used_commodities[_i]]['etr']=etr_commodities[_i]
                                temp_object[used_commodities[_i]]['lag']=lag_commodities[_i]
                                temp_object[used_commodities[_i]]['exp']=chunked_commodities[_i]
                                temp_object[used_commodities[_i]]['tag']=tag_commodities[_i]

                                temp_better_object[this.asset_id_list_comm[_i]]['exposure']=used_commodities[_i]
                                temp_better_object[this.asset_id_list_comm[_i]]['vs']='USD'
                                temp_better_object[this.asset_id_list_comm[_i]]['etr']=etr_commodities[_i]
                                temp_better_object[this.asset_id_list_comm[_i]]['lag']=lag_commodities[_i]
                                temp_better_object[this.asset_id_list_comm[_i]]['exp']=chunked_commodities[_i]
                                temp_better_object[this.asset_id_list_comm[_i]]['tag']=tag_commodities[_i]

                                this.ndf_array.push(false);
                                this.myFormArrayNdf.push(new FormControl({value: false})); 
                            }


                          
                            //HERE IS MY PROBLEM!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                            for (var _j = 0; _j < this.exposure_array.length; _j++) {
                                //this.exposure_static_list.push(temp_object[this.exposure_array[_j]])
                                //this.exposure_static_list.push(temp_better_object[this.exposure_array[_j]])
                            }

                            for (var _j = 0; _j < this.asset_id_list.length; _j++) {
                                this.exposure_static_list.push(temp_better_object[this.asset_id_list[_j]])
                            }

                            

                            //REALLY
                           

                          
                            for (var _j = 0; _j < this.horizon; _j++) {
                                this.deferred_earnings_array.push(1+_j);
                            }

                            for (var _i = 0; _i < this.exposure_array.length; _i++) {
                                this.asset_array[this.exposure_array[_i]]=[];
                                    this.asset_other[this.exposure_array[_i]]=[];
                                for (var _j = 0; _j < this.currency_array.length; _j++) {
                                    if ( this.currency_array[_j].displayname!=this.base_currency && this.currency_array[_j].displayname!=this.exposure_array[_i]){
                                        this.asset_array[this.exposure_array[_i]].push(this.currency_array[_j].displayname);
                                    }
                                }
                            }

                    } //end new
        } //this.exposure_array.length==0
        
    } //getNextExposure()


    
    onClickChange(i){
        let prev_idx = this.exposure_idx
        this.exposure_idx = i
       
        this.onSubmitNext(false,prev_idx,false)

    } // onClickChange(i)

    

    navStyle(i){
        var objExp = {}
        if (i==this.exposure_idx){
            objExp = {"font-weight": "bold","text-decoration": "underline #052541"}
        }
        return objExp
    }

    saveExit(){

        let prev_idx = this.exposure_idx
        //this.exposure_idx = i
        this.onSubmitNext(false,prev_idx,true)
    }
 
 
    onSubmitNext(is_next,prev_idx,is_exit=false){

    
        
        
        let rand = this.exposure_rand_array[prev_idx]

        
        
        //////////////////////////////
        //create data objects here
        ///////////////////////////////
        if (this.asset_type=="fx"){
            try {
                this.instrument_object[rand][this.selectedExposure][this.asset_type]['Forward']=this.myForm.controls['instruments_forward_fx'].value
            } catch (e) {}
            try {
                this.instrument_object[rand][this.selectedExposure][this.asset_type]['Option']=this.myForm.controls['instruments_option_fx'].value
            } catch (e) {}
            try {
                this.policy_object[rand][this.selectedExposure][this.asset_type]['Forward']=this.myForm.controls['policy_forward_fx'].value
            } catch (e) {}
            try {
                this.policy_object[rand][this.selectedExposure][this.asset_type]['Option']=this.myForm.controls['policy_option_fx'].value
            } catch (e) {}
        }

        if (this.asset_type=="comm"){
            try {
                this.instrument_object[rand][this.selectedExposure][this.asset_type]['Future']=this.myForm.controls['instruments_future_comm'].value
            } catch (e) {}
            try {
                this.instrument_object[rand][this.selectedExposure][this.asset_type]['Option']=this.myForm.controls['instruments_option_comm'].value
            } catch (e) {}
            try {
                this.policy_object[rand][this.selectedExposure][this.asset_type]['Future']=this.myForm.controls['policy_future_comm'].value
            } catch (e) {}
            try {
                this.policy_object[rand][this.selectedExposure][this.asset_type]['Option']=this.myForm.controls['policy_option_comm'].value
            } catch (e) {}
        }

        
        //////////////////////////////
        //reset form data here
        ///////////////////////////////
        if (is_next==true){
            if (this.exposure_idx<this.exposure_array.length-1){
                this.exposure_idx = this.exposure_idx + 1;
            } else {
                this.exposure_idx = 0;
            }
        } else {
            
        }

        


        this.myForm.reset()
        this.myForm = this.fb.group({
            instruments_forward_fx: this.buildArray(),
            instruments_option_fx: this.buildOptionArray(),
            instruments_future_comm: this.buildFutureCommArray(),
            instruments_option_comm: this.buildOptionCommArray(),
             policy_forward_fx: this.buildPolicyForwardFxArray(),
             policy_option_fx: this.buildPolicyOptionFxArray(),
             policy_future_comm: this.buildPolicyFutureCommArray(),
             policy_option_comm: this.buildPolicyOptionCommArray()
        }) 
        
        //////////////////////////////
        //save here
        ///////////////////////////////
        let data = {}
        data['instrument_object']=this.instrument_object
        data['policy_object']=this.policy_object
        data['exposure_static_list']=this.exposure_static_list
        data['exposure_rand_array']=this.exposure_rand_array
        data['exposure_tag_array']=this.exposure_tag_array
        
        
        var result:any;
        if (this.crud=='new'){
            result = this._apiService.addModelId(this.endpoint,this.model_id,data).subscribe(x => {
                this.crud='edit'
                if (is_exit==false){
                    this.getNextExposure()
                } else {
                    this._router.navigateByUrl('models');
                }

                
                //this._router.navigateByUrl('models/hedgetailrisk/'  +  this.model_id + ';exposuremodelid=' + this.exposure_model_id);
            });
        }

        
        if (this.crud=='edit'){
         
            result = this._apiService.updateModelId(this.endpoint,this.model_id,data).subscribe(x => {
                this.crud='edit'
                
                if (is_exit==false){
                  
                    this.getNextExposure()
                } else {
                    this._router.navigateByUrl('models');
                }
                    
            });
        }
       
    } //onSubmitNext()


}
