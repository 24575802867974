import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
@Injectable()
export class DbBusyService {

  constructor() { }

  private dbbusyAnnouncedSource = new Subject<boolean>();
  // Observable string streams
  dbbusyAnnounced$ = this.dbbusyAnnouncedSource.asObservable();
  // Service message commands
  announceDbBusy(db_busy: boolean) {
    this.dbbusyAnnouncedSource.next(db_busy);
  }

}
