import {Component, OnInit,AfterViewInit} from '@angular/core';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router,ActivatedRoute,RouterLink} from '@angular/router';
import {Model} from './model';
import {LinkList} from '../common/link_list';
import { ApiService } from '../api_service/api.service';
import { LoggedinService } from '../loggedin.service';
import { ModelValidators } from '../validators/validators';
import { BusyService } from '../busy.service';

@Component({
    templateUrl: './model-form.component.html',
    styleUrls: ['../app.component.css'],
    providers: [RouterLink,FormBuilder,ApiService]
})
export class ModelFormComponent implements OnInit,AfterViewInit {
    title: string;
    crud: string;
    model = new Model();
    endpoint='/test/model';
    myForm: FormGroup;

    myFormExposureModelUse = new FormArray([]); 

	constructor(private loggedinService: LoggedinService,
                private busyService: BusyService,
                private route: ActivatedRoute,
                private fb: FormBuilder,
                private _router: Router,
                private _apiService: ApiService) {

                this.loggedinService.announceLoggedin(true);

                this.myForm = fb.group({
                    name: ['',[Validators.required]],
                    my_form_exposure_model_use: this.myFormExposureModelUse,
                    my_form_exposure_hedging_model_use: ['']
                })

 
                     
	}


    test = [];
    isLoading = false;
    models: any[];
    models_hedging_list: any[] = [];
    models_hedging: any[] = [];
    models_id_hedging: any[] = [];
    models_exposure: any[] = [];
    models_id_exposure: any[] = [];
    model_type: string;
    model_id: string;
    private sub: any;
    super_model:any;
    next_page:string;
    is_edit:boolean;
    modelid_name_hash = {}
    name_modelid_hash = {}
    exposuremodelid:string;


    ngOnInit(){
      

       var id: string;
        this.sub = this.route.params.subscribe(params => {
            this.model_type = params['model']
            id = params['id'];
            this.model_id = id; 
        });


        this.route.data.forEach((data) => {



            this.getNameIdHash(data['results'])
            
           
            if (!this.model_id){
                this.crud='new';
                this.is_edit=false;
                if (this.model_type=='risk' || this.model_type=='hedging'){
                    this.getModelDataAllNew(data['results']);
                }
            }

            if (this.model_id){
                this.crud='edit';
                this.is_edit=true;
                this.getModelDataEdit(data['results'])
                
            }
            
            if (this.model_type=='risk'){
                this.title = id ? "Edit Risk Model" : "New Risk Model";
                this.next_page = 'BASIC SETUP'
            }

            if (this.model_type=='hedging'){
                this.title = id ? "Edit Hedging Model" : "New Hedging Model";
                //this.next_page = 'HEDGE POLICY: HEDGE RATIO';  
                this.next_page = 'Next';  

                if (this.is_edit==true){
                    this.myForm.controls['my_form_exposure_hedging_model_use'].disable();
                }


            }

            if (this.model_type=='exposure'){
                this.title = id ? "Edit Exposure Model" : "New Exposure Model";
                this.next_page = 'Exposure Parameters'
            }

            if (this.model_type=='hedging_list'){
                this.title = id ? "Edit Hedging List" : "New Hedging List";
                this.next_page = 'Pair Exposure and Hedging Models'
            }
           


        });
        

    } //end ngOnInit

    ngAfterViewInit() {
    
    } // end  ngAfterViewInit
  

   getNameIdHash(models){

       for (var _i = 0; _i < models.length; _i++) {

           this.modelid_name_hash[models[_i]['data']['model_id']]=models[_i]['data']['name'];
           this.name_modelid_hash[models[_i]['data']['name']]=models[_i]['data']['model_id'];

       }


   }
  
   

    getModelDataAllNew(models){
        //New Model

        for (var _i = 0; _i < models.length; _i++) {
            if (models[_i]['data']['type']=='exposure'){
                if (models[_i]['data']['data'].hasOwnProperty('hedgeexpcom')){
                  models[_i]['data']['status']='complete';
                  this.models_exposure.push(models[_i]['data']['name']);   //model.data.name
                  this.models_id_exposure.push(models[_i]['data']['model_id']);
                  //this.modelid_name_hash[models[_i]['data']['model_id']]=models[_i]['data']['name'];
                  //this.myFormHedgingModelUse.push(new FormControl(models[_i]['data']['model_id']));
                  this.myFormExposureModelUse.push(new FormControl(false));
                }
            }
        }
    } // getModelDataAllNew 





    getModelDataEdit(models){

        //get name given model_id
        let model_name_hash = {}
        for (var _i = 0; _i < models.length; _i++) {
            let name = models[_i]['data']['name'];
            let idd = models[_i]['data']['model_id'];
            model_name_hash[idd]=name;
         }

         //models_exposure_radio
        
       
        
         for (var _i = 0; _i < models.length; _i++) {    //iter trough all models exposure,risk,hedging

             //EXPOSURE
            if (models[_i]['data']['type']=='exposure' && models[_i]['data']['model_id']==this.model_id){
                this.model.name = models[_i]['data']['name'];
                this.super_model = models[_i]['data'];
            } // end hedge




            //START OF RISK FOR THIS MODEL ID
            if (models[_i]['data']['type']=='risk' && models[_i]['data']['model_id']==this.model_id){
                
                //models_id_exposure
               // //is_used_hash

                
                this.model.name = models[_i]['data']['name'];
                this.super_model = models[_i]['data'];

                let test: any[] = []; 
                let models_id_exposure_temp = models[_i]['data']['models_id_exposure'];
                let my_form_exposure_model_use_temp = models[_i]['data']['my_form_exposure_model_use'];

               
                for (var _j = 0; _j < models_id_exposure_temp.length; _j++) {  //iter over saved model id's
                   let this_model_id = models_id_exposure_temp[_j];
                   this.models_exposure.push(model_name_hash[this_model_id]);
                 

                   let tb = false
                   if (models[_i]['data'].is_used_hash.hasOwnProperty(this_model_id)){
                       tb = true;
                   }
                   this.models_id_exposure.push(this_model_id)
                   let is_used = my_form_exposure_model_use_temp[_j];
                   //this.myFormExposureModelUse.push(new FormControl(is_used)); 
                   this.myFormExposureModelUse.push(new FormControl(tb)); 
                } //end of J loop


            } //end risk


            //HEDGING
            if (models[_i]['data']['type']=='hedging' && models[_i]['data']['model_id']==this.model_id){

                this.models_exposure = models[_i]['data']['models_exposure_radio']

                //this.models_exposure.push(models[_i]['data']['my_form_exposure_hedging_model_use'])
                this.model.name = models[_i]['data']['name'];
                this.super_model = models[_i]['data'];
                this.myForm.controls['my_form_exposure_hedging_model_use'].setValue(this.super_model['my_form_exposure_hedging_model_use']);
            } // end hedge 

            //HEDGING LIST
            if (models[_i]['data']['type']=='hedging_list' && models[_i]['data']['model_id']==this.model_id){
                this.model.name = models[_i]['data']['name'];
                this.super_model = models[_i]['data'];
              } // end hedge 


        }  //end for 

    } //end getModelDataEdit





   onSubmit(){

    //try to fix checkbot issue
    let is_used_hash = {}
    if (this.model_type=='risk'){
        this.model.my_form_exposure_model_use=this.myForm.controls['my_form_exposure_model_use'].value;
        let temp = [];
        let temp2 = []
        for (var _j = 0; _j < this.model.my_form_exposure_model_use.length; _j++) { 
            if (this.model.my_form_exposure_model_use[_j]==true){
                //temp2.push(true)
                //temp.push(this.models_id_exposure[_j])
                is_used_hash[this.models_id_exposure[_j]]=true
            } else {
                //temp2.push(false)
                //temp.push(this.models_id_exposure[_j])
                is_used_hash[this.models_id_exposure[_j]]=false
            }
        }
         //this.model.models_id_exposure = temp;
         //this.model.my_form_exposure_model_use= temp2;
    }
    this.model.is_used_hash=is_used_hash






        this.busyService.announceBusy(false);
        var result:any;
        if (this.model_id){

            

            this.super_model['is_used_hash']=this.model.is_used_hash;
            localStorage.setItem('model_name', this.super_model['name']);

            this.model.type=this.model_type;
            this.model.model_id = this.model_id;
            this.model.status='incomplete';
            if (this.model_type=='risk'){
                this.model.my_form_exposure_model_use=this.myForm.controls['my_form_exposure_model_use'].value;
                this.model.models_id_exposure=this.models_id_hedging;

                this.super_model['my_form_exposure_model_use']=this.myForm.controls['my_form_exposure_model_use'].value;
                this.super_model['models_id_exposure']=this.models_id_exposure;

            }

            if (this.model_type=='hedging'){
                this.super_model['my_form_exposure_hedging_model_use'] = this.myForm.controls['my_form_exposure_hedging_model_use'].value;
                this.exposuremodelid = this.name_modelid_hash[this.myForm.controls['my_form_exposure_hedging_model_use'].value];
                this.super_model['models_exposure_radio']=this.models_exposure;
            }
            
            this.super_model['name']=this.model.name;
        
          
            result = this._apiService.updateModel(this.endpoint,this.super_model).subscribe(x => {

                if (this.model_type=='exposure'){
                    this._router.navigateByUrl('/models/hedgeinitial/' +  this.model.model_id + ';model=' + this.model_type);
                }
                if (this.model_type=='hedging'){
                    //this._router.navigateByUrl('/models/hedgeratio/' +  this.model.model_id + ';exposuremodelid=' + this.exposuremodelid);
                    this._router.navigateByUrl('models/hedgepanel/' + this.model.model_id + ';exposuremodelid=' + this.exposuremodelid);
                }
                if (this.model_type=='risk'){
                    this._router.navigateByUrl('/models/basic/' +  this.model.model_id);
                }
                if (this.model_type=='hedging_list'){
                    this._router.navigateByUrl('/models/matchexphedge/' +  this.model.model_id);
                }
            }); //end result
         
           
           
           
            
        } //end if this.model_id

       
        //updateModelId(this.endpoint,this.model_id,data)
        
        if (!this.model_id){
                   


                   localStorage.setItem('model_name', this.model['name']);

                    
               

                    this.model.status='incomplete';
                    this.model.type=this.model_type;


                    
                    if (this.model_type=='risk'){
                        this.model.my_form_exposure_model_use=this.myForm.controls['my_form_exposure_model_use'].value;
                        //this.model.models_id_exposure=this.models_id_exposure;
                        //this.model.cb_hash={}
                        let temp = [];
                        for (var _j = 0; _j < this.model.my_form_exposure_model_use.length; _j++) { 
                            if (this.model.my_form_exposure_model_use[_j]==true){
                                temp.push(this.models_id_exposure[_j])
                            }
                        }
                         this.model.models_id_exposure = temp;
                    }
                    

                  
                    
                    if (this.model_type=='hedging'){
                            this.model.my_form_exposure_hedging_model_use = this.myForm.controls['my_form_exposure_hedging_model_use'].value;
                            this.exposuremodelid = this.name_modelid_hash[this.myForm.controls['my_form_exposure_hedging_model_use'].value];
                            this.model['models_exposure_radio']=this.models_exposure;    
                            this.model.my_form_exposure_hedging_model_id_use = this.exposuremodelid;
                    }
            
        
                   
                    result = this._apiService.addModel(this.endpoint,this.model).subscribe(x => {
                            let model_id = x.model_id;

                            if (this.model_type=='exposure'){
                                this._router.navigateByUrl('/models/hedgeinitial/' +  model_id + ';model=' + this.model_type);
                            }
                            if (this.model_type=='hedging'){
                                //this._router.navigateByUrl('models/hedgeratio/' +  model_id + ';exposuremodelid=' + this.exposuremodelid);
                                this._router.navigateByUrl('models/hedgepanel/' +  model_id + ';exposuremodelid=' + this.exposuremodelid);
                            }
                            if (this.model_type=='risk'){
                                this._router.navigateByUrl('models/basic/' +  model_id);
                            }
                            if (this.model_type=='hedging_list'){
                                this._router.navigateByUrl('models/matchexphedge/' +  model_id);
                            }

                        
                     }); //end resuklt
                   
                   
                     
                
 

            } //end !this.model_id
           
     } //end submit
             
	
} 



