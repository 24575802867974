import {Component, OnInit, Output,EventEmitter,Provider, forwardRef, AfterViewInit} from '@angular/core';
import {MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA} from '@angular/material';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { ModelValidators } from '../validators/validators';
import { CURRENCIES, COMMODITIES } from '../object_lists/assets_object';
import { LoggedinService } from '../loggedin.service';
import { BusyService } from '../busy.service';
import { FormComponent } from '../can_deactivate/prevent_unsaved_changes_gaurd.service';

import 'rxjs/add/operator/first';
import { DialogRouteCanDeactiveComponent } from '../dialog-route-can-deactive/dialog-route-can-deactive.component'
import {MyCurrencyPipe} from '../my-currency.pipe'


import * as _ from "lodash";

const FLOAT_REGEX = /^-?\d*(\.\d+)?$/;
const POSITIVE_FLOAT_REGEX = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
//const POSITIVE_FLOAT_REGEX = /^\+?(0|[1-9]\d*)$/;

@Component({
  selector: 'app-mesa-hedge-expcom',
  templateUrl: './mesa-hedge-expcom.component.html',
  styleUrls: ['./mesa-hedge-expcom.component.css','../app.component.css']
})  
export class MesaHedgeExpcomComponent implements OnInit,FormComponent {

    endpoint='/test/model/hedgeexpcom';
    use_hash = false;
    myForm: FormGroup;
    model_id: string;
    isLoading = false;
    crud:string;
    deferred_earnings_max:number = 13;
    deferred_earnings_array: any[] = [];
    currency_array: any[] = []; // = COMMODITIES;
    unused_array: any[] = [];
    add_asset_object = {};

    myFormArrayExp =  new FormArray([]);
    myFormArrayEtr =  new FormArray([]);
    myFormArrayLag =  new FormArray([]);
    myFormArrayTag =  new FormArray([]);
    myFormArrayRand =  new FormArray([]);

    busy=true; 
    constructor(
        private loggedinService: LoggedinService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private _apiService: ApiService,
        private busyService: BusyService,
        public dialog: MatDialog,
        private mycurpipe: MyCurrencyPipe,
        private decimalpipe: DecimalPipe) {

        this.loggedinService.announceLoggedin(true); 
 
        this.myForm = fb.group({
             my_form_array_exp: this.myFormArrayExp,
             my_form_array_etr: this.myFormArrayEtr,
             my_form_array_lag: this.myFormArrayLag,
             my_form_array_tag: this.myFormArrayTag,
             my_form_array_rand: this.myFormArrayRand
         })
    }

    selectedAsset:string;
    api_object:any;
    horizon:number;
    currency_list: any[] = [];
    lag_array: any[] = [];
    etr:number;
    deferred_ernings_lag:number;
    temp:any;

    edit_asset_type_hash ={}
    orig_asset_type_hash:any;
    models_hedging: any[] = [];
    models_risk: any[] = [];
    asset_array: any[] = [];
    asset_id_list: any[] = [];


    is_edit=false;

   is_submit = false;
   canDeactivate() {
       //https://scotch.io/courses/routing-angular-2-applications/candeactivate
        if (this.myForm.dirty == true && this.is_submit==false){
            const config = new MatDialogConfig();
            config.disableClose=true;
            let dialogRef = this.dialog.open(DialogRouteCanDeactiveComponent,config);
            return dialogRef.afterClosed().map(result => {
                return result
            }).first(); //end dialogRef
        } //end if
        return true 
    }


    ngOnInit() {
        this.route.params.map(params => params['id'])
            .subscribe(id => { this.model_id=id });

        this.currency_array = JSON.parse(localStorage.getItem('commodity_list'));

        this.route.data.forEach((data) => {
                if (data['results'].hasOwnProperty('hedgeexpcom')===false){
                    this.crud='new'; 
                } else {
                    this.crud='edit'; 
                }

                this.horizon = +data['results']["hedgeinitial"]["analysis_horizon"];
                this.deferred_ernings_lag = +data['results']["hedgeinitial"]["deferred_earnings"];
                this.etr = +data['results']["hedgeinitial"]["etr"];

                for (var _i = 0; _i < this.horizon; _i++) {
                    this.lag_array.push('M'+(_i+1));
                } 
                

                

                 for (var _i = 0; _i < this.currency_array.length; _i++) {
                            this.asset_array.push(this.currency_array[_i].displayname)
                }
                this.asset_array.sort()

                if (this.crud=='edit'){

                    this.getAllModels();

                    this.edit_asset_type_hash = data['results']['hedgeexpcom']['edit_asset_type_hash'];
                    this.orig_asset_type_hash = data['results']['hedgeexpcom']['orig_asset_type_hash'];
                    this.currency_list = data['results']["hedgeexpcom"]["commodities"];
                    this.asset_id_list = data['results']['hedgeexpcom']['asset_id_list'];


                    //THIS WILL HANDLE THE CASE WHERE ON EDIT TGE HORIZON CHANGES
                    if (data['results']["hedgeexpcom"]["exp"].length!=this.currency_list.length*this.horizon){
                        
                        
                        let new_list = []
                        let new_hash = {}

                        for (var _i = 0; _i < this.currency_list.length; _i++) {
                            new_hash[this.asset_id_list[_i]]=[];
                            let old_len = this.edit_asset_type_hash[this.asset_id_list[_i]].length;
                            for (var _j = 0; _j < this.horizon; _j++) {

                                if (this.edit_asset_type_hash.hasOwnProperty(this.asset_id_list[_i])===true && _j<old_len){ //???
                                    let temp = this.edit_asset_type_hash[this.asset_id_list[_i]][_j]
                                    new_hash[this.asset_id_list[_i]].push(temp)
                                    new_list.push(temp);
                                } else {
                                    new_hash[this.asset_id_list[_i]].push(0);
                                    new_list.push(0);
                                }
                            } //end _j
                        } // _i
                        this.edit_asset_type_hash = new_hash;
                        this.orig_asset_type_hash = new_hash;
                        data['results']["hedgeexpcom"]["exp"] = new_list;
                    
                    } //end if

 
                    let currency_count = this.currency_list.length;
                    for (var _i = 0; _i < this.currency_array.length; _i++) {
                        if (this.currency_list.some(x=>x==this.currency_array[_i].displayname)===false){
                            this.unused_array.push(this.currency_array[_i].displayname);
                        }
                     }

                     for (var _j = 0; _j < currency_count; _j++) {
                            this.myFormArrayEtr.push(new FormControl(data['results']["hedgeexpcom"]["etr"][_j],[Validators.required,ModelValidators.validPercentage]));
                            this.myFormArrayLag.push(new FormControl(data['results']["hedgeexpcom"]["lag"][_j],[Validators.required,ModelValidators.validLag]));
                            this.myFormArrayTag.push(new FormControl(data['results']["hedgeexpcom"]["tag"][_j],[Validators.required]));
                            //this.myFormArrayRand.push(new FormControl(data['results']["hedgeexpcom"]["rand"][_j],[]));
                            this.myFormArrayRand.push(new FormControl(0,[]));
                    }

                    for (var _j = 0; _j < data['results']["hedgeexpcom"]["exp"].length; _j++) {
                        //let temp = this.mycurpipe.transform(data['results']["hedgeexpcom"]["exp"][_j])
                        let temp = this.decimalpipe.transform(data['results']["hedgeexpcom"]["exp"][_j],'1.0-7')
                        this.myFormArrayExp.push(new FormControl(temp,[Validators.required,ModelValidators.isCurrencyFormatValidFloat]));
                    }

                    
                       
                    this.is_edit=true;

                }


                if (this.crud=='new'){

                        //this.currency_list = data['results']["marketriskfactors"]["commodities"];
                        this.currency_list = [];
                        let currency_count = this.currency_list.length;

                        this.createEditHash();
                        this.orig_asset_type_hash=this.edit_asset_type_hash;


                        for (var _i = 0; _i < this.currency_array.length; _i++) {
                                if (this.currency_list.some(x=>x==this.currency_array[_i].displayname)===false){
                                    this.unused_array.push(this.currency_array[_i].displayname);
                                }
                        }

                        for (var _j = 0; _j < currency_count; _j++) {
                                    this.myFormArrayEtr.push(new FormControl(this.etr,[Validators.required,ModelValidators.validPercentage]));
                                    this.myFormArrayLag.push(new FormControl(this.deferred_ernings_lag,[Validators.required,ModelValidators.validLag]));
                                    this.myFormArrayTag.push(new FormControl('',[]));
                                    this.myFormArrayRand.push(new FormControl(Math.random(),[]));
                            }

                            for (var _i = 0; _i < this.horizon; _i++) {
                                for (var _j = 0; _j < currency_count; _j++) {
                                    this.myFormArrayExp.push(new FormControl('0',[Validators.required,Validators.pattern(FLOAT_REGEX)]));
                                }
                            } 
                }
        });



         
    }

    onInputChange(ce,i,j){

        let idx = ((i+1)*this.lag_array.length)-this.lag_array.length+j;
        let hash_id = this.asset_id_list[i];
        this.edit_asset_type_hash[hash_id][j]=this.myForm.controls['my_form_array_exp'].value[idx];
    }

    getAllModels111(){

      this._apiService.getModels('/test/model')
                      .subscribe(models => {
                          this.models_hedging = []
                          for (var _i = 0; _i < models.length; _i++) {
                            if (models[_i]['data']['type']=='hedging' && models[_i]['data']['my_form_exposure_hedging_model_id_use']==this.model_id){
                              this.models_hedging.push(models[_i]);
                            }
                          }
                      }
                      ,null,() => { this.isLoading = false; 
                        
                      });
    }

     getAllModels(){

      this._apiService.getModels('/test/model')
                      .subscribe(models => {
                          this.models_hedging = []
                          this.models_risk = []


                          for (var _i = 0; _i < models.length; _i++) {

                              if (models[_i]['data']['type']=='risk'){

                               for (var _j = 0; _j < models[_i]['data']['models_id_exposure'].length; _j++) {
                                   if (models[_i]['data']['models_id_exposure'][_j]==this.model_id && models[_i]['data']['my_form_exposure_model_use'][_j]==true){
                                       this.models_risk.push(models[_i]);
                                   }
                                } 
                            }




                            if (models[_i]['data']['type']=='hedging' && models[_i]['data']['my_form_exposure_hedging_model_id_use']==this.model_id){
                              this.models_hedging.push(models[_i]);
                            }
                          }
                      }
                      ,null,() => { this.isLoading = false; 
                      
                      });
    }


    createEditHash(){
         
        for (var _i = 0; _i < this.currency_list.length; _i++) {
            this.edit_asset_type_hash[this.currency_list[_i]]=[];
            for (var _j = 0; _j < this.horizon; _j++) {
                this.edit_asset_type_hash[this.currency_list[_i]].push(0);
            }
        }
    }

   deleteAsset(ce,idx){

        let col_count = this.horizon*this.currency_list.length
        for (var _i = 0; _i < this.horizon; _i++) {
            this.myFormArrayExp.removeAt(idx*this.horizon)
        }

        let hash_id = this.asset_id_list[idx];
        delete this.edit_asset_type_hash[hash_id];
        //delete this.orig_asset_type_hash[hash_id];

        this.myFormArrayEtr.removeAt(idx)
        this.myFormArrayLag.removeAt(idx);
        this.myFormArrayTag.removeAt(idx);
        this.myFormArrayRand.removeAt(idx);
        this.currency_list.splice(idx, 1);
        this.asset_id_list.splice(idx, 1)

    }

/*

    addAsset(){

        let rand = Math.floor(Math.random() * 10000) + 1 
        let asset_rand = this.selectedAsset + '_' + rand;
        this.asset_id_list.push(asset_rand)

        this.edit_asset_type_hash[asset_rand]=[];
            for (var _j = 0; _j < this.horizon; _j++) {
                this.edit_asset_type_hash[asset_rand].push(0);
        }

        this.currency_list.push(this.selectedAsset);
        var index = this.unused_array.indexOf(this.selectedAsset, 0);
        //this.unused_array.splice(index, 1);
        //this.unused_array
        for (var _i = 0; _i < this.horizon; _i++) {
            this.myFormArrayExp.push(new FormControl('0',[Validators.required]));
        }
        this.myFormArrayEtr.push(new FormControl(this.etr,[Validators.required]));
        this.myFormArrayLag.push(new FormControl(this.deferred_ernings_lag,[Validators.required]));
        this.selectedAsset = this.asset_array[0];

    }
*/




    addAsset() { 


        
        let asset_rand = '';
        let form_tag = '';
        let rand = Math.floor(Math.random() * 10000) + 1;


        //Init tags and random keys
        let tag_asset_hash = {}
        for (var _i = 0; _i < this.asset_id_list.length; _i++) {
            if (_.startsWith(this.asset_id_list[_i], this.selectedAsset)==true){
                let tt = _.split(this.asset_id_list[_i], '_')
                tag_asset_hash[tt[1]]=this.selectedAsset
            }
        }
        if (_.isEmpty(tag_asset_hash)){
            asset_rand = this.selectedAsset + '_' + '1';
            form_tag = '1';
        } else {
            for (var _i = 0; _i < 1000; _i++) {
                if (tag_asset_hash.hasOwnProperty((_i+1).toString())==false){
                    asset_rand = this.selectedAsset + '_' + (_i+1).toString();
                    form_tag = (_i+1).toString();
                    break;
                }

            }
        }
        
        let ce = this.selectedAsset;
        this.asset_id_list.push(asset_rand);
        
        this.edit_asset_type_hash[asset_rand]=[];
            for (var _j = 0; _j < this.horizon; _j++) {
                this.edit_asset_type_hash[asset_rand].push(0);
        }
        

        this.currency_list.push(this.selectedAsset);
        var index = this.unused_array.indexOf(this.selectedAsset, 0);
        //this.unused_array.splice(index, 1);
        //this.unused_array
        for (var _i = 0; _i < this.horizon; _i++) {
            this.myFormArrayExp.push(new FormControl('0',[Validators.required,Validators.pattern(FLOAT_REGEX)]));
        }
        this.myFormArrayEtr.push(new FormControl(this.etr,[Validators.required,ModelValidators.validPercentage]));
        this.myFormArrayLag.push(new FormControl(this.deferred_ernings_lag,[Validators.required,ModelValidators.validLag]));
        this.myFormArrayTag.push(new FormControl(form_tag,[Validators.required]));
        this.myFormArrayRand.push(new FormControl(Math.random(),[]));
        this.selectedAsset = this.asset_array[0];

        this.add_asset_object = {'ce':ce,'asset_id_list_item':asset_rand,'idx':this.currency_list.length-1}
        
 
    }

    onChangeAsset(event){
        this.selectedAsset = event; 
    }


    back(){
        this._router.navigateByUrl('models/hedgeexpfx/'+  this.model_id);
    }

    openDialogAdd(){
           
            if (this.crud=='edit' && (this.models_hedging.length>0 || this.models_risk.length>0)){

                    this.addAsset() 
                    let ce = this.add_asset_object['ce'];
                    let asset_id_list_item = this.add_asset_object['asset_id_list_item'];
                    let idx = this.add_asset_object['idx'];

                 

                    const config = new MatDialogConfig();
                    config.disableClose=true;
                    let dialogRef = this.dialog.open(DialogAddAssetComm,config);
                    
                    dialogRef.componentInstance.asset = ce;  //    http://stackoverflow.com/questions/34205593/working-example-of-angular-2-0-material-mddialog-with-angular-2-0/40185852#40185852
                    dialogRef.componentInstance.asset_type = 'fx';
                    dialogRef.componentInstance.models_hedging = this.models_hedging;
                    dialogRef.componentInstance.models_risk = this.models_risk;
                    dialogRef.componentInstance.asset_id_list_item=asset_id_list_item //this.asset_id_list[idx];
                    dialogRef.componentInstance.model_id = this.model_id;
                    dialogRef.afterClosed().subscribe(result => {
                        if (result=='cancel'){
                            this.deleteAsset(ce,idx)
                        } else {
                            //this.addAsset()
                            var data = {'commodities':this.currency_list,
                                        'asset_id_list':this.asset_id_list,
                                        'etr':this.myForm.controls['my_form_array_etr'].value.map(Number),
                                        'lag':this.myForm.controls['my_form_array_lag'].value.map(Number),
                                        'exp':this.myForm.controls['my_form_array_exp'].value.map(Number),
                                        'edit_asset_type_hash':this.edit_asset_type_hash,
                                        'orig_asset_type_hash':this.orig_asset_type_hash};
                            
                            this._apiService.updateModelId(this.endpoint,this.model_id,data).subscribe(x => {});

                        }
                    }); 
              } else {
                 
                  this.addAsset()
                  //this.deleteAsset(ce,idx);
              }



    }// end dialog

    openDialogDelete(ce,idx){
            if (this.crud=='edit' && (this.models_hedging.length>0)){
                    const config = new MatDialogConfig();
                    config.disableClose=true;

                  
                   
                    let dialogRef = this.dialog.open(DialogDeleteAssetComm,config);
                    
                    dialogRef.componentInstance.asset = ce;  //    http://stackoverflow.com/questions/34205593/working-example-of-angular-2-0-material-mddialog-with-angular-2-0/40185852#40185852
                    dialogRef.componentInstance.asset_type = 'comm';
                    dialogRef.componentInstance.models_hedging = this.models_hedging;
                    dialogRef.componentInstance.asset_id_list_item=this.asset_id_list[idx];
                    dialogRef.afterClosed().subscribe(result => {
                        if (result=='cancel'){
                            
                        } else {
                            this.deleteAsset(ce,idx)
                            //this.memory_analysis_horizon = this.myForm.controls['analysis_horizon'].value;
                            //save form now!!!!!!!!!!!!!!
                        }
                    }); 
              } else {
                  this.deleteAsset(ce,idx);
              }



    }// end dialog

    cleanStringArray(string_array){
        let cleaned = []
        for (var _j = 0; _j < string_array.length; _j++) {
            var is_neg = false;
            if (string_array[_j].charAt(0)=='-'){
                is_neg=true;
            }
            let temp = string_array[_j].replace(/[^0-9\.]+/g,"")
            if (is_neg==true){
                temp = '-'+temp;
            }
            cleaned.push(temp)
        }
        return cleaned
    }
 
    onSubmit() {
        this.is_submit = true;

        var result:any;

        if(this.crud=='new'){
            this.orig_asset_type_hash=this.edit_asset_type_hash;
        }
        
        let cleaned_exp = this.cleanStringArray(this.myForm.controls['my_form_array_exp'].value)

        var data = {'commodities':this.currency_list,
                    'asset_id_list':this.asset_id_list,
                    'etr':this.myForm.controls['my_form_array_etr'].value.map(Number),
                    'lag':this.myForm.controls['my_form_array_lag'].value.map(Number),
                    'tag':this.myForm.controls['my_form_array_tag'].value,
                    'rand':this.myForm.controls['my_form_array_rand'].value.map(Number),
                     'exp':cleaned_exp.map(Number),
                     'edit_asset_type_hash':this.edit_asset_type_hash,
                     'orig_asset_type_hash':this.orig_asset_type_hash};

        if (this.crud=='new'){
            result = this._apiService.addModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    this._router.navigateByUrl('models');
                });
        }

        if (this.crud=='edit'){
            result = this._apiService.updateModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    this._router.navigateByUrl('models');
            });
        }
        
       
        
        
       

    }
} 




/*
DELETE
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
*/


@Component({
  selector: 'delete_asset_dialog',
  templateUrl: './delete_asset_dialog.html',
})
export class DialogDeleteAssetComm {


    asset:string;  //    http://stackoverflow.com/questions/34205593/working-example-of-angular-2-0-material-mddialog-with-angular-2-0/40185852#40185852
    asset_type:string;
    models_hedging: any[] = [];
    asset_id_list_item:any;
  
  constructor(public dialogRef: MatDialogRef<DialogDeleteAssetComm>,
              private _apiService: ApiService) {


  } //end constructor

  onContinue(){

      let data_list = [];
            for (var _i = 0; _i < this.models_hedging.length; _i++) {
                let temp = {}
                temp['model_id']=this.models_hedging[_i].model_id;
                temp['method']='update';
                temp['asset']=this.asset;
                temp['asset_id_list_item']=this.asset_id_list_item;
                data_list.push(temp)
            } //end _i

            var result:any;
            let endpoint='/test/model/serverlistupdate';
            result = this._apiService.addModel(endpoint,data_list).subscribe(x => {
                this.dialogRef.close()
            }); //end result

        // hedge data to server side and deleted
  }

  



   onSubmit(){


   } //end onSubmit


}





/*
ADD
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
*/


@Component({
  selector: 'add_asset_dialog',
  templateUrl: './add_asset_dialog.html',
})
export class DialogAddAssetComm {


    asset:string;  //    http://stackoverflow.com/questions/34205593/working-example-of-angular-2-0-material-mddialog-with-angular-2-0/40185852#40185852
    asset_type:string;
    models_hedging: any[] = [];
    models_risk: any[] = [];
    asset_id_list_item:any;
    model_id:string;

    d1=true;
    d2=false;
  
  constructor(public dialogRef: MatDialogRef<DialogAddAssetComm>,
              private _apiService: ApiService) {


  } //end constructor

  onContinue(){

        
        this.d1=false;
        this.d2=true;
        
  }

   updateModels(){

       let data_list = [];
       
    
        for (var _i = 0; _i < this.models_hedging.length; _i++) {
            let temp = {}
            temp['model_type']='hedging';
            temp['model_id']=this.model_id;
            temp['model_id_hedging']=this.models_hedging[_i].model_id;
            temp['method']='add';
            temp['asset']=this.asset;
            temp['asset_type']=this.asset_type;
            temp['asset_id_list_item']=this.asset_id_list_item;
            data_list.push(temp)
        } //end _i

        for (var _i = 0; _i < this.models_risk.length; _i++) {
            let temp = {}
            temp['model_id']=this.model_id;
            temp['model_type']='risk';
            temp['model_id_risk']=this.models_risk[_i].model_id;
            temp['method']='add';
            temp['asset']=this.asset;
            temp['asset_type']=this.asset_type;
            temp['asset_id_list_item']=this.asset_id_list_item;
            data_list.push(temp)
        } //end _i
        
    
        var result:any;
        let endpoint='/test/model/serverlistupdate';
        result = this._apiService.addModel(endpoint,data_list).subscribe(x => {
            //this.dialogRef.close('cancel');
            this.dialogRef.close()
        }); //end result
        
        

       

       
      
    }

  



   onSubmit(){


   } //end onSubmit


}

