export class BasicSetup {
    analysis_horizon: string;
    analysis_date: string;
    base_currency: string;
    estimation_expo: string;
    seed: string;
    estimation_lambda: number;
    estimation_start: string;
    estimation_end: string;
}

