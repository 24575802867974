import { Component, OnInit, Renderer, OnDestroy,ViewEncapsulation,ViewChild  } from '@angular/core';
import {MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA} from '@angular/material';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { LoggedinService } from '../loggedin.service';
import { BusyService } from '../busy.service';
import {MatTabsModule,MatTabGroup} from '@angular/material';
@Component({
  selector: 'app-output',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OutputComponent implements OnInit {

     //http://localhost:4200/#/output/(output:details/(data:distributions))
 

     //http://localhost:4200/#/output/(output:details;id=11/(data:distributions;id=11))

    @ViewChild('tabbar') tabbar: MatTabGroup;
  
    //selectedIndex = 3;
    navLinks = [{"name":"VaR","link":['/output', {outlets: {'output': ['var']}}]},
                //{"name":"Scenarios","link":['/output', {outlets: {'output': ['scenarios']}}]},
                {"name":"Outputs","link":['/output', {outlets: {'output': ['outputs']}}]},
                {"name":"Output Details","link":['/output', {outlets: {'output': ['details',{outlets:{'data':['distributions']}}]}}]  }
                
                
    ]
    busy=true;
    constructor(private loggedinService: LoggedinService,
                private busyService: BusyService,
                private route: ActivatedRoute,
                public router: Router,
                private _apiService: ApiService,
                private renderer: Renderer,
                public dialog: MatDialog) {

                this.busyService.busyAnnounced$.subscribe(
                                busy => {
                                  this.busy = busy;
                                  if (this.busy==true){
                                    
                                  } else{
                                    //this.busyService.announceBusy(false);
                                  }
                });
                


                this.loggedinService.announceLoggedin(true);
                this.busyService.announceBusy(false);

                
    }

  ngOnInit() {

     //this.tabbar.selectedIndex = 3;

      //this.router.navigate(['/output', {outlets: {'output': ['var']}}]);

      this.router.navigateByUrl('/output/(output:var)');

          

  } //end ngOnInit

  onTabChange(){

    


  }



}
