import { Component, OnInit, ViewChild } from '@angular/core';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink,} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import {MatTabsModule,MatTabGroup} from '@angular/material';
import * as _ from "lodash";
declare var $: any;
declare var require: any;

//import '@angular/material/_theming.scss';
//include mat-core();

//import { mat-core } from '@angular/material/_theming.scss';

var Highcharts = require('highcharts/highstock');

@Component({
  selector: 'app-output-distributions',
  templateUrl: './output-distributions.component.html',
  styleUrls: ['./output-distributions.component.css','./output-distributions.component.scss']
})
export class OutputDistributionsComponent implements OnInit {

  show_table=false;
  isLoading=false;
  id:string;  
  data={};
  sub: any;
  analysis_horizon_keys = []; 
  selectedAnalysisHorizon:any;
  analysis_horizon:any;

  model_names_list = []
  selected_model_list = []
  tax_list = ["aftertax", "pretax"]
 
  //tax_array = [{'name':'After Tax','id':'aftertax'},{'name':'Pre Tax','id':'pretax'}]
  tax_array = [{'name':'Pre Tax','id':'pretax'}]
  type_array = [{'name':'Earnings','id':'E'},{'name':'Cash Flows','id':'CF'}]
  percentile_array = [
                      {'name':'05','id':'05'},
                      {'name':'10','id':'10'}
                      ]
  selectedPercentile='05';
  selectedType='CF';
  selectTaxType = 'pretax'
  //{'name':'01','id':'01'},
  endpoint='/output'

  selectedChartType = 'dist'

  //color_array = ['#F44336','#E91E63','#9C27B0','#673AB7','#3F51B5','#2196F3','#03A9F4','#00BCD4','#009688']
  //color_array = ['#3F51B5','#2196F3','#03A9F4','#00BCD4','#009688']
  color_array = ['#9C27B0','#FF9800','#009688','#CDDC39']

  myForm: FormGroup;
  myFormModels = new FormArray([]);
  

  constructor(private route: ActivatedRoute,
              private _router: Router,
              private _apiService: ApiService,
              private fb: FormBuilder) { 

                this.myForm = fb.group({
                    selected_models_list: this.myFormModels
                })
                
              this.route.params.map(params => params['id']).subscribe(id => { 
                                      this.id=id
                                      
              });
              let id = this.id
              let type = 'distributions'
              this.makeDistCharts(id,type)

              
  } //end constructor

  ngOnInit() {

  }

 getSeries(type,tax_type,ah,x,y){

        // x 50 
        // y 49

        /*
          "exp_value": [1.99],
          "q_01": [-0.27],
          "q_05": [0.35],
          "q_10": [0.71],
          "q_20": [1.15],
          "ES_01": [-0.63],
          "ES_05": [-0.05],
          "ES_10": [0.24],
          "ES_20": [0.6]



        */
        
        let temp_data = {}
        let series_temp = []
        let plotline_temp = []
        let percentile_temp = []
        let es_temp = []
        let series_hash = {}
        let series_hist_y_temp = []
        let series_hist_x_temp = []

       
        
        for (var _i = 0; _i < this.selected_model_list.length; _i++) {
            let mn = this.selected_model_list[_i]
          
            temp_data = this.data[mn][tax_type][ah][type];
           
            let data_den = _.zip(temp_data[x],temp_data[y]);
            let temp  = {
                color: this.color_array[_i],  
                showInLegend: true,     
                name: mn,
                dashStyle: "Solid",
                //color: 'rgba(223, 83, 83, .5)',
                lineWidth: 3,
                data: data_den,
            }
            series_temp.push(temp)

            let temp_y = {name: mn,data:temp_data[y]}
            series_hist_y_temp.push(temp_y)

            let temp_x = {name: mn,data:temp_data[x]}
            series_hist_x_temp.push(temp_x)

            
            let pl_temp = {}
            if (this.is_show_expectation==true){

                pl_temp = {
                        //colorName: 'ass', // Red
                        value: temp_data['exp_value'], // Position, you'll have to translate this to the values on your x axis
                        label:{
                            text:'Exp',
                            verticalAlign:"top",
                        },
                        color: this.color_array[_i], // Red
                        dashStyle: "Solid",
                        width: 1

                }

                 plotline_temp.push(pl_temp)

            } 

            if (this.is_show_percentile==true){

                pl_temp = {
                        label:{
                            //text:`q_${this.selectedPercentile}`,
                            text:this.data[mn][tax_type][ah][type][`q_${this.selectedPercentile}_name`][0],
                            verticalAlign:"top",
                            y:50
                        },
                        color: this.color_array[_i], // Red
                        dashStyle: "dash",
                        width: 1,
                        value: temp_data[`q_${this.selectedPercentile}` ] // Position, you'll have to translate this to the values on your x axis
                 }

                 plotline_temp.push(pl_temp)

            } 

            

            if (this.is_show_es==true){


                     pl_temp = {
                        label:{
                            //text:`ES_${this.selectedPercentile}`,
                            text:this.data[mn][tax_type][ah][type][`ES_${this.selectedPercentile}_name`][0],
                            verticalAlign:"middle"
                        },
                        color: this.color_array[_i], // Red
                        dashStyle: "dot", 
                        width: 1,
                        value: temp_data[`ES_${this.selectedPercentile}` ] // Position, you'll have to translate this to the values on your x axis
                   }

                 plotline_temp.push(pl_temp)

            } 


            

            /*
            let temp_lines = {}
            temp_lines["exp_value"]: [1.99],
            temp_lines["q_01"]: [-0.27],
            temp_lines["q_05"]: [0.35],
            temp_lines["q_10"]: [0.71],
            temp_lines["q_20"]: [1.15],
            temp_lines["ES_01"]: [-0.63],
            temp_lines["ES_05"]: [-0.05],
            temp_lines["ES_10"]: [0.24],
            temp_lines["ES_20"]: [0.6]
            */



        } //end _i

       let test = this.data[this.model_names_list[0]][tax_type][ah][type]
       var title = test["chart_title"][0]
       var chart_ylab = test["chart_ylab"][0]
       var chart_xlab = test["chart_xlab"][0]

        series_hash = {'series_list':series_temp}
        series_hash['series_list_y'] = series_hist_y_temp
        series_hash['series_list_x'] = series_hist_x_temp
        series_hash['plotline'] = plotline_temp

        series_hash['title'] = title
        series_hash['chart_ylab'] = chart_ylab
        series_hash['chart_xlab'] = chart_xlab
        
        return series_hash

   } //end getSeries



   test={}
   makeDistCharts(id,type){

      this._apiService.getOutput(this.endpoint,id,type)
                      .subscribe(data => {
                          this.data = data

                        


                          this.model_names_list = Object.keys(this.data);
                         
                          //this.selected_model_list = this.model_names_list;

                           for (var _i = 0; _i < this.model_names_list.length; _i++) {
                               if (_i<3){
                                    this.myFormModels.push(new FormControl(true));
                                    this.selected_model_list.push(this.model_names_list[_i])
                                } else {
                                    this.myFormModels.push(new FormControl(false));
                                }
                            }




                          let first_model = this.model_names_list[0]
                          let tax_type = this.selectTaxType
                          let analysis_horizon_keys = Object.keys(this.data[first_model][tax_type]);

                          
                          
                          let temp = []
                          for (var _i = 0; _i < analysis_horizon_keys.length; _i++) {
                            temp.push(`M${_i+1}`)
                          }
                          this.analysis_horizon_keys = temp
                          this.selectedAnalysisHorizon = this.analysis_horizon_keys.slice(-1)[0];

                          let ah = this.selectedAnalysisHorizon;
                          this.updateData()

                      }
                      ,null,() => { this.isLoading = false;
                                    this.show_table = true;
                        //this.busyService.announceBusy(false); 
                      
                      });


    } //end duder

    dudder(container_id,args,series_hash){

        

         let series_list = series_hash['series_list']
       

         //let data_den = this.density()

         var ah_lag = this.selectedAnalysisHorizon.replace("M", "")

         let data_den = _.zip(this.test[ args.x],this.test[ args.y]);
         let exp_value = 0 //this.test['exp_value'][0];

         
        
          let q = 0 //this.test[`q_${this.selectedPercentile}`]
          let ES = 0 //this.test[`ES_${this.selectedPercentile}`]

          var title = series_hash['title'] 
          var chart_ylab =  series_hash['chart_ylab'] 
          var chart_xlab =  series_hash['chart_xlab']

         $(function(){ 

            Highcharts.chart(container_id, {
                title: {
                    //text: `Earnings Distribution Plot: ${ah_lag} Month EaR`
                    text: title
                    
                },

                credits: {
                        enabled: false
                    },

                /*
                subtitle: {
                    text: 'Source: thesolarfoundation.com'
                },
                */

/*
                 A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  https://material.io/guidelines/style/color.html#
  */

                xAxis: {
                  title: {
                        text: chart_xlab
                    },
                    plotLines: series_hash['plotline']
                },


                yAxis: {
                    title: {
                        text:  chart_ylab
                    }
                },
                /*
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },
               */
                plotOptions: {
                    scatter: {
                            marker: {
                                radius: 5,
                                states: {
                                    hover: {
                                        enabled: true,
                                        lineColor: 'rgb(100,100,100)'
                                    }
                                }
                            },
                            states: {
                                hover: {
                                    marker: {
                                        enabled: false
                                    }
                                }
                            }
                           
                        }
                },
 
                series: series_list

                        })
        
         });
        




    } //end dudder


    updateData(){

        console.log('wtf')

        let series_hash = {}
        let arg_list = []
        arg_list.push({'type':'E','container_id':'container_density_e','x':"dist_x",'y':"dist_y"})
        //arg_list.push({'type':'E','container_id':'container_zoomdist_e','x':"zoomdist_x",'y':"zoomdist_y"})
        /////arg_list.push({'type':'CF','container_id':'container_density_cf','x':"dist_x",'y':"dist_y"})
        //arg_list.push({'type':'CF','container_id':'container_zoomdist_cf','x':"zoomdist_x",'y':"zoomdist_y"})

        for (var _i = 0; _i < arg_list.length; _i++) {
            let args = arg_list[_i]
          
            series_hash = this.getSeries(args.type,this.selectTaxType,this.selectedAnalysisHorizon,args.x,args.y)
           
            this.dudder(args.container_id,args,series_hash)
        }

    }

    onTaxTypeChange(){

        this.updateData()

    }

    onAnalysisHorizonChange(){
        this.updateData()
    }

    onPercentileChange(){

        this.updateData()
    
    }

    is_show_expectation = true;
    is_show_percentile = true
    is_show_es = true
    showExpectation(){
        this.updateData()
    }

    showPercentile(){
        this.updateData()
    } //

    showEs(){
        this.updateData()
    } //showEs

    onCbChange(i){
        
      

        this.selected_model_list = []
        let sel = this.myForm.controls['selected_models_list'].value
        for (var _i = 0; _i < sel.length; _i++) {
            if (sel[_i]==true){
                this.selected_model_list.push(this.model_names_list[_i])
            }
        }
        

        //must have one value checked
        if (this.selected_model_list.length>0){
            this.updateData()
        } else {
            let tt = []
            for (var _i = 0; _i < sel.length; _i++) {
                tt.push(false)
            }
            tt[i]=true
            this.myForm.controls['selected_models_list'].setValue(tt)
        }
    } //end onCbChange
    
    onChangeChartType(){
       
        if (this.selectedChartType=='dist'){

        }

        if (this.selectedChartType=='hist'){

        }
    } //onChangeChartType


}
