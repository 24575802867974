import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ApiService } from '../api_service/api.service';

 
export interface EndpointComponent {
    endpoint: string;
}


@Injectable()
export class CorFxResolve implements Resolve<EndpointComponent> {

  constructor(private _apiService: ApiService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    let id = route.params['id'];
    let model_id = id;
    let endpoint='/test/model/corfx';
    return new Promise((resolve) => { this._apiService.getModel(endpoint,model_id,false).subscribe(api_object => {
            if (api_object) {
                return resolve(api_object); 
            } else { // id not found
                this.router.navigateByUrl('/dashboard(content:models/corfx/' +  model_id +')');
                return true;
            }
            });

    });
  }
}