import {Component, OnInit, Output,EventEmitter,Provider, forwardRef, AfterViewInit} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { ModelValidators } from '../validators/validators';
import { CURRENCIES } from '../object_lists/assets_object';
import { BasicSetup } from './basic_setup'
import { FormComponent } from '../can_deactivate/prevent_unsaved_changes_gaurd.service';
import { LoggedinService } from '../loggedin.service';


import * as moment from 'moment/moment';


import * as _ from "lodash";
declare var $: any;
 
@Component({
  selector: 'app-mesa-basic-setup',
  templateUrl: './mesa-basic-setup.component.html',
  styleUrls: ['./mesa-basic-setup.component.css','../app.component.css']
})  
export class MesaBasicSetupComponent implements OnInit, AfterViewInit, FormComponent {

    endpoint='/test/model/basic';
    use_hash = false;
    myForm: FormGroup; 
    model_id: string; 
    isLoading = false;
    crud:string;
     
    basic_setup = new BasicSetup();
    analysis_horizon_max:number = 60;
    analysis_horizon_array: any[] = [];
    currency_array = CURRENCIES;

    model_name:string;



    test:any;
    testme='01/09/2001';
    //dateSelected= '01/09/2001';

    //estimation_method_list  = [{'value':'ew','name':'equal-weighting'},{'value':'es','name':'exponential-smoothing'}]
    estimation_method_list  = [{'value':'ew','name':'equal-weighting'}]
 
    adStartDate = new Date(2017, 0, 1); //set the control
    adMin = new Date(2005, 0, 1);
    startDateMin = new Date(2000, 0, 1); //set the control
    startDate = new Date(2017, 22, 9); //set the control 
    minDate = new Date(1990, 0, 1); //set the control
    maxDate = new Date(2030, 0, 1); //set the control
    //assu = moment("12/25/1995");
    wtf=moment("1995-12-25"); //set the input
    //myFilter = (d: Date) =>  d.getFullYear() >= 1990  

    constructor(
        private loggedinService: LoggedinService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private _apiService: ApiService) {

        this.loggedinService.announceLoggedin(true);

        this.model_name = localStorage.getItem('model_name');

        
        this.basic_setup.estimation_start = '1/1/2010';
        
        this.basic_setup.seed = '';

        this.myForm = fb.group({
            analysis_horizon: ['Select Analysis Horizon',[Validators.required]],
            analysis_date: [,[Validators.required]],
            //analysis_date: ['',[Validators.required,ModelValidators.validMaxAnalysisDate]],
            base_currency: ['USD'],
            estimation_expo: ['ew',[Validators.required]],
            //estimation_lambda: new FormControl({value: .94, disabled: true}, [Validators.required,ModelValidators.validLambda]),
            estimation_lambda: new FormControl(0.94, [Validators.required,ModelValidators.validLambda]),
            seed: new FormControl(1000, [ModelValidators.validPositiveIntegerSeed]),
            dateRange: new FormGroup({
                estimation_start: new FormControl('', [Validators.required,ModelValidators.validStartDate]),
                estimation_end: new FormControl('', [Validators.required,ModelValidators.validEndDate]),
                },this.validateDates) 
         })
         
    }


 

    onDateChangeTest(event){

        
    }

    validateDates(control: FormGroup) {
        
       
        var valid:any;
        
        var startdate = control.controls['estimation_start'].value;
        var enddate = control.controls['estimation_end'].value;
        if (startdate && enddate){
            var diff = new Date(enddate).valueOf() - new Date(startdate).valueOf();
          
            if (diff>=31622400000){
                valid=true;
            } else {
              
                valid=null;
            }
        } else {
            valid=null;
        }
       
        return valid ? null : { validateDates: true };
    }

    api_object:any; 
     
    models_id_hedging: any[] = [];
    analysis_horizon_maxval:number = 0;
    
    ngOnInit() {
        this.route.params.map(params => params['id'])
            .subscribe(id => { this.model_id=id });
 
        this.basic_setup.base_currency="USD";
        this.basic_setup.analysis_horizon = 'Select Analysis Horizon';
        //this.basic_setup.base_currency ='Select Base Currency';
        this.basic_setup.estimation_expo='ew';
        this.basic_setup.estimation_lambda=0.94;
        for (var _i = 0; _i < this.analysis_horizon_max; _i++) {
            this.analysis_horizon_array.push(_i+1);
        }



        this.route.data.forEach((data: { results: BasicSetup }) => {
          
            if (data.results.hasOwnProperty('status')){
                    this.crud='new';
                    let analysis_date_local = JSON.parse(localStorage.getItem('analysis_date'));
     
                    
                    this.myForm.controls['analysis_date'].setValue(moment(analysis_date_local, "MM/DD/YYYY").toDate())
                    this.myForm.controls['dateRange']['controls']['estimation_start'].setValue(moment('1/1,2010', "MM/DD/YYYY").toDate())
                    this.myForm.controls['dateRange']['controls']['estimation_end'].setValue(moment(analysis_date_local, "MM/DD/YYYY").toDate())

            
                    this.getSelectedHedgeModels();
            } else {
                    this.crud='edit';
                    this.basic_setup = data.results;
                    this.myForm.controls['dateRange']['controls']['estimation_start'].setValue(moment(this.basic_setup.estimation_start, "YYYY-MM-DD").toDate())
                    this.myForm.controls['dateRange']['controls']['estimation_end'].setValue(moment(this.basic_setup.estimation_end, "YYYY-MM-DD").toDate())
                    this.myForm.controls['analysis_date'].setValue(moment(this.basic_setup.analysis_date, "YYYY-MM-DD").toDate());
                    this.myForm.controls['analysis_horizon'].setValue(+data['results']["analysis_horizon"]);
                    this.myForm.controls['estimation_lambda'].setValue(data['results']["estimation_lambda"]);
                    this.myForm.controls['estimation_expo'].setValue(data['results']["estimation_expo"]);
                    try{
                        this.myForm.controls['seed'].setValue(data['results']["seed"]);
                    } catch(err) {
                        this.myForm.controls['seed'].setValue('');
                    }
                
                   
            }
        });

        
         

           /*
           $('#datepicker').datepicker({
            format: 'yyyy-mm-dd',
            //startDate: this.testme,
            autoclose: true
            }).on('changeDate', (ev) => {
                var newDate = new Date(ev.date);

                var d = new Date(ev.date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;

                this.basic_setup.analysis_date = [year, month, day].join('-');
              


                //this.basic_setup.analysis_date = newDate.toLocaleDateString();//ev.date;
                //var splitted = newDate.toLocaleDateString().split("/");
                //this.basic_setup.analysis_date = splitted[2] + '-' + splitted[1] + '-' + splitted[0]
            });
           

            $('#start_date_datepicker').datepicker({
            format: 'yyyy-mm-dd',
            //startDate: this.testme,
            autoclose: true
            }).on('changeDate', (ev) => {
                var newDate = new Date(ev.date)

                 var d = new Date(ev.date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;

                this.basic_setup.estimation_start = [year, month, day].join('-');
                //this.basic_setup.analysis_date = newDate.toLocaleDateString();//ev.date;
                //var splitted = newDate.toLocaleDateString().split("/");
                //this.basic_setup.estimation_start = splitted[2] + '-' + splitted[1] + '-' + splitted[0]
            });

            $('#end_date_datepicker').datepicker({
            format: 'yyyy-mm-dd',
            //startDate: this.testme,
            autoclose: true
            }).on('changeDate', (ev) => {
                var newDate = new Date(ev.date)

                 var d = new Date(ev.date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;

                this.basic_setup.estimation_end = [year, month, day].join('-');
                //this.basic_setup.analysis_date = newDate.toLocaleDateString();//ev.date;
                //var splitted = newDate.toLocaleDateString().split("/");
                //this.basic_setup.estimation_end = splitted[2] + '-' + splitted[1] + '-' + splitted[0]
            });
             */
        
          
        
        
    }

    getSelectedHedgeModels(){

        //Get the hedging models selected
        this._apiService.getModel('/test/model',this.model_id,true)
            .subscribe(api_object => {
                this.models_id_hedging = api_object['models_id_exposure'];
               
                this.getHedgeModelData(api_object['models_id_exposure']);
                
                
          },
          null,() => { this.isLoading = false; });


    }

    analysis_horizon_array_test: number[] = [];
    getHedgeModelData(model_array){
         let analysis_horizon = 0;
         let analysis_horizon_temp = 0;
      
         for (var _i = 0; _i < model_array.length; _i++) {
                    
                    this._apiService.getModel('/test/model',model_array[_i],true)
                        .subscribe(api_object => {
                          
                            analysis_horizon_temp = +api_object['data']['hedgeinitial']['analysis_horizon'];
                          
                            this.analysis_horizon_array_test.push(analysis_horizon_temp);
                            if (analysis_horizon_temp>this.analysis_horizon_maxval){
                               
                                this.analysis_horizon_maxval = analysis_horizon_temp;
                            }
                            this.myForm.controls['analysis_horizon'].setValue(this.analysis_horizon_maxval);
                            
                    },
                    null,() => { 
                        this.isLoading = false;
                      
                        this.basic_setup.analysis_horizon = Math.max.apply(Math, this.analysis_horizon_array_test);
                    });
          }
          
        
    }

    
    ngAfterViewInit() {
        // Your jQuery code goes here
       //$('[data-toggle="tooltip"]').tooltip();
       //$.material.init();
    }

    onDateChange(event){


    }

    radioEstimationMethodChange(){
        //this.model_parameters_general.estimationmethod=event;
        let event = this.myForm.controls['estimation_expo'].value
       
        if (event=='es'){
            //this.myForm.controls['estimation_lambda'].enable();
        } else {
            //this.myForm.controls['estimation_lambda'].disable();
        }
    }

    back(){
        this._router.navigateByUrl('models/edit/' +  this.model_id + ';model=risk');    //{model:'risk'}
    }
 
    onSubmit() {



        var result:any;

        /*
        this.basic_setup.analysis_horizon = this.myForm.controls['analysis_horizon'].value
        this.basic_setup.base_currency='USD';
        this.basic_setup.estimation_lambda = this.myForm.controls['estimation_lambda'].value
        this.basic_setup.estimation_expo = this.myForm.controls['estimation_expo'].value
        this.basic_setup.seed = this.myForm.controls['seed'].value
       
        this.basic_setup.analysis_date=moment(this.myForm.controls["analysis_date"].value).format('YYYY-MM-DD')
        this.basic_setup.estimation_start=moment(this.basic_setup.estimation_start).format('YYYY-MM-DD')
        this.basic_setup.estimation_end=moment(this.basic_setup.estimation_end).format('YYYY-MM-DD')
        */

        let data = {}
        data['analysis_horizon'] = this.myForm.controls['analysis_horizon'].value
        data['base_currency']='USD';
        data['estimation_lambda'] = this.myForm.controls['estimation_lambda'].value
        data['estimation_expo'] = this.myForm.controls['estimation_expo'].value
        data['seed'] = this.myForm.controls['seed'].value
       
        data['analysis_date']=moment(this.myForm.controls["analysis_date"].value).format('YYYY-MM-DD')
        data['estimation_start']=moment(this.myForm.controls['dateRange']['controls']['estimation_start'].value).format('YYYY-MM-DD')
        data['estimation_end']=moment(this.myForm.controls['dateRange']['controls']['estimation_end'].value).format('YYYY-MM-DD')


      

        if (this.crud=='new'){
            
            result = this._apiService.addModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    this._router.navigateByUrl('models/marketriskfactors/' +  this.model_id + ';model=risk');
                });
        }

        if (this.crud=='edit'){
            this.basic_setup.base_currency='USD';

            result = this._apiService.updateModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    this._router.navigateByUrl('models/marketriskfactors/' +  this.model_id + ';model=risk');
            });
            
        }
      
       

    }
} 
