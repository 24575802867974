import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './common/auth.guard';
import { LoginComponent } from './login/login.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardResolve } from './dashboard/dashboard_resolve.service';

import { ModelsComponent } from './models/models.component';
import { ModelFormComponent } from './models/model-form.component';
import { ModelFormResolve } from './models/model-form_resolve.service';

import { BasicSetupResolve }  from './mesa-basic-setup/basic_setup_resolve.service';
import { MesaBasicSetupComponent } from './mesa-basic-setup/mesa-basic-setup.component';

import { MesaHedgeInitialComponent} from './mesa-hedge-initial/mesa-hedge-initial.component';
import { HedgeInitialResolve } from './mesa-hedge-initial/mesa_hedge_initial_resolve.service';

import { HedgeExpComResolve } from './mesa-hedge-expcom/mesa_hedge_expcom_resolve.service';
import { MesaHedgeExpcomComponent} from './mesa-hedge-expcom/mesa-hedge-expcom.component';


import { HedgeExpFxResolve } from './mesa-hedge-expfx/mesa_hedge_expfx_resolve.service';
import { MesaHedgeExpfxComponent} from './mesa-hedge-expfx/mesa-hedge-expfx.component';

import { BasicMrfResolve }  from './mesa-mrf/basic_mrf_resolve.service';
import { MesaMrfComponent } from './mesa-mrf/mesa-mrf.component';

 

import { CanDeactivateGuard } from './can_deactivate/can-deactivate-guard.service';


import { AssetMenuComponent } from './asset-menu/asset-menu.component';
 
import { CeMrResolve } from './mesa-ce-mr/mesa_ce_mr_resolve.service';
import { MesaCeMrComponent } from './mesa-ce-mr/mesa-ce-mr.component';

import { CeExpResolve } from './mesa-ce-exp/mesa_ce_exp_resolve.service';
import { MesaCeExpComponent } from './mesa-ce-exp/mesa-ce-exp.component';

import { CeVolResolve } from './mesa-ce-vol/mesa_ce_vol_resolve.service';
import { MesaCeVolComponent } from './mesa-ce-vol/mesa-ce-vol.component';


import { ComMrResolve } from './mesa-com-mr/mesa_com_mr_resolve.service';
import { MesaComMrComponent } from './mesa-com-mr/mesa-com-mr.component';

import { ComVolResolve } from './mesa-com-vol/mesa_com_vol_resolve.service';
import { MesaComVolComponent } from './mesa-com-vol/mesa-com-vol.component';

import { ComExpResolve } from './mesa-com-exp/mesa_com_exp_resolve.service';
import { MesaComExpComponent } from './mesa-com-exp/mesa-com-exp.component';

import { CorComResolve } from './mesa-cor-com/mesa_cor_com_resolve.service';
import { MesaCorComComponent } from './mesa-cor-com/mesa-cor-com.component';

import { CorCrossResolve } from './mesa-cor-cross/mesa_cor_cross_resolve.service';
import { MesaCorCrossComponent } from './mesa-cor-cross/mesa-cor-cross.component';

import { CorFxResolve } from './mesa-cor-fx/mesa_cor_fx_resolve.service';
import { MesaCorFxComponent } from './mesa-cor-fx/mesa-cor-fx.component';


import { ChangePasswordComponent } from './change-password/change-password.component';


import { HedgePanelResolve } from './mesa-hedge-panel/mesa_hedge_panel_resolve.service';
import { MesaHedgePanelComponent } from './mesa-hedge-panel/mesa-hedge-panel.component';


import { OutputComponent } from './output/output.component';
import { MesaOutputVarComponent } from './mesa-output-var/mesa-output-var.component';
import { OutputVarResolve } from './mesa-output-var/mesa-output-var_resolve.service';
import { MesaOutputOutputsComponent } from './mesa-output-outputs/mesa-output-outputs.component';

import { MesaOutputDetailsComponent } from './mesa-output-details/mesa-output-details.component';
//import { OutputDetailResolve } from './mesa-output-details/mesa-output-details_resolve.service';

import { OutputDistributionsComponent } from './output-distributions/output-distributions.component';
import { OutputExposuretablesComponent } from './output-exposuretables/output-exposuretables.component';
import { OutputMonthlyriskComponent } from './output-monthlyrisk/output-monthlyrisk.component';
import { OutputHedgingcostComponent } from './output-hedgingcost/output-hedgingcost.component';


 

export const ROUTES: Routes = [

    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login',  component: LoginComponent},
  
    { path: 'dashboard',  component: DashboardComponent, canActivate: [AuthGuard] ,resolve: {results: DashboardResolve}}, 

    { path: 'models',component: ModelsComponent, canActivate: [AuthGuard] },
     
    { path: 'models/new',component: ModelFormComponent, canActivate: [AuthGuard], resolve: {results: ModelFormResolve}},
    { path: 'models/edit/:id',component: ModelFormComponent, canActivate: [AuthGuard], resolve: {results: ModelFormResolve}},

    { path: 'models/hedgeinitial/:id', component: MesaHedgeInitialComponent,canActivate: [AuthGuard],  resolve: {results: HedgeInitialResolve} },
    { path: 'models/hedgeexpfx/:id', component: MesaHedgeExpfxComponent,canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard], resolve: {results: HedgeExpFxResolve}},
    { path: 'models/hedgeexpcom/:id', component: MesaHedgeExpcomComponent,canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard], resolve: {results: HedgeExpComResolve}},

    { path: 'models/basic/:id', component:  MesaBasicSetupComponent, canActivate: [AuthGuard], resolve: {results: BasicSetupResolve} },
    { path: 'models/marketriskfactors/:id', component: MesaMrfComponent,canActivate: [AuthGuard], resolve: {results: BasicMrfResolve} },
   
    
    { path: 'models/cemr/:id', component: MesaCeMrComponent,canActivate: [AuthGuard], resolve: {results: CeMrResolve} },
    { path: 'models/cevol/:id', component: MesaCeVolComponent,canActivate: [AuthGuard], resolve: {results: CeVolResolve} },
    { path: 'models/ceexp/:id', component: MesaCeExpComponent,canActivate: [AuthGuard], resolve: {results: CeExpResolve} },
    
    { path: 'models/commr/:id', component: MesaComMrComponent,canActivate: [AuthGuard], resolve: {results: ComMrResolve} },
    { path: 'models/comvol/:id', component: MesaComVolComponent,canActivate: [AuthGuard], resolve: {results: ComVolResolve} },
    { path: 'models/comexp/:id', component: MesaComExpComponent,canActivate: [AuthGuard], resolve: {results: ComExpResolve} },
    { path: 'models/corfx/:id', component: MesaCorFxComponent,canActivate: [AuthGuard], resolve: {results: CorFxResolve} },
    { path: 'models/corcom/:id', component: MesaCorComComponent,canActivate: [AuthGuard], resolve: {results: CorComResolve}},
    { path: 'models/corcross/:id', component: MesaCorCrossComponent,canActivate: [AuthGuard], resolve: {results: CorCrossResolve} },
 
   
    { path: 'models/hedgepanel/:id', component: MesaHedgePanelComponent,canActivate: [AuthGuard], resolve: {results: HedgePanelResolve} },
    
    { path: 'password',component: ChangePasswordComponent, canActivate: [AuthGuard] },

   
 
    { path: 'output',component: OutputComponent, canActivate: [AuthGuard], children: [
         
        
        { path: '', redirectTo: 'var', pathMatch: 'full' },
        
        { path: 'var',component: MesaOutputVarComponent, canActivate: [AuthGuard], resolve: {results: OutputVarResolve}, outlet:'output'},

        { path: 'outputs',component: MesaOutputOutputsComponent, canActivate: [AuthGuard], resolve: {results: OutputVarResolve}, outlet:'output'},
        
        { path: 'details',component: MesaOutputDetailsComponent, canActivate: [AuthGuard], outlet:'output',children: [
           
            { path: '', redirectTo: 'distributions', pathMatch: 'full' },
            { path: 'distributions',component: OutputDistributionsComponent, canActivate: [AuthGuard], outlet:'data'},
            { path: 'exposuretables',component: OutputExposuretablesComponent, canActivate: [AuthGuard], outlet:'data'},
            { path: 'monthlyrisk',component: OutputMonthlyriskComponent, canActivate: [AuthGuard], outlet:'data'},
            { path: 'hedgingcost',component: OutputHedgingcostComponent, canActivate: [AuthGuard], outlet:'data'},
        
        ]}
         
       
       ]
     }
    
   
]
