import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-dialog-route-can-deactive',
  templateUrl: './dialog-route-can-deactive.component.html',
  styleUrls: ['./dialog-route-can-deactive.component.css']
})
export class DialogRouteCanDeactiveComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogRouteCanDeactiveComponent>) {} //end constructor

  ngOnInit() {
  }

}

 