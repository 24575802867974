import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ApiService } from '../api_service/api.service';

import { CURRENCIES, COMMODITIES } from '../object_lists/assets_object';

@Injectable()
export class BasicMrfResolve implements Resolve<any> {

  constructor(private _apiService: ApiService, private router: Router) {}


  currency_array = CURRENCIES;
  commodities_array = COMMODITIES;
  selected_currency_array: any[] = [];
  selected_commodity_array: any[] = [];
  onEditLoad(api_object:any){
     
      let api_object_edit:any;
      if (api_object.hasOwnProperty('status')){
                api_object_edit = api_object;
      } else {
            
            for (var _i = 0; _i < api_object.currencies.length; _i++) {
                //var this_asset = this.currency_array.find(x => x.displayname === api_object.currencies[_i]);
                //this.selected_currency_array.push(this_asset);
                //var index = this.currency_array.indexOf(this_asset, 0);
                //this.currency_array.splice(index, 1);
                //api_object_edit['selected_currency_array'] = this.selected_currency_array;
            }
            
           api_object_edit = api_object;
        }

      return api_object_edit;
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    let id = route.params['id'];
    let model_id = id;
    let endpoint='/test/model/marketriskfactors';
    return new Promise((resolve) => { this._apiService.getModel(endpoint,model_id,false).subscribe(api_object => {
            if (api_object) {
                //let api_object_edit = this.onEditLoad(api_object);
                return resolve(api_object); 
            } else { // id not found
                this.router.navigateByUrl('/dashboard(content:models/marketriskfactors/' +  model_id +')');
                return true;
            }
            }); 

    });


  }
}
