import { FormControl } from '@angular/forms';


export class ModelValidators{


    /*
	static cannotContainSpace(control: Control){
		if (control.value.indexOf(' ')>=0 )
			return { cannotContainSpace: true}
		return null;
  
	}
	(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)
	*/

	static isCurrencyFormatValidFloat(control: FormControl){

		var test  = control.value.replace(/[^0-9\.]+/g,"")

		var val = parseFloat(test)
		var isNum = isNaN(val)
		
		var valid: any;
		valid=null;
		if (isNum==false){
			valid = true;
		}
		
		//valid = true;
		return valid ? null : { isCurrencyFormatValidFloat: true };
	}

  
	static cannotContainSpace(control: FormControl){

		var regEx = /^\S*$/;
        var valid = regEx.test(control.value);
        return valid ? null : { cannotContainSpace: true };
  
	}

	static passwordControls(control: FormControl){

		var regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,100})/;
		var valid = regEx.test(control.value);

		return valid ? null : { passwordControls: true };
		  
	}



	static onlyAlphaNurmeric(control: FormControl){

		var regEx = /^[a-zA-Z0-9]*$/;
        var valid = regEx.test(control.value);
        return valid ? null : { onlyAlphaNurmeric: true };
  
	}
 
	static onlyAlphaAndLowerCase(control: FormControl){

		var regEx = /^[a-z]*$/;
        var valid = regEx.test(control.value);
        return valid ? null : { onlyAlphaAndLowerCase: true };
  
	}

	static validPositiveInteger(control: FormControl){

		var regEx = /^\+?(0|[1-9]\d*)$/;
        var valid = regEx.test(control.value);
        return valid ? null : { validPositiveInteger: true };

	}

	static validPositiveIntegerSeed(control: FormControl){
			
		        valid=null
				if (!control.value || 0 === control.value.length){
					valid = true;
					return valid ? null : { validPositiveIntegerSeed: true };
				}

				var regEx = /^\+?(0|[1-9]\d*)$/;
				var valid = regEx.test(control.value);
				if (valid){
					
					if (control.value>=1 && control.value<=1000){
						valid = true
					} else {
						valid = false
					}
				} else {
					valid=null
				}

				return valid ? null : { validPositiveIntegerSeed: true };
		
	}

	static validLag(control: FormControl){

        var valid: any;
		valid=null;

		var is_int = control.value % 1 === 0

		//var regEx = /^\+?(0|[1-9]\d*)$/;
        //var isInt = regEx.test(control.value);

		if (control.value>=0 && control.value<=60 && is_int==true){
			valid = true;
		}

        return valid ? null : { validLag: true };

	}

	static validPositiveFloat(control: FormControl){
		var valid: any;
		valid=null;
		if (control.value>=0){
			valid = true;
		}
        return valid ? null : { validLambda: true };
  
	}

	static validPercentage(control: FormControl){
		var valid: any;
		valid=null;
		if (control.value>=0 && control.value<=1.0){
			valid = true;
		}
        return valid ? null : { validPercentage: true };
  
	}

	static validMaxAnalysisDate(control: FormControl){
        var valid: any;
		valid=null;
		var diff = new Date('2016-08-15').valueOf() - new Date(control.value).valueOf();

		if (diff>=0){
			valid=true;
		}

        return valid ? null : { validMaxAnalysisDate: true };
  
	}

	static validVar(control: FormControl){
		var valid: any;
		valid=null;
		if (control.value>=.8 && control.value<1){
			valid = true;
		}
		
        return valid ? null : { validVar: true };
  
	}

	static validLambda(control: FormControl){
		var valid: any;
		valid=null;
		if (control.value>0 && control.value<1){
			valid = true;
		}
        return valid ? null : { validLambda: true };
  
	}

	static validEtr(control: FormControl){
		var valid: any;
		valid=null;
		if (control.value>=0 && control.value<1){
			valid = true;
		}
        return valid ? null : { validEtr: true };
  
	}




	

	static validStartDate(control: FormControl){
		//http://stackoverflow.com/questions/14980014/how-can-i-calculate-the-time-between-2-dates-in-typescript
		var valid: any;
		valid=null;
        var diff:any;
		
		diff = new Date(control.value).valueOf() - new Date('2000-01-01').valueOf();
		
		if (diff>=0){
			valid=true;
		}
        return valid ? null : { validStartDate: true };
  
	}

	static validEndDate(control: FormControl){
		//http://stackoverflow.com/questions/14980014/how-can-i-calculate-the-time-between-2-dates-in-typescript
		var valid: any;
		valid=null;
        var diff:any;
		
		diff = new Date(control.value).valueOf() - new Date('2002-01-01').valueOf();
	
		if (diff>0){
			valid=true;
		}
        return valid ? null : { validEndDate: true };
  
	}
ups

	/*
    static startsWithNumber(control: Control): ValidationResult { 
 
		if ( control.value !=”” && !isNaN(control.value.charAt(0)) ){
			return { “startsWithNumber”: true };
		}
 
		return null;
	}
	*/

	static cannotContainUppercase(control: FormControl){
		if (control.value.indexOf(' ')>=0 )
			return { cannotContainUppercase: true}

		return null;

	}


}

