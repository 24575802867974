import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';

declare var require: any;
import { Highcharts } from 'angular-highcharts';
require('highcharts/modules/exporting')(Highcharts);
 
import { AppComponent } from './app.component';
import { LoginComponent,DialogForgotDialog } from './login/login.component';

 
import { DashboardResolve } from './dashboard/dashboard_resolve.service';
import { DashboardComponent,DialogTopline } from './dashboard/dashboard.component';

import { ModelsComponent,DialogClone,DialogShareModelId,DialogUpload,DialogDownload,UploadErrorSnackBarComponent } from './models/models.component';

import { ModelFormComponent } from './models/model-form.component';
import { ModelFormResolve } from './models/model-form_resolve.service';

import { BasicSetupResolve }  from './mesa-basic-setup/basic_setup_resolve.service';
import { MesaBasicSetupComponent } from './mesa-basic-setup/mesa-basic-setup.component';

import { MyCurrencyPipe } from './my-currency.pipe';
import { CurrencyPipe, DecimalPipe } from '@angular/common';

import { MesaHedgeInitialComponent} from './mesa-hedge-initial/mesa-hedge-initial.component';
import { HedgeInitialResolve } from './mesa-hedge-initial/mesa_hedge_initial_resolve.service';

import { HedgeExpFxResolve } from './mesa-hedge-expfx/mesa_hedge_expfx_resolve.service';
import { MesaHedgeExpfxComponent } from './mesa-hedge-expfx/mesa-hedge-expfx.component';

import { HedgeExpComResolve } from './mesa-hedge-expcom/mesa_hedge_expcom_resolve.service';
import { MesaHedgeExpcomComponent} from './mesa-hedge-expcom/mesa-hedge-expcom.component';

import { BasicMrfResolve }  from './mesa-mrf/basic_mrf_resolve.service';
import { MesaMrfComponent } from './mesa-mrf/mesa-mrf.component';

import { CanDeactivateGuard } from './can_deactivate/can-deactivate-guard.service';

import { AssetMenuComponent } from './asset-menu/asset-menu.component';
 
import { CeMrResolve } from './mesa-ce-mr/mesa_ce_mr_resolve.service';
import { MesaCeMrComponent } from './mesa-ce-mr/mesa-ce-mr.component';

import { CeExpResolve } from './mesa-ce-exp/mesa_ce_exp_resolve.service';
import { MesaCeExpComponent } from './mesa-ce-exp/mesa-ce-exp.component';

import { CeVolResolve } from './mesa-ce-vol/mesa_ce_vol_resolve.service';
import { MesaCeVolComponent } from './mesa-ce-vol/mesa-ce-vol.component';


import { ComMrResolve } from './mesa-com-mr/mesa_com_mr_resolve.service';
import { MesaComMrComponent } from './mesa-com-mr/mesa-com-mr.component';

import { ComVolResolve } from './mesa-com-vol/mesa_com_vol_resolve.service';
import { MesaComVolComponent } from './mesa-com-vol/mesa-com-vol.component';

import { ComExpResolve } from './mesa-com-exp/mesa_com_exp_resolve.service';
import { MesaComExpComponent } from './mesa-com-exp/mesa-com-exp.component';

import { CorComResolve } from './mesa-cor-com/mesa_cor_com_resolve.service';
import { MesaCorComComponent } from './mesa-cor-com/mesa-cor-com.component';

import { CorCrossResolve } from './mesa-cor-cross/mesa_cor_cross_resolve.service';
import { MesaCorCrossComponent } from './mesa-cor-cross/mesa-cor-cross.component';

import { CorFxResolve } from './mesa-cor-fx/mesa_cor_fx_resolve.service';
import { MesaCorFxComponent } from './mesa-cor-fx/mesa-cor-fx.component';

import { ChangePasswordComponent } from './change-password/change-password.component';

import { HedgePanelResolve } from './mesa-hedge-panel/mesa_hedge_panel_resolve.service';
import { MesaHedgePanelComponent } from './mesa-hedge-panel/mesa-hedge-panel.component';

import { OutputComponent } from './output/output.component';
import { MesaOutputVarComponent } from './mesa-output-var/mesa-output-var.component';
import { OutputVarResolve } from './mesa-output-var/mesa-output-var_resolve.service';
import { MesaOutputOutputsComponent } from './mesa-output-outputs/mesa-output-outputs.component';
import { MesaOutputDetailsComponent } from './mesa-output-details/mesa-output-details.component';
//import { OutputDetailResolve } from './mesa-output-details/mesa-output-details_resolve.service';


import { OutputDistributionsComponent } from './output-distributions/output-distributions.component';
import { OutputExposuretablesComponent } from './output-exposuretables/output-exposuretables.component';
import { OutputMonthlyriskComponent } from './output-monthlyrisk/output-monthlyrisk.component';
import { OutputHedgingcostComponent } from './output-hedgingcost/output-hedgingcost.component';



import { LoggedinService } from './loggedin.service';
import { BusyService } from './busy.service';
import { PollingService } from './polling.service';
import { ModelRefreshService } from './model-refresh.service';
import { ApiService } from './api_service/api.service';
import { DbBusyService } from './dbbusy.service';

import {MatButtonModule, MatCheckboxModule,MatMenuModule,MatIconModule,MatToolbarModule,MatCardModule,
  MatInputModule,MatDialogModule,MatSnackBarModule,MatButtonToggleModule,MatSidenavModule,MatListModule,
  MatProgressBarModule,MatProgressSpinnerModule,MatSelectModule,MatRadioModule,MatDatepickerModule, MatNativeDateModule,
  MatTooltipModule,MatSlideToggleModule,MatTabsModule,MatExpansionModule,MatTableModule} from '@angular/material';


import { AuthGuard } from './common/auth.guard';
import { Http, RequestOptions } from '@angular/http';
import { AuthService } from './auth/auth.service';
import { AuthHttp, AuthConfig } from 'angular2-jwt';

export function authHttpServiceFactory(http: Http, options: RequestOptions) {
  return new AuthHttp(new AuthConfig({
        headerName: 'Authorization',
        headerPrefix: 'bearer',
        tokenName: 'token',
		tokenGetter: (() => localStorage.getItem('id_token')),
		globalHeaders: [{'Content-Type':'application/json'}],
	}), http, options);
}




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DialogForgotDialog,
    DashboardComponent,
    DialogTopline,
    ModelsComponent,
    ModelFormComponent,
    MesaBasicSetupComponent,
    MesaHedgeInitialComponent,
    MesaHedgeExpfxComponent,
    MesaHedgeExpcomComponent,
    MesaMrfComponent,
    MyCurrencyPipe,
    AssetMenuComponent,MesaCeMrComponent,MesaCeExpComponent,MesaCeVolComponent,
    MesaComMrComponent,MesaComVolComponent,MesaComExpComponent,MesaCorComComponent,MesaCorCrossComponent,MesaCorFxComponent,
    ChangePasswordComponent,
    MesaHedgePanelComponent,
    DialogClone,DialogShareModelId,DialogUpload,DialogDownload,UploadErrorSnackBarComponent,
    OutputComponent,MesaOutputVarComponent,MesaOutputOutputsComponent,MesaOutputDetailsComponent,
    OutputDistributionsComponent,OutputExposuretablesComponent,OutputMonthlyriskComponent,
    OutputHedgingcostComponent
  ],
  entryComponents: [
    DialogForgotDialog,
    DialogTopline,
    DialogClone,DialogShareModelId,DialogUpload,DialogDownload,UploadErrorSnackBarComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,HttpModule,
    MatButtonModule, MatCheckboxModule,MatMenuModule,MatIconModule,MatToolbarModule,MatInputModule,
    MatCardModule,MatDialogModule,MatSnackBarModule,MatButtonToggleModule,MatSidenavModule,MatListModule,
    MatDatepickerModule, MatNativeDateModule,MatTooltipModule,MatSlideToggleModule,MatTabsModule,
    MatSelectModule,MatRadioModule,MatExpansionModule,MatTableModule,
    MatProgressBarModule,MatProgressSpinnerModule,
    RouterModule.forRoot(ROUTES, { useHash: true })
  ],
  providers: [
    LoggedinService,
    BusyService,
    PollingService,
    ModelRefreshService,
    AuthService,
    ApiService,
    AuthGuard,
    DbBusyService,
    DashboardResolve,
    ModelFormResolve,
    BasicSetupResolve,
    HedgeInitialResolve,
    HedgeExpFxResolve,
    HedgeExpComResolve,
    BasicMrfResolve,
    CanDeactivateGuard,
    MyCurrencyPipe,CurrencyPipe, DecimalPipe,
    CeMrResolve,CeExpResolve,CeVolResolve,
    ComMrResolve,ComVolResolve, ComExpResolve,CorComResolve,CorCrossResolve,CorFxResolve,
    HedgePanelResolve,
    OutputVarResolve
,
    {
      provide: AuthHttp,
      useFactory: authHttpServiceFactory,
      deps: [Http, RequestOptions]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
