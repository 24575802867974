import {Component, OnInit, Output,EventEmitter,Provider, forwardRef} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { ModelValidators } from '../validators/validators';
import { CURRENCIES, COMMODITIES } from '../object_lists/assets_object';
import { Assets } from '../object_lists/assets';
import { MarketRiskFactors } from './mrf';
import { LoggedinService } from '../loggedin.service';

import * as _ from "lodash";


@Component({
   templateUrl: './mesa-mrf.component.html',
    styleUrls: ['./mesa-mrf.component.css'],
    providers: [RouterLink, FormBuilder, ApiService ]
}) 
export class MesaMrfComponent implements OnInit {

    
    endpoint='/test/model/marketriskfactors';

    model_id: string;
    isLoading = false;
    crud:string;

    selectedCurrency:string;
    selectedCommodity:string;

    //currency_array = CURRENCIES;
    //commodities_array = COMMODITIES;

    currency_array: any[] = [];
    commodities_array: any[] = [];

    sorted_currency_array = []
    sorted_commodities_array = []
    



    selected_currency_array: any[] = [];
    selected_commodity_array: any[] = [];

    prior_currencies: any[] = [];
    prior_commodities:  any[] = [];

    currency_form_array = new FormArray([]);
    commodity_form_array = new FormArray([]);
    myForm: FormGroup;
    currencyformArray = new FormArray([]);
    commodityformArray = new FormArray([]);
    isRun=false;
    test="false";

    forward_title:string
    back_title = 'BASIC SETUP';
    is_edit = false;
    is_edit_hedge = false;

    model_name:string
    constructor(
        private loggedinService: LoggedinService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private _apiService: ApiService) {

       this.model_name = localStorage.getItem('model_name'); 


       this.loggedinService.announceLoggedin(true);

        this.myForm = fb.group({
            //currency_form_array: this.currencyformArray,
            //commodity_form_array: this.commodityformArray
            //commodities_list:  this.selected_commodity_array,
         })
        
    }
 
    api_object:any;
    mydata: any[] = [];
    model_type='hedging';  

    is_comm=false;
    forward_link:string

    ngOnInit() {
        //$.material.init();

        let currency_list = JSON.parse(localStorage.getItem('currency_list'));
        let commodity_list = JSON.parse(localStorage.getItem('commodity_list'));

        

        for (var _i = 0; _i < currency_list.length; _i++) {
            //This needs to be made dynaic
            if (currency_list[_i].displayname!='USD'){
                this.currency_array.push(currency_list[_i]);
            }
        }

        for (var _i = 0; _i < commodity_list.length; _i++) {
            this.commodities_array.push(commodity_list[_i]);
        }
        
 
        

        //this.model_type = params['model']
        //this.market_risk_factors = new MarketRiskFactors(); 
        this.selectedCurrency = 'Select Currency';
        this.selectedCommodity = 'Select Commodity';


        

        /*
        this.route.params.map(params => params['id'])
            .subscribe(id => { this.model_id=id });
        */
        this.route.params.subscribe(params => {
            this.model_type = params['model']
            this.model_id = params['id']; 

        });


        if (this.model_type=='exposure'){

            this.back_title = 'MODEL NAME';
            this.model_type=='exposure';
        }
        if (this.model_type=='risk'){
            //this.forward_title = 'CURRENCY: MEAN REVERSION';
            this.getSelectedHedgeModels();
        } else{
          // this.model_type=='hedging';
            
        }

        this.route.data.forEach((data) => {
            if (data['results'].hasOwnProperty('status')){
                    this.crud='new';
                    this.test="true";
            } else {
                this.crud='edit';

                if (this.model_type=='exposure'){
                    this.is_edit_hedge=true;
                }
 
 
                this.is_edit = true;
                this.onEditLoad(data['results']);
            }       
            
            
            
        });


          
        /*

        this._apiService.getModel(this.endpoint,this.model_id,false)
            .subscribe(api_object => {
                if (api_object.hasOwnProperty('status') && api_object.status=="new"){
                    this.crud='new';
                } else {
                    this.crud='edit';
                    this.onEditLoad(api_object);
                }

          },
          null,() => { this.isLoading = false; });
          */
          //$('[data-toggle="tooltip"]').tooltip();
         
        
    }

    models_id_hedging: any[] = [];
    getSelectedHedgeModels(){

        //Get the hedging models selected
        this._apiService.getModel('/test/model',this.model_id,true)
            .subscribe(api_object => {
                this.models_id_hedging = api_object['models_id_exposure'];
                this.getHedgeModelData(api_object['models_id_exposure']);
          },
          null,() => { this.isLoading = false; });


    }


    currencies_temp: any[] = [];
    commodities_temp: any[] = [];
    getHedgeModelData(model_array){
         let currencies_temp_local: any[] = [];
         let commodities_temp_local: any[] = [];
         for (var _i = 0; _i < model_array.length; _i++) {
                    
                    this._apiService.getModel('/test/model',model_array[_i],true)
                        .subscribe(api_object => {
                            this.currencies_temp = currencies_temp_local.concat(api_object['data']["hedgeexpfx"]['currencies']);
                            this.commodities_temp = commodities_temp_local.concat(api_object['data']["hedgeexpcom"]['commodities']);
                            //this.currencies_temp = currencies_temp_local.concat(api_object['data']["marketriskfactors"]['currencies']);
                            //this.commodities_temp = commodities_temp_local.concat(api_object['data']["marketriskfactors"]['commodities']);
                            //this.analysis_horizon_array_test.push(analysis_horizon_temp);
                    },
                    null,() => { 
                        this.isLoading = false;
                        let api_object_temp = {}
                        api_object_temp['currencies']=_.uniq(this.currencies_temp);
                        api_object_temp['commodities']= _.uniq(this.commodities_temp);
                        this.onEditLoad(api_object_temp);
                    });
          }
          
          
    }


    onEditLoad(api_object:any){

        let currency_name_object_hash = {}
        let currency_name_list = []
        
        currency_name_list.sort()
      

        let temp_selected_currency_array: any[] = [];
        let temp_selected_commodity_array: any[] = [];
        for (var _i = 0; _i < api_object.currencies.length; _i++) {
            for (var _j = 0; _j < this.currency_array.length; _j++) {
                if (api_object.currencies[_i]==this.currency_array[_j].displayname){
                    this.selected_currency_array.push(this.currency_array[_j]);
                    this.prior_currencies.push(this.currency_array[_j].displayname);
                    
                }
            }
        }


       




 

        /*
        COMMODITIES
        */


        for (var _i = 0; _i < api_object.commodities.length; _i++) {
            for (var _j = 0; _j < this.commodities_array.length; _j++) {
                if (api_object.commodities[_i]==this.commodities_array[_j].displayname){
                    this.selected_commodity_array.push(this.commodities_array[_j]);
                    this.prior_commodities.push(this.commodities_array[_j].displayname);
                }
            }
            
        }


        
        for (var _i = 0; _i < api_object.currencies.length; _i++) {
            var this_asset = this.currency_array.find(x => x.displayname === api_object.currencies[_i]);
            var index = this.currency_array.indexOf(this_asset, 0);
            this.currency_array.splice(index, 1);
        }
        
        for (var _i = 0; _i < api_object.commodities.length; _i++) {
            var this_asset = this.commodities_array.find(x => x.displayname === api_object.commodities[_i]);
            var index = this.commodities_array.indexOf(this_asset, 0);
            this.commodities_array.splice(index, 1);
        }

      
         /*
        CREATE SORTED LISTS
         */
        this.sorted_currency_array = []
        this.sorted_commodities_array = []
        for (var _i = 0; _i < this.currency_array.length; _i++) {
            this.sorted_currency_array.push(this.currency_array[_i].displayname);
        }
        for (var _i = 0; _i < this.commodities_array.length; _i++) {
            this.sorted_commodities_array.push(this.commodities_array[_i].displayname);
        }
        this.sorted_currency_array.sort()
        this.sorted_commodities_array.sort()
        


        
        //this.selected_currency_array = temp_selected_currency_array;
        //this.selected_commodity_array = temp_selected_commodity_array;
        if (this.currency_array.length>0 || this.commodities_array.length>0){
            this.isRun=true;
        }
        this.test="true";

        if (this.selected_currency_array.length>0){  
            this.is_comm=false;
            //this.forward_title='Currency: Mean Reversion'
            this.forward_title='Currency: Volatilities'
            //this.forward_link = 'models/cemr/' +  this.model_id
            this.forward_link = 'models/cevol/' +  this.model_id
            
        } else {
            this.forward_title='Commodity: Mean Reversion'
            this.forward_title = 'Commodity: Volatilities'
            //this.forward_link = 'models/commr/' +  this.model_id
            this.forward_link = 'models/comvol/' +  this.model_id
        }

    }
    
  onChangeAsset(event:any,type:any){
        if (type=='fx'){
             this.selectedCurrency=event;
        }

        if (type=='comm'){
             this.selectedCommodity=event;
        }
    }

    deleteAsset(event:any,type:any){
        if (type=='fx'){
            var this_asset = this.selected_currency_array.find(x => x.displayname === event);
            var index = this.selected_currency_array.indexOf(this_asset, 0);
            this.selected_currency_array.splice(index, 1);
            this.currency_array.push(this_asset);
        }

        if (type=='comm'){
            var this_asset = this.selected_commodity_array.find(x => x.displayname === event);
            var index = this.selected_commodity_array.indexOf(this_asset, 0);
            this.selected_commodity_array.splice(index, 1);
            this. commodities_array.push(this_asset);
        }

         if (this.currency_array.length>0 && this.commodities_array.length>0){
            this.isRun=false;
        }

        
        this.sorted_currency_array = []
        this.sorted_commodities_array = []
        for (var _i = 0; _i < this.currency_array.length; _i++) {
            this.sorted_currency_array.push(this.currency_array[_i].displayname);
        }
        for (var _i = 0; _i < this.commodities_array.length; _i++) {
            this.sorted_commodities_array.push(this.commodities_array[_i].displayname);
        }
        this.sorted_currency_array.sort()
        this.sorted_commodities_array.sort()
       


    }

    addAssetMd(type:any){


    }

    
    addAsset(type:any){

        if (type=='fx'){
            var this_asset = this.currency_array.find(x => x.displayname === this.selectedCurrency);
            //this.currencyformArray.push(new FormControl(this.selectedCurrency));
            var index = this.currency_array.indexOf(this_asset, 0);
            this.currency_array.splice(index, 1);
            this.selected_currency_array.push(this_asset);
            this.selectedCurrency = 'Select Currency';
        }

        if (type=='comm'){
            var this_asset = this.commodities_array.find(x => x.displayname === this.selectedCommodity);
            //this.commodity_form_array.push(new FormControl(this.selectedCommodity));
            var index = this.commodities_array.indexOf(this_asset, 0);
            this.commodities_array.splice(index, 1);
            this.selected_commodity_array.push(this_asset);
            this.selectedCommodity = 'Select Commodity';
            this.commodity_form_array.push(new FormControl(this.selectedCommodity,Validators.required));
        }

        if (this.currency_array.length>0 || this.commodities_array.length>0){
            this.isRun=true;
        }

        
        this.sorted_currency_array = []
        this.sorted_commodities_array = []
        for (var _i = 0; _i < this.currency_array.length; _i++) {
            this.sorted_currency_array.push(this.currency_array[_i].displayname);
        }
        for (var _i = 0; _i < this.commodities_array.length; _i++) {
            this.sorted_commodities_array.push(this.commodities_array[_i].displayname);
        }
        this.sorted_currency_array.sort()
        this.sorted_commodities_array.sort()
        

    


}


   back(){
       if (this.model_type=='risk'){
        this._router.navigateByUrl('models/basic/' +  this.model_id +';model=risk');
       } else {

           this._router.navigateByUrl('models/edit/' +  this.model_id +';model=exposure');
       }
   }
 
    onSubmit() {
        let market_risk_factors = new MarketRiskFactors(); 
        var result:any;
        for (var _i = 0; _i < this.selected_currency_array.length; _i++) {
             market_risk_factors.currencies.push(this.selected_currency_array[_i].displayname);
        } 
        for (var _i = 0; _i < this.selected_commodity_array.length; _i++) {
             market_risk_factors.commodities.push(this.selected_commodity_array[_i].displayname);
        } 

        
       

        if (this.crud=='new'){
            
            result = this._apiService.addModelId(this.endpoint,this.model_id,market_risk_factors).subscribe(x => {
                    if (this.model_type=='risk'){
                       
                        this._router.navigateByUrl(this.forward_link);
                    } else {
                        this._router.navigateByUrl('models/hedgeinitial/' +  this.model_id);
                    }
            });
        }
 
        if (this.crud=='edit'){
            market_risk_factors.prior_commodities = this.prior_commodities;
            market_risk_factors.prior_currencies = this.prior_currencies;
            result = this._apiService.updateModelId(this.endpoint,this.model_id,market_risk_factors).subscribe(x => {
                    if (this.model_type=='risk'){
                        this._router.navigateByUrl(this.forward_link);
                    } else {
                        this._router.navigateByUrl('models/hedgeinitial/' +  this.model_id);  
                    }
            });
        }
    }
} 


