import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-asset-menu',
  templateUrl: './asset-menu.component.html',
  styleUrls: ['./asset-menu.component.css'] 
})
export class AssetMenuComponent implements OnInit {

  @Input() mr_vol_exp:string;
  @Input() is_vol:boolean;
  @Input() is_user:boolean;
  @Input() asset:string; 


 
  @Output("value") colorValueEvent: EventEmitter<object> = new EventEmitter();

  is_exp=false;
  

  constructor() { 
   
  }

  ngOnInit() {

    //this.is_user=false;

    if (this.mr_vol_exp=="exp"){
      this.is_exp=true;
    }

  }


  onClick(event){
   
    this.colorValueEvent.emit({'event':event,'asset_idx':this.asset});
  }


}
