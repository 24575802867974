import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class PollingService {

  constructor() { }

  private pollingAnnouncedSource = new Subject<object>();
  pollingAnnounced$ = this.pollingAnnouncedSource.asObservable();
  announcePolling(polling: object) {
    this.pollingAnnouncedSource.next(polling);
  }

}
