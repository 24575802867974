import {Component, OnInit, Output,EventEmitter,Provider, forwardRef, AfterViewInit} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { LoggedinService } from '../loggedin.service';
import { ModelValidators } from '../validators/validators';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  dudder="primary"

  myForm: FormGroup; 

  is_changed=false;
  hide = true;
  constructor(private loggedinService: LoggedinService,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private _router: Router,
              private _apiService: ApiService) { 

                this.myForm = fb.group({
                passwordSame: new FormGroup({
                      password: new FormControl('', [Validators.required,ModelValidators.passwordControls]),  //Validators.minLength(10)
                      //password_repeat: new FormControl('', [Validators.required]),
                      },this.validatePassword) 
               
                    })

               
  }

  dude(){
   
  }

  is_password = false;
  passwordChange(){

    var regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,100})/;
    var valid = regEx.test(this.myForm.controls['passwordSame']['controls']['password'].value);

    if (valid){
      this.is_password = true;
      this.dudder="accent"
    } else {

      this.is_password = false;
      this.dudder="primary"

    }
    
  }

  validatePassword(control: FormGroup) {

    var valid:any;

    /*
    var password = control.controls['password'].value;
    var password_repeat = control.controls['password_repeat'].value;
    
    if (password && password_repeat){
       
        if (password==password_repeat){
            valid=true;
        } else {
            valid=null;
        }
    } else {
        valid=null;
    }
    */

    valid=true
    return valid ? null : { validatePassword: true };

  } //end validatePassword

  ngOnInit() {
  }

  onSubmit(){

    var result:any;

    var data = {}
    data['password']= this.myForm.controls['passwordSame']['controls']['password'].value
    let endpoint = '/test/changepassword'
    result = this._apiService.addModel(endpoint,data).subscribe(x => {
         this.is_changed=true
        //this._router.navigateByUrl('models/marketriskfactors/' +  this.model_id + ';model=risk');
    });


  }

}
  