import {Component, OnInit, Output,EventEmitter,Provider, forwardRef, AfterViewInit} from '@angular/core';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { ModelValidators } from '../validators/validators';
import { CURRENCIES } from '../object_lists/assets_object';
import { FormComponent } from '../can_deactivate/prevent_unsaved_changes_gaurd.service';
import { LoggedinService } from '../loggedin.service';

const FLOAT_REGEX = /^-?\d*(\.\d+)?$/;

@Component({
    templateUrl: './mesa-ce-mr.component.html',
    styleUrls: ['./mesa-ce-mr.component.css','../app.component.css' ], 
    providers: [RouterLink, FormBuilder, ApiService ]
})  
export class MesaCeMrComponent implements OnInit, AfterViewInit, FormComponent {

    endpoint='/test/model/cemr';
    use_hash = false;
    myForm: FormGroup;
    myFormArrayStored = new FormArray([]);
    myFormArrayUse = new FormArray([]);
    model_id: string;
    isLoading = false;
    crud:string; 

    raw_data: any[] = [];

    model_name:string;
    
    constructor( 
        private loggedinService: LoggedinService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private _apiService: ApiService) {

        this.model_name = localStorage.getItem('model_name');

        this.loggedinService.announceLoggedin(true);

        this.myForm = fb.group({
             my_form_array_stored: this.myFormArrayStored,
             my_form_array_use: this.myFormArrayUse,
         })
         
    }

    api_object:any;
     
    currency_list: any[] = [];
    p_values: any[] = [];
    beta_list: any[] = [];
    temp:any;
    ngOnInit() {
        this.route.params.map(params => params['id'])
            .subscribe(id => { this.model_id=id });

        this.route.data.forEach((data) => {

                /*
                  u'fx_estresult_mr_speed': [{u'_row': u'GBP_USD',
                  u'betas': 0.5845,
                  u'pval': 0.53}],
                */ 
      
                if (data['results'].hasOwnProperty('cemr')===false){
                    this.crud='new';
                } else {
                    this.crud='edit';
                }
 
                if (data['results'].hasOwnProperty('cemr')){ //edit
                    this.raw_data = data['results']['cemr'];
                    for (var _i = 0; _i < this.raw_data.length; _i++) {
                        this.myFormArrayUse.push(new FormControl(data['results']['cemr'][_i]['use'],[]));
                        this.myFormArrayStored.push(new FormControl(data['results']['cemr'][_i]['stored_values'],[Validators.required,Validators.pattern(FLOAT_REGEX)]));
                    }   
                } else { //new
                    /*
                    this.raw_data = data['results']["populate_risk"]['fx_estresult_mr_speed'];
                    for (var _i = 0; _i < data['results']["populate_risk"]['fx_estresult_mr_speed'].length; _i++) {
                        this.p_values.push(data['results']["populate_risk"]['fx_estresult_mr_speed'][_i]['pval']);
                        this.beta_list.push(data['results']["populate_risk"]['fx_estresult_mr_speed'][_i]['betas'])
                        
                        
                        if (data['results']["populate_risk"]['fx_estresult_mr_speed'][_i]['pval']<.05 && data['results']["populate_risk"]['fx_estresult_mr_speed'][_i]['pval']> -.05){
                                this.myFormArrayUse.push(new FormControl(true,[]));
                                this.myFormArrayStored.push(new FormControl(data['results']["populate_risk"]['fx_estresult_mr_speed'][_i]['betas'],[Validators.required,Validators.pattern(FLOAT_REGEX)]));
                        } else{
                                this.myFormArrayUse.push(new FormControl(false,[]));
                                this.myFormArrayStored.push(new FormControl(0,[Validators.required]));
                        }
                    }\*/
                }   
             
                //this.onEditLoad(data['results']);

        });

         
    }
    ngAfterViewInit() {
        // Your jQuery code goes here
        //$('[data-toggle="tooltip"]').tooltip();
    }


    back(){
        this._router.navigateByUrl('models/marketriskfactors/'+  this.model_id + ';model=risk');
    }
 
    onSubmit() {
        var result:any;

        for (var _i = 0; _i < this.raw_data.length; _i++) {
            this.raw_data[_i]['use']=this.myForm.controls['my_form_array_use'].value[_i];
            this.raw_data[_i]['stored_values']=this.myForm.controls['my_form_array_stored'].value[_i]
        }

        let data = this.raw_data;
        
        if (this.crud=='new'){
            result = this._apiService.addModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    this._router.navigateByUrl('models/cevol/' +  this.model_id );
                });
        }

        if (this.crud=='edit'){
            result = this._apiService.updateModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    this._router.navigateByUrl('models/cevol/' +  this.model_id);
            });
        }
       
        
        
       

    }
} 
