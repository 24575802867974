import { Component, OnInit, Renderer, OnDestroy,Inject,ElementRef, Input, ViewChild} from '@angular/core';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { Model } from './model';
import { LoggedinService } from '../loggedin.service';
import { BusyService } from '../busy.service';
import { environment } from '../../environments/environment';
import {MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA} from '@angular/material';
import { PollingService } from '../polling.service';
import { ModelRefreshService } from '../model-refresh.service';
import {HttpClientModule} from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import * as FileSaver from 'file-saver'; 
import {RequestOptions, Request, RequestMethod,ResponseContentType} from '@angular/http';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.css','model-form.component.css','../app.component.css'],
  providers: [ ApiService ]
})
export class ModelsComponent implements OnInit, OnDestroy {

    isLoading = false;
    models: any[];
    models_risk: any[] = [];
    models_hedging: any[] = [];
    models_exposures: any[] = [];
    models_hedging_list: any[] = [];
    modelrefresh_sub:any;
    endpoint='/test/model';
    poll:any;
    sub:any;
    params:any; 
    dev=false;
    user_group_object = {}
    busy=true;

    dude_data = [
      {
        name: "Test 1",
        age: 13,
        average: 8.2,
        approved: true,
        description: "using 'Content here, content here' "
      },
      {
        name: 'Test 2',
        age: 11,
        average: 8.2,
        approved: true,
        description: "using 'Content here, content here' "
      },
      {
        name: 'Test 4',
        age: 10,
        average: 8.2,
        approved: true,
        description: "using 'Content here, content here' "
      },
    ];
    constructor(private loggedinService: LoggedinService,
                private busyService: BusyService,
                private pollingService: PollingService,
                private modelrefreshService: ModelRefreshService,
                private route: ActivatedRoute,
                public router: Router,
                public http: Http,
                private _apiService: ApiService,
                private renderer: Renderer,
                public dialog: MatDialog,
                public snackBar: MatSnackBar) {

                this.busyService.busyAnnounced$.subscribe(
                                busy => {
                                  this.busy = busy;
                                  if (this.busy==true){
                                    
                                  } else{
                                    //this.busyService.announceBusy(false);
                                  }
                });


              if (environment.environment!='production'){
                this.dev=true;
                this.user_group_object = JSON.parse(localStorage.getItem('user_group_object'));
               
              }

                this.loggedinService.announceLoggedin(true);

                //this.wowDude()

                //new Angular2Csv(this.dude_data, 'My Report');
         
                //this.pollingService.announcePolling({'model_id':'mid_6e7bb7e570194ea29f92766215f9f49c','is_output':false}); 
                
    }

    
/*
    wowDude(){

      var token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwYXlsb2FkIjoie1wicm9sZVwiOiBcInN1cGVydXNlclwiLCBcImVtYWlsXCI6IFwiZGF2aWRtb250Z29tZXJ5QGdtYWlsLmNvbVwiLCBcInVzZXJfaWRcIjogXCJ1aWQtYTNkOTZkYzgtYTE2OS00ODIzLTg3MjYtYmQ4MmFkZTBhMTM2XCIsIFwibGFzdF9uYW1lXCI6IFwiTW9udGdvbWVyeVwiLCBcImFjY291bnRfaWRcIjogXCJhY2NvdW50LWdwc1wiLCBcImZpcnN0X25hbWVcIjogXCJEYXZpZFwiLCBcImNsaWVudF9zbHVnXCI6IFwiZ3BzXCJ9IiwiZXhwIjoxNTI1ODE5OTUyLCJpc3MiOiJtdG06YXBpIiwiYXVkIjoibXRtOmNsaWVudCIsImlhdCI6MTUyNTgxNjM1Mn0.LrTArGCVFaP_y-2exPdZcDEm6EIBv4dVvDXyhGLpqi8"
      let headers = new Headers({ 
        'Content-Type': 'application/json', 
        'Authorization': 'bearer ' + token
       
       });

       this.http.get('https://pi8hks9sd4.execute-api.us-east-1.amazonaws.com/local/user/list',{ headers: headers } )
          .subscribe(service_data => {
            console.log(service_data)
          },
          error => {
            console.log(error)
          }
        );
    }
 */   

   


    dude(){

      this._apiService.getModels(this.endpoint)
                      .subscribe(models => {
                          this.models = models
                          this.models_exposures = []
                          this.models_hedging = []
                          this.models_risk = [];
                          this.models_hedging_list = [];

                          for (var _i = 0; _i < models.length; _i++) {

                            if (models[_i]['data']['type']=='risk'){
                              this.models_risk.push(models[_i]);
                              if (models[_i]['data']['data'].hasOwnProperty('corcross')){
                                  //models[_i]['data']['status']='complete';
                              }
                            }
 
                            if (models[_i]['data']['type']=='hedging'){
                              if (models[_i]['data']['data'].hasOwnProperty('hedgetailrisk')){
                                  models[_i]['data']['status']='complete';
                              }
                              this.models_hedging.push(models[_i]);
                            }
 

                            if (models[_i]['data']['type']=='exposure'){
                              if (models[_i]['data']['data'].hasOwnProperty('hedgeexpcom')){
                                  models[_i]['data']['status']='complete';
                              }
                              this.models_exposures.push(models[_i]);
                            }

                            if (models[_i]['data']['type']=='hedging_list'){
                              if (models[_i]['data']['data'].hasOwnProperty('dude')){
                                  models[_i]['data']['status']='complete';
                              }
                              this.models_hedging_list.push(models[_i]);
                            }
                  
                          }
                      }
                      ,null,() => { this.isLoading = false;
                        this.busyService.announceBusy(false); 
                        
                      });


    }

           
    
     ngOnInit(){

            //this.busyService.announceBusy(false); 
            this.modelrefresh_sub = this.modelrefreshService.modelrefreshAnnounced$.subscribe(
              modelrefresh => {
                this.dude()
              });

       

       this.sub = this.route.params.subscribe(params => {
            this.params=params;
           
        });
       

      this.dude()
      this.busyService.announceBusy(false); 


      /*
        execute after every model run
        for output will have to get the 
      */
      
      

		
      /*
      this.poll = this._apiService.getPollModles(this.endpoint)
        .subscribe(models => {
          this.models = models,
          this.isDone(this.models)
        },null,() => { this.isLoading = false; });
      */

    }
    ngOnDestroy(){

      if(this.modelrefresh_sub){
        this.modelrefresh_sub.unsubscribe();
      }

    } //end OnDestroy


    isHedgingModel(){

      return false;
    }

    deleteModel(model:any){

        if (confirm("Are you sure you want to delete " + model.data.name + "?")) {

          if (model.data.type=='risk'){
            var index = this.models_risk.indexOf(model)
            // Here, with the splice method, we remove 1 object
            // at the given index.
            this.models_risk.splice(index, 1);
          }

          if (model.data.type=='hedging'){
            var index = this.models_hedging.indexOf(model)
            // Here, with the splice method, we remove 1 object
            // at the given index.
            this.models_hedging.splice(index, 1);
          }

          if (model.data.type=='exposure'){
            var index = this.models_exposures.indexOf(model)
            // Here, with the splice method, we remove 1 object
            // at the given index.
            this.models_exposures.splice(index, 1);
          }

          if (model.data.type=='hedging_list'){
            var index = this.models_hedging.indexOf(model)
            // Here, with the splice method, we remove 1 object
            // at the given index.
            this.models_hedging_list.splice(index, 1);
          }



          this._apiService.deleteModel(this.endpoint,model.model_id)
            .subscribe(null, 
              err => {
                alert("Could not delete the user.");
                            // Revert the view back to its original state
                            // by putting the user object at the index
                            // it used to be.
                this.models.splice(index, 0, model);
              });
        }
		
	}

  onClickEdit(model){
     this.router.navigateByUrl('/dashboard(content:models/edit/' +  model.model_id + ';model=risk)');

  }

  

    isDone(models:any){
        var count = 0;
        var status: string;
        for (var _i = 0; _i < models.length; _i++) {
          status = models[_i].data.status;
          if (status=='running'){
            count++;
          }
        }
        if (count==0){
          this.poll.unsubscribe();
         
        }

	   } // end isDone

     download(fileName,mid){
        //endpoint='/test/download/' + mid;
        let fn_data = {};
        for (var _i = 0; _i < this.models.length; _i++) {
          if (mid==this.models[_i]['model_id']){
            fn_data = this.models[_i];
          }
         
        }
        //let test = {"a":"b"}

        let temp = JSON.stringify(fn_data) 
        let fileName_t = fileName + '.json'
        this.buildDownloader(fileName_t,temp);
      }

     private getDocumentBody(): any {
      return document.body;
    }

    filetype: string;
    openDownloadDialog(model,model_id): void {

      let dialogRef = this.dialog.open(DialogDownload,{
        data: { filetype: this.filetype }
      });
      //dialogRef.componentInstance.model_id = model_id;  //    http://stackoverflow.com/questions/34205593/working-example-of-angular-2-0-material-mddialog-with-angular-2-0/40185852#40185852
      //dialogRef.componentInstance.model = model;
      dialogRef.afterClosed().subscribe(result => {
        if (result['filetype']=='json'){
          this.download(model.data.name,model_id)
        }
        if (result['filetype']=='xlsx'){

          var token = localStorage.getItem('token')
            
          let headers = new Headers({ 
            'Content-Type': 'application/json', 
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Access-Control-Allow-Methods': 'GET',
            'Authorization': 'bearer ' + token
           });
           let options = new RequestOptions({ headers: headers });
           options.responseType = ResponseContentType.Blob;

          let ran = Math.floor(Math.random() * 10000) + 1;
          this.http.get(environment.API_URL + '/test/model/expsoure/excel/' + model.model_id + '?cache=' + ran.toString(),options)
          .subscribe(service_data => {
            let fileBlob = service_data.blob();
            let blob = new Blob([fileBlob], { 
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // must match the Accept type
           });
           let filename = model.data.name + '.xlsx';
           FileSaver.saveAs(blob, filename);
          },
          error => {
          
           }
        );
        }
      }); //dialogRef.afterClosed()

  }
  
    openUploadDialog(model,model_id){
       var mid = null;
       if (model_id=='fresh'){
          mid = 'fresh'
       } else {
          mid = model.model_id
       }
       
        let dialogRef = this.dialog.open(DialogUpload);
        dialogRef.componentInstance.model_id = mid;  //    http://stackoverflow.com/questions/34205593/working-example-of-angular-2-0-material-mddialog-with-angular-2-0/40185852#40185852
        //dialogRef.componentInstance.model = model;
        dialogRef.afterClosed().subscribe(result => {

          if (result){
              if (result.status==false){
               
                localStorage.setItem('excel_msg', result.msg);
                this.openUploadErrorSnackBar(result.msg)
              } 
              if (result.status==true){
                this.dude()
              }
            } //end if result
        }); 

    }//end openUploadDialog

    openUploadErrorSnackBar(msg) {
      this.snackBar.openFromComponent(UploadErrorSnackBarComponent, {
        duration: 4000,
      });
    }
 
    templateDownload(){

          var token = localStorage.getItem('token')
             
          let headers = new Headers({ 
            'Content-Type': 'application/json', 
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Access-Control-Allow-Methods': 'GET',
            'Authorization': 'bearer ' + token
           });
           let options = new RequestOptions({ headers: headers });
           options.responseType = ResponseContentType.Blob;
           let ran = Math.floor(Math.random() * 10000) + 1;
           this.http.get(environment.API_URL + '/test/model/expsoure/excel/template' + '?cache=' + ran.toString(),options)
              .subscribe(service_data => {
                let fileBlob = service_data.blob();
                let blob = new Blob([fileBlob], { 
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // must match the Accept type
              });
              let filename = 'template' + '.xlsx';
              FileSaver.saveAs(blob, filename);
              },
              error => {
             
              }
            );


      
    }

    templateUpload(){

    }

     private buildDownloader(fileName,data) {

        
        let anchor = this.renderer.createElement(this.getDocumentBody(), 'a');
        this.renderer.setElementStyle(anchor, 'visibility', 'hidden');
        this.renderer.setElementAttribute(anchor, 'href', 'data:application/json;charset=utf-8,' + encodeURIComponent(data));
        this.renderer.setElementAttribute(anchor, 'target', '_blank');
        this.renderer.setElementAttribute(anchor, 'download', fileName);

        setTimeout(() => {
          this.renderer.invokeElementMethod(anchor, 'click');
          this.renderer.invokeElementMethod(anchor, 'remove');
        }, 5);

      }

        openDialog(model,model_id){
            let dialogRef = this.dialog.open(DialogClone);
            dialogRef.componentInstance.model_id = model_id;  //    http://stackoverflow.com/questions/34205593/working-example-of-angular-2-0-material-mddialog-with-angular-2-0/40185852#40185852
            dialogRef.componentInstance.model = model;
            dialogRef.afterClosed().subscribe(result => {
              //this.selectedOption = result;
              this.dude();
            }); 

            

          }// end dialog


          openDialogShare(type,model_id){
                //const config = new MdDialogConfig();
                //config.disableClose=false;
                //let dialogRef = this.dialog.open(DialogShareModelId,config);
                let dialogRef = this.dialog.open(DialogShareModelId);
                
                dialogRef.componentInstance.model_id = model_id;  //    http://stackoverflow.com/questions/34205593/working-example-of-angular-2-0-material-mddialog-with-angular-2-0/40185852#40185852
                dialogRef.componentInstance.type = type;
                dialogRef.componentInstance.user_group_object = this.user_group_object 
                
                dialogRef.afterClosed().subscribe(result => {
                    //this.selectedOption = result;
                    if (result=='cancel'){
                        //this.deleteAsset(ce,idx)
                    } else {
                        //var data = {'model_id':model_id,'type':type};
                        //this._apiService.addModelId(this.endpoint,model_id,data).subscribe(x => {});
                    } 
                }); 


          } //openDialogShare







} //main end

/*
DOWNLOAD
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
*/

@Component({
  selector: 'download_dialog',
  templateUrl: './download_dialog.html',
})
export class DialogDownload {
  filetype='xlsx'
  constructor(public dialogRef: MatDialogRef<DialogDownload>,
              @Inject(MAT_DIALOG_DATA) public data: any) {} 

  download(){
    this.data.filetype=this.filetype
    this.dialogRef.close(this.data); 
  }
} //end DialogDownload

/*
UPLOAD
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
*/

@Component({
  selector: 'upload_dialog',
  templateUrl: './upload_dialog.html',
})
export class DialogUpload implements OnDestroy {
  

  selectedValue:string;
  myForm: FormGroup;
  model_id:string;
  model:any;
  data:any;
  is_file=true;
  filename:string;

  constructor(public dialogRef: MatDialogRef<DialogUpload>,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              public router: Router,
              private renderer: Renderer,
              public http: Http,
              private _apiService: ApiService) {

 

  } //end constructor

  ngOnDestroy() {

  }

  onSubmit(){
    //'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
     
    var token = localStorage.getItem('token')

    let contentHeaders = new Headers();
    contentHeaders.append('Accept', 'application/json');
    contentHeaders.append('Content-Type', 'application/json');
    contentHeaders.append('Authorization', 'bearer ' + token);


    //{ headers: contentHeaders }
    /*
    let headers = new Headers({ 
      'Content-Type': 'application/json', 
      'Accept': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'POST',
      'Authorization': 'bearer ' + token
      });
      let options = new RequestOptions({ headers: headers }); //replace { headers: contentHeaders } with options
      options.responseType = ResponseContentType.Blob;
    */
  
    let ran = Math.floor(Math.random() * 10000) + 1;
    
    this.http.post(environment.API_URL + '/test/model/expsoure/excel/' + this.model_id + '?cache=' + ran.toString(),this.data,{ headers: contentHeaders })
    .subscribe(service_data => {
        let status = service_data.json().status
        let msg = service_data.json().msg
       
        
        this.dialogRef.close(service_data.json())
      });

  } //end onSubmit



  fileChange(event){
    //https://stackoverflow.com/questions/40214772/file-upload-in-angular
    //https://nehalist.io/uploading-files-in-angular2/
    //data:application/json;base64,eyesrzyRDW

    let reader = new FileReader();
    let file = event.target.files[0];
    this.filename = file.name;
    reader.readAsDataURL(file);
    reader.onload = () => {
    this.data = reader.result.toString().split(',')[1];
    this.is_file=false;

    }


  }// end fileChange


}


/*
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
*/


@Component({
  selector: 'clone_dialog',
  templateUrl: './clone_dialog.html',
})
export class DialogClone implements OnDestroy {
  

  selectedValue:string;
  myForm: FormGroup;
  model_id:string;
  model:any;

  constructor(public dialogRef: MatDialogRef<DialogClone>,
              private fb: FormBuilder,
              private _apiService: ApiService,
              private busyService: BusyService) {

               this.myForm = this.fb.group({
                  name: ['',[Validators.required]],
                }) //end form

  } //end constructor

  ngOnDestroy() {

  }



   onSubmit(){

      var result:any;
      this.model.data.name = this.myForm.controls['name'].value
      let endpoint='/test/model/clone';
      result = this._apiService.addModel(endpoint,this.model).subscribe(x => {
                            //let model_id = x.model_id; 
                            this.dialogRef.close();         
      }); //end result

      

      


  } //end onSubmit


}

/*
SHARE 
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
*/


@Component({
  selector: 'share_model_dialog',
  templateUrl: './share_model_dialog.html',
})
export class DialogShareModelId implements OnInit{

    type:any;
    model_id:string;
    user_group_object = {}
    selectedAccount:any;
    account_list = []
    
    args = {}

  
  constructor(public dialogRef: MatDialogRef<DialogShareModelId>,
              private _apiService: ApiService) {


  } //end constructor

  ngOnInit(){
 

      for (var key in this.user_group_object){
        let temp = {'user_id':key,'email':this.user_group_object[key]['email']}
        this.account_list.push(temp)
      }

     


  }

  is_share = false
  selectChange(){
    this.is_share=true
  }


   shareModelIds(){


        let data = {'model_id':this.model_id,
                    'type':this.type,
                     'user_id':this.selectedAccount }
        var result:any;
        let endpoint='/test/model/sharemodels';

        result = this._apiService.addModel(endpoint,data).subscribe(x => {
            //this.dialogRef.close('cancel');
            this.dialogRef.close()
        }); //end result

        
    }

   onSubmit(){


   } //end onSubmit


}




/*
SHARE 
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
*/

@Component({
  selector: 'upload-error-sb',
  template: `{{msg}}`,
  styles: [],
})
export class UploadErrorSnackBarComponent implements OnInit {

  msg:string;
  constructor(){

  }

  ngOnInit(){

    
    this.msg = localStorage.getItem('excel_msg');
    

  }

}