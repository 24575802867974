
import {Component, OnInit, Output,EventEmitter,Provider, forwardRef, AfterViewInit} from '@angular/core';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { ModelValidators } from '../validators/validators';
import { CURRENCIES } from '../object_lists/assets_object';
import { LoggedinService } from '../loggedin.service';
import { FormComponent } from '../can_deactivate/prevent_unsaved_changes_gaurd.service';
import { PollingService } from '../polling.service';
import * as _ from "lodash";

 
@Component({
    selector: 'app-mesa-cor-cross',
  templateUrl: './mesa-cor-cross.component.html',
  styleUrls: ['./mesa-cor-cross.component.css'],
    providers: [RouterLink, FormBuilder, ApiService ]
})  
export class MesaCorCrossComponent implements OnInit, AfterViewInit, FormComponent {

    endpoint='/test/model/corcross';
    use_hash = false;
    myForm: FormGroup;
    myFormArrayCorr = new FormArray([]);
    model_id: string;
    isLoading = false;
    crud:string;

    model_name:string;

    fx_list=[]
    comm_list=[]

    constructor(
        private loggedinService: LoggedinService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private pollingService: PollingService,
        private _apiService: ApiService) {

        this.model_name = localStorage.getItem('model_name');

        this.loggedinService.announceLoggedin(true);


        this.myForm = fb.group({
             estimation_expo: ['ew'],
             my_form_array_corr: this.myFormArrayCorr
         })
         
    }

    api_object:any;
    
    commodity_list: any[] = [];
    currency_list: any[] = [];
    corr_array: any[] = [];
    temp:any;
    

    fx_estresult_corr_exp_last: any[] = [];
    fx_estresult_corr_hist_last: any[] = [];


    edit_asset_type_hash = {}
    orig_asset_type_hash:any;
    edit_cross_estresult_corr_exp: any[] = [];
    edit_cross_estresult_corr_hist: any[] = [];

     asset_list_fx: any[] = [];
     asset_list_comm: any[] = [];

    backward_title:string;
    ngOnInit() {
        this.route.params.map(params => params['id'])
            .subscribe(id => { this.model_id=id });

        this.currency_list = [];
        this.commodity_list = [];
        this.route.data.forEach((data) => {
 
           if (data['results'].hasOwnProperty('corcross')===false){
                    this.crud='new';
                } else {
                    this.crud='edit';
                }


                this.fx_list = data['results']['marketriskfactors']['currencies']
                this.comm_list = data['results']['marketriskfactors']['commodities']
         

                this.currency_list = data['results']['corcross']['asset_list_fx']
                this.commodity_list = data['results']['corcross']['asset_list_comm']

                this.asset_list_fx = data['results']['corcross']['asset_list_fx'];
                this.asset_list_comm = data['results']['corcross']['asset_list_comm'];


                this.commodity_list = _.sortBy(this.commodity_list);
                this.currency_list = _.sortBy(this.currency_list);

                if (data['results'].hasOwnProperty('cemr')===true){
                    this.backward_title='Correlation: Currencies'
                } else {
                    this.backward_title='Commodity: Expectations'
                } 
                
                
           
                

 
               if (this.currency_list.length==1 && this.commodity_list.length==1){
                    //this.fx_estresult_corr_exp_last = [data['results']["populate_risk"]["cross_estresult_corr_exp"]];
                    //this.fx_estresult_corr_hist_last = [data['results']["populate_risk"]["cross_estresult_corr_hist"]];

               } else {
                     //this.fx_estresult_corr_exp_last = data['results']["populate_risk"]["cross_estresult_corr_exp"];
                     //this.fx_estresult_corr_hist_last = data['results']["populate_risk"]["cross_estresult_corr_hist"];
               }
 
                //let currency_count = this.currency_list.length;
                let estimation_expo = data['results']['basic']['estimation_expo'];
                //let cor_len = currency_count*currency_count;



                if (data['results'].hasOwnProperty('corcross')){ //Edit

                   this.edit_asset_type_hash = data['results']['corcross']['edit_asset_type_hash'];
                   this.orig_asset_type_hash = data['results']['corcross']['orig_asset_type_hash'];


                    let estimation_expo = data['results']['corcross']['estimation_expo'];
                    this.myForm.controls['estimation_expo'].setValue(estimation_expo);
                    let temp = data['results']['corcross']['correlations'];
                    for (var _i = 0; _i < temp.length; _i++) {
                           let v = temp[_i];
                           this.myFormArrayCorr.push(new FormControl(v,[Validators.required]));
                    }
 

                } else { //new

                    this.createEditHash();
                    

                    this.myForm.controls['estimation_expo'].setValue(estimation_expo);

                    let ct = 'hist';
                    let temp: any[] = [];
                    if (estimation_expo=='es'){
                        //this.myForm.controls['estimation_expo']
                        temp = this.fx_estresult_corr_exp_last;
                         ct = 'exp'; 
                    } else {
                        temp = this.fx_estresult_corr_hist_last;  //EW
                         ct = 'hist'; 
                    }

                    for (var _i = 0; _i < this.currency_list.length; _i++) {
                        for (var _j = 0; _j < this.commodity_list.length; _j++) {
                            let v:any;
                            v = this.edit_asset_type_hash[this.currency_list[_i]][this.commodity_list[_j]][ct];
                            if (v>=1) v=1;
                            this.myFormArrayCorr.push(new FormControl(v,[Validators.required]));
                        }
                    }

                }

                this.orig_asset_type_hash = _.cloneDeep(this.edit_asset_type_hash)

        });

         
    } 
    ngAfterViewInit() {
        // Your jQuery code goes here
        //$('[data-toggle="tooltip"]').tooltip();

    }

    onInputChange(ce,i,j){ 
        //let type = this.selectedType;
        let temp = this.myForm.controls['estimation_expo'].value
        
        let type='hist';
        if (temp=='es'){
            type = 'exp';
        }
        let cee = this.currency_list[i];
        let cc = this.commodity_list[j];
        
        let idx = ((i+1)*this.commodity_list.length)-this.commodity_list.length+j;
        this.edit_asset_type_hash[cee][cc][type]=this.myForm.controls['my_form_array_corr'].value[idx];
        
    }

    createEditHash(){

       let pair = '';
       for (var _i = 0; _i < this.edit_cross_estresult_corr_exp.length; _i++) {
                pair = this.edit_cross_estresult_corr_exp[_i]['_row'];
                this.edit_asset_type_hash[pair]={};
                for (var key in this.edit_cross_estresult_corr_exp[_i]) {
                    if (key!='_row'){
                        this.edit_asset_type_hash[pair][key]={};
                        this.edit_asset_type_hash[pair][key]['exp']=this.edit_cross_estresult_corr_exp[_i][key]
                        this.edit_asset_type_hash[pair][key]['hist']=this.edit_cross_estresult_corr_hist[_i][key]
                    }
                }
        }

    }


    back(){
        this._router.navigateByUrl('models/corcom/'+  this.model_id);
    }

    radioChange(event){
        let ct = 'hist';
        let test: any[] = [];
        let temp: any[] = [];
        if (event=='es'){
            temp = this.fx_estresult_corr_exp_last;
             ct = 'exp'; 
        } else {
            temp = this.fx_estresult_corr_hist_last;
             ct = 'hist'; 
        }

       

        
        for (var _i = 0; _i < this.currency_list.length; _i++) {
            for (var _j = 0; _j < this.commodity_list.length; _j++) {
                let v:any;
                v = this.edit_asset_type_hash[this.currency_list[_i]][this.commodity_list[_j]][ct];
                if (v>=1) v=1;
                test.push(v);
            }
        }

        this.myFormArrayCorr.setValue(test);

    }

  
    onSubmit() {
        var result:any;
        //this.myForm.controls['my_form_array_stored'].value;
        
     
        var data = {'correlations':this.myForm.controls['my_form_array_corr'].value,
                    'estimation_expo':this.myForm.controls['estimation_expo'].value,
                    'edit_asset_type_hash':this.edit_asset_type_hash,
                    'orig_asset_type_hash':this.orig_asset_type_hash,
                    'asset_list_fx':this.asset_list_fx,
                    'asset_list_comm':this.asset_list_comm};

        
        if (this.crud=='new'){
            result = this._apiService.addModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    
                    if (this.fx_list.length>0 && this.comm_list.length>0){
                        this.pollingService.announcePolling({'model_id':this.model_id,'is_output':false});
                    }
                    this._router.navigateByUrl('models');
                });
        }

        if (this.crud=='edit'){
            result = this._apiService.updateModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    if (this.fx_list.length>0 && this.comm_list.length>0){
                        this.pollingService.announcePolling({'model_id':this.model_id,'is_output':false});
                    }
                    this._router.navigateByUrl('models');
            });
        }
       
        
        
       

    }
} 
