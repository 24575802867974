import { Component, OnInit, ViewChild } from '@angular/core';
import { HostListener } from '@angular/core';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink,} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import * as _ from "lodash";
declare var $: any; 
declare var require: any;

//import {MatTableDataSource,MatPaginator, MatSort} from '@angular/material';

@Component({
  selector: 'app-output-exposuretables',
  templateUrl: './output-exposuretables.component.html',
  styleUrls: ['./output-exposuretables.component.css']
})
export class OutputExposuretablesComponent implements OnInit {

    //displayedColumns = ['id', 'name', 'progress', 'color'];

    columns = [
        { columnDef: 'userId',    header: 'ID',       cell: (row) => `${row.id}`        },
        { columnDef: 'userName',  header: 'Name',     cell: (row) => `${row.name}`      },
        { columnDef: 'progress',  header: 'Progress', cell: (row) => `${row.progress}%` },
        { columnDef: 'color',     header: 'Color',    cell: (row) => `${row.color}` }
      ];
      
      /** Column definitions in order */
      displayedColumns = this.columns.map(x => x.columnDef);


    //dataSource = new MatTableDataSource;
    //@ViewChild(MatPaginator) paginator: MatPaginator;
    //@ViewChild(MatSort) sort: MatSort;

  show_table = false;
  isLoading=false;
  id:string; 
  data={};
  sub: any;
  analysis_horizon_keys = []; 
  selectedAnalysisHorizon:any;
  analysis_horizon:any;

  model_names_list = []
  selected_model_list = []
  tax_list = ["pretax"]

  //percentile_filter = ["ES_01", "ES_05", "ES_10", "ES_20", "exp_value", "q_01", "q_05", "q_10", "q_20"]
  //percentile_filter = ["ES_01", "ES_05", "ES_10", "ES_20", "q_01", "q_05", "q_10", "q_20"]

  percentile_filter = ['ExpValue','Quantile','ES']
 
  tax_array = [{'name':'Pre Tax','id':'pretax'}]
  type_array = [{'name':'Earnings','id':'E'}] //,{'name':'Cash flow','id':'CF'}
  percentile_array = [
                      {'name':'05','id':'05'},
                      {'name':'10','id':'10'}
                      ]
  selectedPercentile='05';
  selectedTypeName='Earnings';
  selectedType='E';
  selectTaxType = 'pretax'
  selectedPercentileFilter = "ExpValue"
  selectedModel = ''
  
  risk_measure_array = ['aR','ES']
  selectedRiskMeasure = 'aR'
  
   expousre_type_array = ['All','FX','Commodity']
   selectedExpousreType = 'All'


  endpoint='/output'

  selectedChartType = 'dist'

  color_array = ['#9C27B0','#FF9800','#009688','#CDDC39']

  myForm: FormGroup; 
  myFormModels = new FormArray([]);

  lugo_keys = ["column_names", "lugotable_all", "lugotable_all_info"]
  column_names = []
  constructor(private route: ActivatedRoute,
              private _router: Router,
              private _apiService: ApiService,
              private fb: FormBuilder) { 
 
              this.myForm = fb.group({
                  selected_models_list: this.myFormModels
              })

              this.route.params.map(params => params['id']).subscribe(id => { 
                                    this.id=id
                                 
              });

              let id = this.id
              let type = 'exposuretables'
              this.makeDistCharts(id,type)

              let users = []
              for (let i = 1; i <= 100; i++) { 
                  users.push(this.createNewUser(i)); 
              }
              // Assign the data to the data source for the table to render
              //this.dataSource = new MatTableDataSource(users);
 

              }

    createNewUser(id: number) {
    
        return {
        'id': '1',
        'name': 'dude',
        'progress': '100',
        'color': 'maroon'
        };
    }


cardwidth =  510;
newInnerWidth:any;
newInnerHeight:any;
@HostListener('window:resize', ['$event'])
onResize(event) {
    this.newInnerHeight = event.target.innerHeight;
    this.newInnerWidth = event.target.innerWidth;
    this.cardwidth =  +this.newInnerWidth - 510 - 20;
}

classCell(row){
  
    return "{'colorDude' : true}"

}
styleCell(col_name,row){
    

    if (col_name['columnDef']=='progress' && row['progress']=='100'){
        return {'color' : 'red'}
    } else {
        return {}
    }

    /*
    if (col_name['columnDef']=='color'){
        return {'color' : 'pink'}
    } else {
        return {}
    }
    */
        

}

  ngOnInit() {
  }

  test={}
   makeDistCharts(id,type){

      this._apiService.getOutput(this.endpoint,id,type)
                      .subscribe(data => {
                          this.data = data

                         
                          let model_names_list = Object.keys(this.data);
                          this.model_names_list = _.sortedUniq(this.model_names_list);
                          this.selectedModel = model_names_list[0]
                          this.model_names_list = model_names_list

                          let analysis_horizon_keys = Object.keys(this.data[this.selectedModel][this.selectTaxType])
                          let temp = []
                          for (var _i = 0; _i < analysis_horizon_keys.length; _i++) {
                            temp.push(`M${_i+1}`)
                          }
                          this.analysis_horizon_keys = temp
                          this.selectedAnalysisHorizon = this.analysis_horizon_keys.slice(-1)[0];

                          this.updateData()
                          

                      }
                      ,null,() => { this.isLoading = false;
                                     this.show_table = true;
                        //this.busyService.announceBusy(false); 
                       
                      });
 
 
    } //end makeDistCharts



    
    data_hash = {}
    rows = []
    cols = []
    lugotable_all_info = {}
    updateData(){ 

      Object.keys(this.data[this.selectedModel][this.selectTaxType])

      let data = this.data[this.selectedModel][this.selectTaxType][this.selectedAnalysisHorizon][this.selectedType][this.selectedRiskMeasure][`q${this.selectedPercentile}`]["lugotable_all"]
      this.column_names = this.data[this.selectedModel][this.selectTaxType][this.selectedAnalysisHorizon][this.selectedType][this.selectedRiskMeasure][`q${this.selectedPercentile}`]["column_names"]
        
      //this.displayedColumns = this.column_names

 
      this.lugotable_all_info = this.data[this.selectedModel][this.selectTaxType][this.selectedAnalysisHorizon][this.selectedType][this.selectedRiskMeasure][`q${this.selectedPercentile}`]["lugotable_all_info"][0]

      this.data_hash = {}
      this.rows = []
      this.cols = []

       
       for (var _j = 0; _j < 7; _j++) {
        this.cols.push(`col${_j}`)
       }

       if (this.selectedExpousreType=='All'){

          for (var _i = 0; _i < data.length; _i++) {
            this.data_hash[`row${_i}`]={}
            this.rows.push(`row${_i}`)
            for (var _j = 0; _j < this.cols.length; _j++) {
                this.data_hash[`row${_i}`][`col${_j}`]=data[_i][`col${_j+1}`]
            }
          }
        } 

        if (this.selectedExpousreType=='FX'){
          for (var _i = 0; _i < data.length; _i++) {
            if (data[_i]["col2"]=='FX'){
                this.data_hash[`row${_i}`]={}
                this.rows.push(`row${_i}`)
                for (var _j = 0; _j < this.cols.length; _j++) {
                    this.data_hash[`row${_i}`][`col${_j}`]=data[_i][`col${_j+1}`]
                }
            }
          }
        } 

        if (this.selectedExpousreType=='Commodity'){
          for (var _i = 0; _i < data.length; _i++) {
            if (data[_i]["col2"]=='Comm'){
                this.data_hash[`row${_i}`]={}
                this.rows.push(`row${_i}`)
                for (var _j = 0; _j < this.cols.length; _j++) {
                    this.data_hash[`row${_i}`][`col${_j}`]=data[_i][`col${_j+1}`]
                }
            }
          }
        } 
       
    } //end updateData



    onTaxTypeChange(){
        this.updateData()
    }
    onPercentileChange(){
         this.updateData()
    }

    onPercentileFilterChange(){
        this.updateData()
    }

    onTypeChange(){
        this.updateData()
    }

    onModelChange(){
        this.updateData()
    }

    onCbChange(i){
        
       
        this.selected_model_list = []
        let sel = this.myForm.controls['selected_models_list'].value
        for (var _i = 0; _i < sel.length; _i++) {
            if (sel[_i]==true){
                this.selected_model_list.push(this.model_names_list[_i])
            }
        }
        

        //must have one value checked
        if (this.selected_model_list.length>0){
            this.updateData()
        } else {
            let tt = []
            for (var _i = 0; _i < sel.length; _i++) {
                tt.push(false)
            }
            tt[i]=true
            this.myForm.controls['selected_models_list'].setValue(tt)
        }
    } 


}

