import { Component, OnInit, ViewChild } from '@angular/core';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink,} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import * as _ from "lodash";
declare var $: any;
declare var require: any;


var Highcharts = require('highcharts/highstock');


@Component({
  selector: 'app-output-hedgingcost',
  templateUrl: './output-hedgingcost.component.html',
  styleUrls: ['./output-hedgingcost.component.css']
})
export class OutputHedgingcostComponent implements OnInit {

  isLoading=false;
  id:string; 
  data={};
  sub: any;
  analysis_horizon_keys = []; 
  selectedAnalysisHorizon:any;
  analysis_horizon:any;

  model_names_list = []
  selected_model_list = []

  panel_array = ["EaR","CFaR","EES","CFES"]
  tax_list = ["pretax"]

  tax_array = [{'name':'Pre Tax','id':'pretax'}]
  type_array = [{'name':'Earnings','id':'E'},{'name':'Cash Flows','id':'CF'}]
  percentile_array = [
                      {'name':'05','id':'05'},
                      {'name':'10','id':'10'}
                      ]
  selectedPercentile='05';
  selectedType='CF';
  selectTaxType = 'pretax'

  endpoint='/output'
  
  //color_array = ['#F44336','#E91E63','#9C27B0','#673AB7','#3F51B5','#2196F3','#03A9F4','#00BCD4','#009688']
  //color_array = ['#3F51B5','#2196F3','#03A9F4','#00BCD4','#009688']
  color_array = ['#9C27B0','#FF9800','#009688','#CDDC39']

  myForm: FormGroup;
  myFormModels = new FormArray([]);

  constructor(private route: ActivatedRoute,
              private _router: Router,
              private _apiService: ApiService,
              private fb: FormBuilder) { 

            
                this.myForm = fb.group({
                    selected_models_list: this.myFormModels
                })


               this.route.params.map(params => params['id']).subscribe(id => { 
                                      this.id=id
                                      
              });

             // mat-color($mat-cyan, A400);


              let id = this.id
              let type = 'monthlyrisk'
              this.makeDistCharts(id,type)





              }

  ngOnInit() {
  }

  getSeries(type,tax_type,ah,x,y){

        // x 50 
        // y 49

        /*
          "exp_value": [1.99],
          "q_01": [-0.27],
          "q_05": [0.35],
          "q_10": [0.71],
          "q_20": [1.15],
          "ES_01": [-0.63],
          "ES_05": [-0.05],
          "ES_10": [0.24],
          "ES_20": [0.6]



        */
        
        let temp_data = {}
        let series_temp = []
        let plotline_temp = []
        let percentile_temp = []
        let es_temp = []
        let series_hash = {}
        let series_hist_y_temp = []
        let series_hist_x_temp = []

        for (var _i = 0; _i < this.selected_model_list.length; _i++) {
            let mn = this.selected_model_list[_i]
            temp_data = this.data[mn][tax_type][ah][type];
            let data_den = _.zip(temp_data[x],temp_data[y]);
            let temp  = {
                color: this.color_array[_i],  
                showInLegend: false,     
                name: mn,
                dashStyle: "Solid",
                //color: 'rgba(223, 83, 83, .5)',
                lineWidth: 3,
                data: data_den,
            }
            series_temp.push(temp)

            let temp_y = {name: mn,data:temp_data[y]}
            series_hist_y_temp.push(temp_y)

            let temp_x = {name: mn,data:temp_data[x]}
            series_hist_x_temp.push(temp_x)

            let pl_temp = {}
            /*
            if (this.is_show_expectation==true){

                pl_temp = {
                        //colorName: 'ass', // Red
                                                value: temp_data['exp_value'], // Position, you'll have to translate this to the values on your x axis
                        label:{
                            text:'Exp',
                            verticalAlign:"top",
                        },
                        color: this.color_array[_i], // Red
                        dashStyle: "Solid",
                        width: 1

                }

                 plotline_temp.push(pl_temp)

            } 
            */

            /*
            if (this.is_show_percentile==true){

                pl_temp = {
                        label:{
                            text:`q_${this.selectedPercentile}`,
                            verticalAlign:"top",
                            y:50
                        },
                        color: this.color_array[_i], // Red
                        dashStyle: "dash",
                        width: 1,
                        value: temp_data[`q_${this.selectedPercentile}` ] // Position, you'll have to translate this to the values on your x axis
                 }

                 plotline_temp.push(pl_temp)

            } 
            */

            /*
            if (this.is_show_es==true){


                     pl_temp = {
                        label:{
                            text:`ES_${this.selectedPercentile}`,
                            verticalAlign:"middle"
                        },
                        color: this.color_array[_i], // Red
                        dashStyle: "dot", 
                        width: 1,
                        value: temp_data[`ES_${this.selectedPercentile}` ] // Position, you'll have to translate this to the values on your x axis
                   }

                 plotline_temp.push(pl_temp)

            } 
            */
            

            /*
            let temp_lines = {}
            temp_lines["exp_value"]: [1.99],
            temp_lines["q_01"]: [-0.27],
            temp_lines["q_05"]: [0.35],
            temp_lines["q_10"]: [0.71],
            temp_lines["q_20"]: [1.15],
            temp_lines["ES_01"]: [-0.63],
            temp_lines["ES_05"]: [-0.05],
            temp_lines["ES_10"]: [0.24],
            temp_lines["ES_20"]: [0.6]
            */



        } //end _i

       let test = this.data[this.model_names_list[0]][tax_type][ah][type]
       var title = test["chart_title"][0]
       var chart_ylab = test["chart_ylab"][0]
       var chart_xlab = test["chart_xlab"][0]

        series_hash = {'series_list':series_temp}
        series_hash['series_list_y'] = series_hist_y_temp
        series_hash['series_list_x'] = series_hist_x_temp
        series_hash['plotline'] = plotline_temp

        series_hash['title'] = title
        series_hash['chart_ylab'] = chart_ylab
        series_hash['chart_xlab'] = chart_xlab
        
        return series_hash

   } //end getSeries

   test={}
   makeDistCharts(id,type){

      this._apiService.getOutput(this.endpoint,id,type)
                      .subscribe(data => {
                          this.data = data
                       

                        
                          let tax_type = this.selectTaxType
                          let analysis_horizon_keys = Object.keys(this.data[tax_type][`q${this.selectedPercentile}`]);
                         
                          let temp = []
                          for (var _i = 0; _i < analysis_horizon_keys.length; _i++) {
                            temp.push(`M${_i+1}`)
                          }
                         
                          this.analysis_horizon_keys = temp
                          this.selectedAnalysisHorizon = this.analysis_horizon_keys.slice(-1)[0];
                         
                          this.model_names_list = Object.keys(this.data[tax_type][`q${this.selectedPercentile}`][this.selectedAnalysisHorizon][0]);
                         
                           for (var _i = 0; _i < this.model_names_list.length; _i++) {
                            if (_i<3){
                                this.myFormModels.push(new FormControl(true));
                                this.selected_model_list.push(this.model_names_list[_i])
                            } else {
                                this.myFormModels.push(new FormControl(false));
                            }
                           }

                          

                          var index = this.model_names_list.indexOf('Name');
                          this.model_names_list.splice(index, 1); 

                          index = this.model_names_list.indexOf("axislabel");
                          this.model_names_list.splice(index, 1); 

                          this.updateData()
                      }
                      ,null,() => { this.isLoading = false;
                        //this.busyService.announceBusy(false); 
                      
                      });


    } //end duder

    dudder(container_id,args,series_hash){

         

         let series_list = args['series']
       

         var ah_lag = this.selectedAnalysisHorizon.replace("M", "")

         let data_den = _.zip(this.test[ args.x],this.test[ args.y]);
         let exp_value = 0 //this.test['exp_value'][0];

         
        
          let q = 0 //this.test[`q_${this.selectedPercentile}`]
          let ES = 0 //this.test[`ES_${this.selectedPercentile}`]

          var title = args['title']
          var chart_ylab =  args['x_label']
          var chart_xlab =  args['y_label']

         $(function(){ 

            Highcharts.chart(container_id, {
                title: {
                    //text: `Earnings Distribution Plot: ${ah_lag} Month EaR`
                    text: title
                    
                },
                chart: {
                    type: 'scatter',
                    zoomType: 'xy'
                },

                credits: {
                        enabled: false
                },
                /*
                subtitle: {
                    text: 'Source: thesolarfoundation.com'
                },
                */

                xAxis: {
                  title: {
                        text: chart_xlab
                    },
                    //plotLines: series_hash['plotline']
                },


                yAxis: {
                    title: {
                        text:  chart_ylab
                    }
                },
                /*
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },
               */
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}'
                        }
                    }
                },
 
                series: series_list

                        })
        
         });
        




    } //end dudder




  updateData(){

        let series_hash = {}
        let arg_list = []

/*
        "pretax": {
    "q01": {
      "M1"

      this.model_names_list
      this.model_names_list = Object.keys(this.data[tax_type][`q${this.selectedPercentile}`][this.selectedAnalysisHorizon][0]);
             selectedPercentile='01';
  selectedType='CF';
  selectTaxType = 'pretax'               
      */


       let data_list = this.data[this.selectTaxType][`q${this.selectedPercentile}`][this.selectedAnalysisHorizon]
       let cost_hash = data_list.slice(-1)[0];

       let model_lookup_index = {}
       for (var _i = 0; _i < data_list.length; _i++) {
            if (data_list[_i]['Name']!='Name' && data_list[_i]['Name']!='Cost'){
                let name = data_list[_i]['Name'];
                model_lookup_index[name]=[]
                for (var _j = 0; _j < this.selected_model_list.length; _j++) {
                    model_lookup_index[name].push({x:+cost_hash[this.selected_model_list[_j]],y:+data_list[_i][this.selected_model_list[_j]],name:this.selected_model_list[_j]})
                }
            }
       }

     
 
       let args_hash = {}
       let name = ''
       let test_this = []
       let cost = 0;
       
        
       let y_label = cost_hash["axislabel"]


       for (var _i = 0; _i < data_list.length; _i++) {
            if (data_list[_i]['Name']!='Name' && data_list[_i]['Name']!='Cost'){
                name = data_list[_i]['Name']
                let temp_s = {}
                temp_s['name']=name
                temp_s['title']=' '
                temp_s['y_label']=y_label
                temp_s['x_label']=data_list[_i]["axislabel"]
                temp_s['container_id']=`container_scatter_${name}`
                temp_s['series']=[{ 
                                 showInLegend: false,
                                 //name: "dude",
                                
                                 /*
                                 data:[
                                  {x:+cost_hash["Unhedged"],y:+data_list[_i]["Unhedged"],name:"Unhedged"},
                                  {x:+cost_hash["Current"],y:+data_list[_i]["Current"],name:"Current"},
                                  {x:+cost_hash["HP1"],y:+data_list[_i]["HP1"],name:"HP1"},
                                  {x:+cost_hash["HP1 - 50% Options"],y:+data_list[_i]["HP1 - 50% Options"],name:"HP1 - 50% Options"}
                                  ]
                                  */
                                  data: model_lookup_index[name]
                                }]
                args_hash[name]=temp_s
                arg_list.push(temp_s)
            }
       }

     

       //let args = arg_list[0]
       //series_hash = {}
       //this.dudder(args.container_id,args,series_hash)
        
        
        for (var _i = 0; _i < arg_list.length; _i++) {
            let args = arg_list[_i]
            //series_hash = this.getSeries(args.type,this.selectTaxType,this.selectedAnalysisHorizon,args.x,args.y)
            series_hash = {}
            this.dudder(args.container_id,args,series_hash)
        }
        
        

    }

   onTaxTypeChange(){
        this.updateData()
    }

    onAnalysisHorizonChange(){
        this.updateData()
    }

    onPercentileChange(){
        this.updateData()
    }

    onCbChange(i){
        
       
        this.selected_model_list = []
        let sel = this.myForm.controls['selected_models_list'].value
        for (var _i = 0; _i < sel.length; _i++) {
            if (sel[_i]==true){
                this.selected_model_list.push(this.model_names_list[_i])
            }
        }
        

        //must have one value checked
        if (this.selected_model_list.length>0){
            this.updateData()
        } else {
            let tt = []
            for (var _i = 0; _i < sel.length; _i++) {
                tt.push(false)
            }
            tt[i]=true
            this.myForm.controls['selected_models_list'].setValue(tt)
        }
    } 

}
