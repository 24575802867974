import { Component, OnInit, OnDestroy } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import { Http, Headers } from '@angular/http';
import { contentHeaders } from './common/headers';
import { environment } from '../environments/environment';
import { Router,ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { BusyService } from './busy.service';
import { PollingService } from './polling.service';
import { ModelRefreshService } from './model-refresh.service';
import { LoggedinService } from './loggedin.service';

import { AuthHttp  } from 'angular2-jwt';
import { ApiService } from './api_service/api.service';
import { AuthService } from './auth/auth.service';

import { Subscription } from 'rxjs/Subscription';
import {Observable} from "rxjs/Rx";

import * as _ from "lodash";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit,OnDestroy{

  polling_endpoint = '/test/polling';
  running_models = {}
  polling_sub:any;
  polling:object;
  af_poll:any;
  busy_sub:any;
  li_sub:any;
  isLoggedIn = false;
  busy=false;
  
  firstName:string;
  lastName:string;
  full_name:string;
  login_data:any;
  is_name = false;
  af_cred:any;
  env=false;

  disable_links = true;


  constructor(private fb: FormBuilder,
              public http: Http,
              public router: Router,
              private route: ActivatedRoute,
              private loggedinService: LoggedinService,
              private busyService: BusyService,
              private pollingService: PollingService,
              private modelrefreshService: ModelRefreshService,
              public snackBar: MatSnackBar,
              private authService: AuthService,
              private _apiService: ApiService,
              private _authHttp: AuthHttp) {

              authService.handleAuthentication();  

 
              if (environment.environment=='local'){
                this.env=true;
              }

              this.polllingCallService()
             
              /*
              Bootstrap by after login get all running models 
              and pass to the below

              this.pollingService.announcePolling({'model_id':gg,'is_output':false}); 
              pass the model name To so pass to snack bar

              */



  } //end constructor

  
  //this.pollingService.announcePolling({'model_id':gg,'is_output':false}); 
 

 
  getEndpointPollingUrl(endpoint:string){

    return environment.API_URL + this.polling_endpoint;
  }

  getCreds(){

    
     this.af_cred = this._apiService.getModels('/creds')
        .subscribe(data => {
            this.login_data = data
            this.firstName = this.login_data['results']['firstName'];
            this.lastName = this.login_data['results']['lastName'];

            localStorage.setItem('firstName', this.firstName);
            localStorage.setItem('firstLast', this.lastName);

            this.full_name = `${this.firstName} ${this.lastName}`
            this.is_name = true;
        },
        error => {
            this.is_name = false;
          }
        );
  }
 
  ngOnInit(){
  
    console.log(this.router.url)

    
    this.li_sub = this.loggedinService.loggedinAnnounced$.subscribe(
                    isLoggedIn => {
                      this.isLoggedIn = isLoggedIn;
                    
                      if (this.isLoggedIn==true){

                        if (_.isEmpty(this.running_models)==true){

                        }

                        //this.is_name = false;
                        if (localStorage.getItem('token')) {
                          this.getCreds();
                        } else {
                          
                        }
                        
                      } else{
                        
                      }
     });
 
      //this.busyService.announceBusy(false); 
      this.busy_sub = this.busyService.busyAnnounced$.subscribe(
                    busy => {
                      this.busy = busy;
                      if (this.busy==true){
                        
                      } else{
                        
                      }
     });

  } // end ngOnInit()



  

  polllingCallService(){

    this.polling_sub = this.pollingService.pollingAnnounced$.subscribe(
      polling => {
        this.polling = polling;
        console.log('polling:',polling)
        if (this.polling){
          this.pollingRemoteService(polling)
        } else{
        }
      });
  } //end 

 
  isEmpty(obj) {
    let temp  = obj['data']
    if (temp){
        for (var _i = 0; _i < temp.length; _i++) {
          if (obj.hasOwnProperty(temp[_i]['model_id'])==false && temp[_i]['data']['status']=='running'){
            this.running_models[temp[_i]['model_id']]={'name':temp[_i]['data']['name'],'status':temp[_i]['data']['status']}
          }

          //If complete show snack bar
          if (this.running_models.hasOwnProperty(temp[_i]['model_id'])==true && temp[_i]['data']['status']=='complete'){
            this.modelrefreshService.announceModelrefresh(true); 
            this.openPollingSnackBar(temp[_i]['data']['name'])
            delete this.running_models[temp[_i]['model_id']]
          }
        }
    }

    if (_.isEmpty(this.running_models)==false){
      return false
    } else {
      return true
    }


  }

  pollingRemoteService(polling){

    let url = this.getEndpointPollingUrl(this.polling_endpoint)
    var result = Observable.interval(5000)
                           .switchMap(() => this._authHttp.get(url + '/' + polling['model_id']))
                           .map(res => res.json())
                           .takeWhile(models => this.isEmpty(models) == false);

    this.af_poll = result.subscribe(
      polldata  => {
        //this.polldata = polldata;
       
        //this.pollingComplete()
      },
      error => {
        //let error_json = JSON.parse(error.text())
       
       
      }
    );
    


  }

  pollingComplete(){
    this.af_poll.unsubscribe()
    this.af_poll=undefined
  }

  openPollingSnackBar(name) {

    /*this.snackBar.openFromComponent(PollingMessageComponent, {
      duration: 5000,
    });*/

    let message = "Model is complete:  " + name
    this.snackBar.open(message,'', {
      duration: 5000,
    });
  }

  onClick(event){

       this.busyService.announceBusy(true);
       if (event=='dashboard'){
          this.router.navigateByUrl('/dashboard');
       }

       if (event=='model'){
          this.router.navigateByUrl('/models');
       }
       if (event=='output'){
          this.router.navigateByUrl('/output');
       }
       if (event=='analysis'){
          this.router.navigateByUrl('/analysis');
       }
     } //end onClick


     
     ngOnDestroy(){

        if(this.af_cred){
          this.af_cred.unsubscribe();
        }

        /*
        if(this.af_poll){
          this.af_poll.unsubscribe();
        }
        this.af_poll==undefined
        */
        
        
      } //end OnDestroy

      changePassword(){
        //this.router.navigateByUrl('/password');
        this.router.navigate(['/password']);
      }

  

    logout() {
      
      this.is_name = false;
      if(this.af_cred){
        this.af_cred.unsubscribe();
      }
      if(this.af_poll){
        this.af_poll.unsubscribe();
      }
      this.af_poll=undefined
      
      
      this.isLoggedIn=false;
      localStorage.removeItem('id_token');
      localStorage.removeItem('firstName');
      localStorage.removeItem('firstLast');
      localStorage.removeItem('token')
      

      this.busyService.announceBusy(false);
      this.loggedinService.announceLoggedin(false);
        //this.router.navigateByUrl('/login(content:nullcontent)');
      this.router.navigate(['/login']);
     }



}




@Component({
  selector: 'pollng-message',
  template: `
  <span class="example-pizza-party">
  Polling is complete...
  </span>`,
  styles: [`.example-pizza-party { color: #0C7238; }`],
})
export class PollingMessageComponent {}