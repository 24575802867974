import {Component, OnInit, Output,EventEmitter,Provider, forwardRef, AfterViewInit} from '@angular/core';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { ModelValidators } from '../validators/validators';
import { CURRENCIES } from '../object_lists/assets_object';
import {MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA} from '@angular/material';

import { FormComponent } from '../can_deactivate/prevent_unsaved_changes_gaurd.service';
import { LoggedinService } from '../loggedin.service';
import { BusyService } from '../busy.service';
import 'rxjs/add/operator/first';
import { DialogRouteCanDeactiveComponent } from '../dialog-route-can-deactive/dialog-route-can-deactive.component'

 
import * as _ from "lodash";



@Component({
    templateUrl: './mesa-hedge-initial.component.html',
    styleUrls: ['../app.component.css'],
    providers: [RouterLink, FormBuilder, ApiService ]
})  
export class MesaHedgeInitialComponent implements OnInit, AfterViewInit, FormComponent{

    endpoint='/test/model/hedgeinitial';
    use_hash = false;
    myForm: FormGroup;
    model_id: string;
    isLoading = false;
    crud:string;
    deferred_earnings_max:number = 13;
    deferred_earnings_array: any[] = [];

    memory_analysis_horizon:any;
    memory_etr:any; 
    memory_deferred:any;

    analysis_horizon_max:number = 60;
    analysis_horizon_array: any[] = [];
    edit_analysis_horizon:any;
    edit_deferred_earnings:any;

    etrDividerColor="primary"
    etrHintLabel=""
    
 
    busy=true; 
    constructor(
        private loggedinService: LoggedinService,
        private busyService: BusyService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private _apiService: ApiService,
        public dialog: MatDialog) {

        this.loggedinService.announceLoggedin(true);

        this.myForm = fb.group({
             base_currency: ['USD'],
             analysis_horizon: [12,[Validators.required]],
             exposure: ['earnings'],
             etr: [0,[Validators.required,ModelValidators.validEtr]],
             deferred_earnings: ['0',[Validators.required]]
         })

         //section 10 lecture 46 reactiv forms woth rjxs
         this.myForm.valueChanges.subscribe(data => {
                  
                    if (this.myForm.controls['etr'].dirty && !this.myForm.controls['etr'].valid){
                        this.etrDividerColor="warn"
                        this.errorStyle('etr')
                        this.etrHintLabel="Required"
                        
                    } else {
                        this.etrDividerColor="primary"
                        this.errorStyle('etr')
                        this.etrHintLabel=""
                    }
        });

        this.busyService.announceBusy(false);
    }

    api_object:any;
      
    currency_list: any[] = [];
    corr_array: any[] = [];
    deferred_earnings:string;
    temp:any;
    //currency_array = CURRENCIES;

    currency_array = [{ type: 'fx', displayname: 'USD', ivol: 1, forward: 1, NDF: 1 }]

    backward_title = "Market Risk Factors";
    backward_route="marketriskfactors";

    selectedValue: string;

  foods = [
    {value: 'steak-0', viewValue: 'Steak'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'}
  ];


   errorStyle(control) {

        let isvalid = this.myForm.controls[control].valid

        if (!this.myForm.controls[control].touched){
            return {'color': 'black'};
        }
            
        if (!isvalid){
            return {'color': 'red'};
        }

        if (isvalid){
            return {'color': 'black'};
        }

   }



   is_submit = false;
   canDeactivate() {
     
       //https://scotch.io/courses/routing-angular-2-applications/candeactivate
        if (this.myForm.dirty == true && this.is_submit==false){

                /*
            var confirm = window.confirm('Discard changes?')
          
            return confirm;\*/
          
            
            //http://www.madhur.co.in/blog/2017/03/26/angular-confirmation-dialog.html
            
           
            const config = new MatDialogConfig();
            config.disableClose=true;
            //let dialogRef = this.dialog.open(DialogCanDeactive,config);
            let dialogRef = this.dialog.open(DialogRouteCanDeactiveComponent,config);

            
           
            

        } //end if
            
       
       
        return true
        
        
    }




    ngOnInit() {


        for (var _i = 0; _i < this.analysis_horizon_max; _i++) {
            this.analysis_horizon_array.push(_i+1);
        }
        /*
        1) get all hedgemodles models
        2) Find the models that use tnis exosure modelk - name and modelid

        */

        this.route.params.map(params => params['id'])
            .subscribe(id => { this.model_id=id });

        this.route.data.forEach((data) => {
            
                
                if (data['results'].hasOwnProperty('hedgeinitial')===false){
                    this.crud='new'; 
                } else {
                    this.crud='edit';
                    this.getAllModels();
                    this.myForm.controls['base_currency'].setValue(data['results']["hedgeinitial"]["base_currency"]);
                    this.myForm.controls['analysis_horizon'].setValue(+data['results']["hedgeinitial"]["analysis_horizon"]);
                    this.memory_analysis_horizon=+data['results']["hedgeinitial"]["analysis_horizon"];
                    this.edit_analysis_horizon = +data['results']["hedgeinitial"]["analysis_horizon"]
                    this.myForm.controls['exposure'].setValue(data['results']["hedgeinitial"]["exposure"]);
                    this.myForm.controls['etr'].setValue(+data['results']["hedgeinitial"]["etr"]);
                    this.memory_etr = +data['results']["hedgeinitial"]["etr"]
                    this.myForm.controls['deferred_earnings'].setValue(data['results']["hedgeinitial"]["deferred_earnings"].toString());
                    this.memory_deferred = data['results']["hedgeinitial"]["deferred_earnings"].toString()
                    this.edit_deferred_earnings = +data['results']["hedgeinitial"]["deferred_earnings"];

                    //this.myForm.controls['analysis_horizon'].disable();
                    //this.myForm.controls['deferred_earnings'].disable();
                    //estimation_lambda: new FormControl({value: .94, disabled: true}, [Validators.required,ModelValidators.validLambda]),
            }


                /*
                if (data['results']["marketriskfactors"]["commodities"].length==0){
                    this.backward_title="Correlation Estimates: Currencies";
                    this.backward_route="corfx";
                } 
                */

        });

        for (var _i = 0; _i < this.deferred_earnings_max; _i++) {
            this.deferred_earnings_array.push(_i.toString());
        }

         
    }
    ngAfterViewInit() {

    }

    


    back(){
        //this._router.navigateByUrl('/dashboard(content:models/' + this.backward_route + '/'+  this.model_id + ';model=exposure)');
        this._router.navigateByUrl('models/edit/' +  this.model_id +';model=exposure');
    }

    ChangeAnalysisHorizon(){

     if (this.myForm.controls['analysis_horizon'].value<this.memory_analysis_horizon){
        this.openDialog()
     }

     if (this.myForm.controls['analysis_horizon'].value>this.memory_analysis_horizon){
        this.openDialogIncrease()
     }
     
    
     
    }

    
    models: any[];
    models_risk: any[] = [];
    models_hedging: any[] = [];
    models_exposures: any[] = [];
    getAllModels(){

      this._apiService.getModels('/test/model')
                      .subscribe(models => {
                          this.models = models
                          this.models_exposures = []
                          this.models_hedging = []
                          this.models_risk = []
                          for (var _i = 0; _i < models.length; _i++) {

                            
                            if (models[_i]['data']['type']=='risk'){

                                /*
                                "models_id_exposure": ["mid_49fe28a8f6e64766b395c714c6b11994"],
		                        "my_form_exposure_model_use": [true]
                                */

                               for (var _j = 0; _j < models[_i]['data']['models_id_exposure'].length; _j++) {
                                   if (models[_i]['data']['models_id_exposure'][_j]==this.model_id && models[_i]['data']['my_form_exposure_model_use'][_j]==true){
                                       this.models_risk.push(models[_i]);
                                   }
                                } 
                            }
                            

                            if (models[_i]['data']['type']=='hedging' && models[_i]['data']['my_form_exposure_hedging_model_id_use']==this.model_id){
                              this.models_hedging.push(models[_i]);
                            }


                            /*
                            if (models[_i]['data']['type']=='exposure'){
                              if (models[_i]['data']['data'].hasOwnProperty('hedgeexpcom')){
                                  models[_i]['data']['status']='complete';
                              }
                              this.models_exposures.push(models[_i]);
                            }
                            */
                  
                          }
                      }
                      ,null,() => { this.isLoading = false; 
                      
                      });


    }

    /*
    Only if edit and used in hedg modle
    */


    openDialog(){
            if (this.crud=='edit' && this.models_hedging.length>0){
                    const config = new MatDialogConfig();
                    config.disableClose=true;

                    let dialogRef = this.dialog.open(DialogDelete,config);
                    dialogRef.componentInstance.analysis_horizon = this.myForm.controls['analysis_horizon'].value;  //    http://stackoverflow.com/questions/34205593/working-example-of-angular-2-0-material-mddialog-with-angular-2-0/40185852#40185852
                    dialogRef.componentInstance.models_hedging = this.models_hedging;
                    dialogRef.componentInstance.models_risk = this.models_risk;
                    dialogRef.componentInstance.memory_analysis_horizon = this.memory_analysis_horizon;
                    
                    dialogRef.afterClosed().subscribe(result => {
            
                        if (result=='cancel'){
                            this.myForm.controls['analysis_horizon'].setValue(this.memory_analysis_horizon);
                        } else {
                            this.memory_analysis_horizon = this.myForm.controls['analysis_horizon'].value;

                            //what if form is not valid!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                            if (this.myForm.valid){
                                result = this._apiService.updateModelId(this.endpoint,this.model_id,this.myForm.value).subscribe(x => {});
                            }
                        }
                    }); 
            }
    }// end dialog

    openDialogIncrease(){
            if (this.crud=='edit' && (this.models_hedging.length>0 || this.models_risk.length>0)){
                    const config = new MatDialogConfig();
                    config.disableClose=true;

                

                    let dialogRef = this.dialog.open(DialogIncrease,config);

                    dialogRef.componentInstance.analysis_horizon = this.myForm.controls['analysis_horizon'].value;  //    http://stackoverflow.com/questions/34205593/working-example-of-angular-2-0-material-mddialog-with-angular-2-0/40185852#40185852
                    dialogRef.componentInstance.models_hedging = this.models_hedging;
                    dialogRef.componentInstance.models_risk = this.models_risk;
                    dialogRef.componentInstance.memory_analysis_horizon = this.memory_analysis_horizon;
                    dialogRef.afterClosed().subscribe(result => {
                        if (result=='cancel'){
                            this.myForm.controls['analysis_horizon'].setValue(this.memory_analysis_horizon);
                        } else {
                            this.memory_analysis_horizon = this.myForm.controls['analysis_horizon'].value;
                            //save form now!!!!!!!!!!!!!!
                        }
                    }); 
              }
    }// end dialog

    openDialogEtr(){

          //CAPS LOCKS SET TBE FIELD FORM TO PRISTINE!!!!!!!!!!!!!!!!!!!!!!!!!!



           if (this.myForm.controls['etr'].valid && this.crud=='edit' && this.models_hedging.length>0){
                    const config = new MatDialogConfig();
                    config.disableClose=true;

                    

                    let dialogRef = this.dialog.open(DialogEtr,config);

                    dialogRef.componentInstance.analysis_horizon = this.myForm.controls['analysis_horizon'].value;  //    http://stackoverflow.com/questions/34205593/working-example-of-angular-2-0-material-mddialog-with-angular-2-0/40185852#40185852
                    dialogRef.componentInstance.models_hedging = this.models_hedging;
                    dialogRef.componentInstance.models_risk = this.models_risk;
                    dialogRef.componentInstance.etr = this.myForm.controls['etr'].value;
                    dialogRef.componentInstance.this_exposure_model_id=this.model_id;
                    
                    dialogRef.afterClosed().subscribe(result => {
                        if (result=='cancel'){
                            this.myForm.controls['etr'].setValue(this.memory_etr);
                        } else {
                            this.memory_etr = this.myForm.controls['etr'].value;
                        }
                    }); 
           }
    }// end dialog

    openDialogDeferred(){

              //CAPS LOCKS SET TBE FIELD FORM TO PRISTINE!!!!!!!!!!!!!!!!!!!!!!!!!!

            if (this.crud=='edit' && this.models_hedging.length>0){
                    const config = new MatDialogConfig();
                    config.disableClose=true;


                    let dialogRef = this.dialog.open(DialogDeferred,config);

                    dialogRef.componentInstance.analysis_horizon = this.myForm.controls['analysis_horizon'].value;  //    http://stackoverflow.com/questions/34205593/working-example-of-angular-2-0-material-mddialog-with-angular-2-0/40185852#40185852
                    dialogRef.componentInstance.models_hedging = this.models_hedging;
                    dialogRef.componentInstance.models_risk = this.models_risk;
                    dialogRef.componentInstance.deferred = this.myForm.controls['deferred_earnings'].value;
                    dialogRef.componentInstance.this_exposure_model_id=this.model_id;

                    dialogRef.afterClosed().subscribe(result => {
                        if (result=='cancel'){
                            this.myForm.controls['deferred_earnings'].setValue(this.memory_deferred);
                        } else {
                            this.memory_analysis_horizon = this.myForm.controls['deferred_earnings'].value;
                        }
                    }); 
            }
    }// end dialog
 
    onSubmit() {

     

        this.is_submit = true;

        var result:any;
        
        var data = this.myForm.value;
 
        data['analysis_horizon']=+data['analysis_horizon'];
        data['deferred_earnings']=+data['deferred_earnings'];
        data['etr']=+data['etr'];

        if (this.crud=='new'){
            result = this._apiService.addModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    this._router.navigateByUrl('models/hedgeexpfx/' +  this.model_id);
                });
        }
 
        if (this.crud=='edit'){

            //data['analysis_horizon']=this.edit_analysis_horizon;
            //data['deferred_earnings']=this.edit_deferred_earnings;
           
            result = this._apiService.updateModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    this._router.navigateByUrl('models/hedgeexpfx/' +  this.model_id);
            });
          
        }
        
       
        
        
       

    }
} 


/*
DELETE
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
*/


@Component({
  selector: 'delete_dialog',
  templateUrl: './delete_dialog.html',
})
export class DialogDelete {
  

  selectedValue:string;
  myForm: FormGroup;
  memory_analysis_horizon:any;
  analysis_horizon:any;
  models_hedging: any[] = [];
  models_risk: any[] = [];

  d1=true;
  d2=false;



  constructor(public dialogRef: MatDialogRef<DialogDelete>,
              private fb: FormBuilder,
              private _apiService: ApiService) {

               this.myForm = this.fb.group({
                  name: ['',[Validators.required]],
                }) //end form

  } //end constructor

  onContinue(){

      this.d1=false;
      this.d2=true;
  }

  updateHedging(event){

    let drop = this.memory_analysis_horizon - this.analysis_horizon;

    if (event=='update'){
        //models_hedging   model.
        /*
        for each model in hedging delete the last N rowls of layering only
        */

        let data_list = [];
        for (var _i = 0; _i < this.models_hedging.length; _i++) {
            if (this.models_hedging[_i].data.data.hasOwnProperty('hedgelayering')){

               
                //this.models_hedging[_i].data.name = this.models_hedging[_i].data.name + '_clone'

             
                //update layering
                let chuck = _.chunk(this.models_hedging[_i].data.data.hedgelayering.layering, this.memory_analysis_horizon)
                let temp0 = []
                for (var _j = 0; _j < chuck.length; _j++) {
                    temp0 = _.concat(temp0, _.dropRight(chuck[_j], drop));
                }
             
                //this.models_hedging[_i].data.data.hedgelayering.layering = temp0;
                this.models_hedging[_i].data.data.hedgelayering.analysis_horizon = this.analysis_horizon;

                for (var asset  in this.models_hedging[_i].data.data.hedgelayering.edit_asset_type_hash){
                    let temp = this.models_hedging[_i].data.data.hedgelayering.edit_asset_type_hash[asset]
                    this.models_hedging[_i].data.data.hedgelayering.edit_asset_type_hash[asset] = _.dropRight(temp, drop)

                    let temp2 = this.models_hedging[_i].data.data.hedgelayering.orig_asset_type_hash[asset]
                    this.models_hedging[_i].data.data.hedgelayering.orig_asset_type_hash[asset] = _.dropRight(temp2, drop)
                }

                var data = {
                     'layering':temp0,
                     'edit_asset_type_hash':this.models_hedging[_i].data.data.hedgelayering.edit_asset_type_hash,
                     'orig_asset_type_hash':this.models_hedging[_i].data.data.hedgelayering.orig_asset_type_hash,
                     'analysis_horizon':this.analysis_horizon,
                     'table_key':'hedgelayering',
                     'model_id':this.models_hedging[_i].model_id
                };

                data_list.push(data);

            }//end if
        }//end _i


     
        var result:any;
        let endpoint='/test/model/listupdate';
        result = this._apiService.addModel(endpoint,data_list).subscribe(x => {
                                //let model_id = x.model_id; 
                                this.dialogRef.close();         
        }); //end result
        
    } //end update

   if (event=='clone'){
       for (var _i = 0; _i < this.models_hedging.length; _i++) {
            if (this.models_hedging[_i].data.data.hasOwnProperty('hedgelayering')){
                   
                   
                    this.models_hedging[_i].data.name = this.models_hedging[_i].data.name + '_clone'

                 
                    //update layering
                    let chuck = _.chunk(this.models_hedging[_i].data.data.hedgelayering.layering, this.memory_analysis_horizon)
                    let temp0 = []
                    for (var _j = 0; _j < chuck.length; _j++) {
                        temp0 = _.concat(temp0, _.dropRight(chuck[_j], drop));
                    }
                 
                    this.models_hedging[_i].data.data.hedgelayering.layering = temp0;
                    this.models_hedging[_i].data.data.hedgelayering.analysis_horizon = this.analysis_horizon;

                    for (var asset  in this.models_hedging[_i].data.data.hedgelayering.edit_asset_type_hash){
                        let temp = this.models_hedging[_i].data.data.hedgelayering.edit_asset_type_hash[asset]
                        this.models_hedging[_i].data.data.hedgelayering.edit_asset_type_hash[asset] = _.dropRight(temp, drop)

                        let temp2 = this.models_hedging[_i].data.data.hedgelayering.orig_asset_type_hash[asset]
                        this.models_hedging[_i].data.data.hedgelayering.orig_asset_type_hash[asset] = _.dropRight(temp2, drop)
                    }


            }//end hasOwnProperty('hedgelayering')

       } //end _i

        var result:any;
        let endpoint='/test/model/clone';
        result = this._apiService.addModel(endpoint,this.models_hedging).subscribe(x => {
                                //let model_id = x.model_id; 
                                this.dialogRef.close();         
        }); //end result
        

    } //end clone

   
    this.dialogRef.close()
  }



   onSubmit(){

     /*
      var result:any;
      this.model.data.name = this.myForm.controls['name'].value
      let endpoint='/test/model/clone';
      result = this._apiService.addModel(endpoint,this.model).subscribe(x => {
                            //let model_id = x.model_id; 
                            this.dialogRef.close();         
      }); //end result
      */

  } //end onSubmit


}



/*
INCREASE
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
*/


@Component({
  selector: 'increase_dialog',
  templateUrl: './increase_dialog.html',
})
export class DialogIncrease implements OnInit {
  

  selectedValue:string;
  myForm: FormGroup;
  analysis_horizon:any;
  models_hedging: any[] = [];
  models_risk: any[] = [];
  memory_analysis_horizon:any;

  d1=true;
  d2=false;



  constructor(public dialogRef: MatDialogRef<DialogIncrease>,
              private fb: FormBuilder,
              private _apiService: ApiService) {

                  

               this.myForm = this.fb.group({
                  name: ['',[Validators.required]],
                }) //end form

  } //end constructor

  ngOnInit(){

              

                  if (this.models_risk.length==0){
                      this.d1=false;
                      this.d2=true;
                  }

  }

  onContinue(){

      this.d1=false;
      this.d2=true;
  }

  updateRisk(event){

      if (event=='update'){

          // Do nothing becuase run risk will self heal
          //

            /*
            let data_list = [];
            for (var _i = 0; _i < this.models_risk.length; _i++) {
                let temp = {}
                temp['model_id']=this.models_risk[_i].model_id;
                temp['analysis_horizon']=this.analysis_horizon;
                temp['memory_analysis_horizon']=this.memory_analysis_horizon;
                temp['method']='update';
                data_list.push(temp)
            } //end _i

            var result:any;
            let endpoint='/test/model/serverlistupdate';
            result = this._apiService.addModel(endpoint,data_list).subscribe(x => {
                                       if (this.models_hedging.length==0){
                                            this.dialogRef.close()
                                        } else {
                                            this.d1=false;
                                            this.d2=true;
                                          

                                        }  
            }); //end result
            */

            if (this.models_hedging.length==0){
                    this.dialogRef.close()
                } else {
                    this.d1=false;
                    this.d2=true;
                   

                }  




      }//end update

      if (event=='clone'){

            let data_list = [];
            for (var _i = 0; _i < this.models_risk.length; _i++) {
                let temp = {}
                temp['model_id']=this.models_risk[_i].model_id;
                temp['analysis_horizon']=this.analysis_horizon;
                temp['memory_analysis_horizon']=this.memory_analysis_horizon;
                temp['method']='clone';
                temp['']
                data_list.push(temp)
            } //end _i

            var result:any;
            let endpoint='/test/model/serverlistupdate';
            result = this._apiService.addModel(endpoint,data_list).subscribe(x => {
                                    if (this.models_hedging.length==0){
                                            this.dialogRef.close()
                                        } else {
                                            this.d1=false;
                                            this.d2=true;
                                           

                                        }  
            }); //end result


      }//end update

      

    /*
    if (this.models_hedging.length==0){
        this.dialogRef.close()
    } else {
        this.d1=false;
        this.d2=true;
       

    }
    */

  } //updateRisk

  updateHedging(event){

      let increase = this.memory_analysis_horizon + (this.analysis_horizon + this.memory_analysis_horizon);

      if (event=='update'){
        //models_hedging   model.
        /*
        for each model in hedging delete the last N rowls of layering only
        */

        let data_list = [];
        for (var _i = 0; _i < this.models_hedging.length; _i++) {
            if (this.models_hedging[_i].data.data.hasOwnProperty('hedgelayering')){

              
                //this.models_hedging[_i].data.name = this.models_hedging[_i].data.name + '_clone'

                //update layering
                let chuck = _.chunk(this.models_hedging[_i].data.data.hedgelayering.layering, this.memory_analysis_horizon)
                let temp0 = []
                for (var _j = 0; _j < chuck.length; _j++) {
                    for (var _k = 0; _k < chuck.length; _k++){
                        chuck[_j].push(0)
                    }
                    temp0 = _.concat(temp0, chuck[_j]);
                }

                let tempNA = []
                for (var _k = 0; _k < chuck.length; _k++){
                        tempNA.push(0)
                }
                
                //this.models_hedging[_i].data.data.hedgelayering.layering = temp0;
                this.models_hedging[_i].data.data.hedgelayering.analysis_horizon = this.analysis_horizon;

                for (var asset  in this.models_hedging[_i].data.data.hedgelayering.edit_asset_type_hash){
                    let temp = this.models_hedging[_i].data.data.hedgelayering.edit_asset_type_hash[asset]
                    this.models_hedging[_i].data.data.hedgelayering.edit_asset_type_hash[asset] = _.concat(temp, tempNA)

                    let temp2 = this.models_hedging[_i].data.data.hedgelayering.orig_asset_type_hash[asset]
                    this.models_hedging[_i].data.data.hedgelayering.orig_asset_type_hash[asset] = _.concat(temp2, tempNA)
                }

                var data = {
                     'layering':temp0,
                     'edit_asset_type_hash':this.models_hedging[_i].data.data.hedgelayering.edit_asset_type_hash,
                     'orig_asset_type_hash':this.models_hedging[_i].data.data.hedgelayering.orig_asset_type_hash,
                     'analysis_horizon':this.analysis_horizon,
                     'table_key':'hedgelayering',
                     'model_id':this.models_hedging[_i].model_id
                };

                data_list.push(data);

            }//end if
        }//end _i

        var result:any;
        let endpoint='/test/model/listupdate';
        result = this._apiService.addModel(endpoint,data_list).subscribe(x => {
                                //let model_id = x.model_id; 
                                this.dialogRef.close();         
        }); //end result
        
    } //end update

      if (event=='clone'){
            for (var _i = 0; _i < this.models_hedging.length; _i++) {
                    if (this.models_hedging[_i].data.data.hasOwnProperty('hedgelayering')){
                        
                          
                            this.models_hedging[_i].data.name = this.models_hedging[_i].data.name + '_clone'

                    
                            //update layering increase
                            let chuck = _.chunk(this.models_hedging[_i].data.data.hedgelayering.layering, this.memory_analysis_horizon)
                            let temp0 = []
                            for (var _j = 0; _j < chuck.length; _j++) {
                                for (var _k = 0; _k < chuck.length; _k++){
                                    chuck[_j].push(0)
                                }
                                temp0 = _.concat(temp0, chuck[_j]);
                            }



                            let tempNA = []
                            for (var _k = 0; _k < chuck.length; _k++){
                                    tempNA.push(0)
                            }


                         
                            this.models_hedging[_i].data.data.hedgelayering.layering = temp0;
                            this.models_hedging[_i].data.data.hedgelayering.analysis_horizon = this.analysis_horizon;

                            for (var asset  in this.models_hedging[_i].data.data.hedgelayering.edit_asset_type_hash){
                                let temp = this.models_hedging[_i].data.data.hedgelayering.edit_asset_type_hash[asset]

                                
                                this.models_hedging[_i].data.data.hedgelayering.edit_asset_type_hash[asset] = _.concat(temp, tempNA);

                                let temp2 = this.models_hedging[_i].data.data.hedgelayering.orig_asset_type_hash[asset]
                                this.models_hedging[_i].data.data.hedgelayering.orig_asset_type_hash[asset] = _.concat(temp2, tempNA);
                            }


                    }//end hasOwnProperty('hedgelayering')

            } //end _i

                var result:any;
                let endpoint='/test/model/clone';
                result = this._apiService.addModel(endpoint,this.models_hedging).subscribe(x => {
                                        //let model_id = x.model_id; 
                                        this.dialogRef.close();         
                }); //end result
                

            } //end clone

   
  }



   onSubmit(){

     /*
      var result:any;
      this.model.data.name = this.myForm.controls['name'].value
      let endpoint='/test/model/clone';
      result = this._apiService.addModel(endpoint,this.model).subscribe(x => {
                            //let model_id = x.model_id; 
                            this.dialogRef.close();         
      }); //end result
      */

  } //end onSubmit


}



/*
ETR
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
*/


@Component({
  selector: 'etr_dialog',
  templateUrl: './etr_dialog.html',
})
export class DialogEtr {
  

  selectedValue:string;
  myForm: FormGroup;
  analysis_horizon:any;
  models_hedging: any[] = [];
  models_risk: any[] = [];
  etr:any;
  this_exposure_model_id:any;

  d1=true;
  d2=false;



  constructor(public dialogRef: MatDialogRef<DialogEtr>,
              private fb: FormBuilder,
              private _apiService: ApiService) {

               this.myForm = this.fb.group({
                  name: ['',[Validators.required]],
                }) //end form

  } //end constructor

  onContinue(event){

      if (event=='update'){

          let data_list = [];
            for (var _i = 0; _i < this.models_hedging.length; _i++) {
                let temp = {}
                temp['model_id']=this.models_hedging[_i].model_id;
                temp['etr']=this.etr;
                temp['method']='update';
                temp['this_exposure_model_id']=this.this_exposure_model_id;
                data_list.push(temp)
            } //end _i

            var result:any;
            let endpoint='/test/model/serverlistupdate';
            result = this._apiService.addModel(endpoint,data_list).subscribe(x => {
                this.dialogRef.close()
            }); //end result


          //get list of hedging models and update all
          //update all on thius exposure model
          

      }
      if (event=='store'){
          //do nothing
      }

      this.d1=false;
      this.d2=true;
      this.dialogRef.close()
  }



   onSubmit(){

     /*
      var result:any;
      this.model.data.name = this.myForm.controls['name'].value
      let endpoint='/test/model/clone';
      result = this._apiService.addModel(endpoint,this.model).subscribe(x => {
                            //let model_id = x.model_id; 
                            this.dialogRef.close();         
      }); //end result
      */

  } //end onSubmit


}





/*
DEREFFED LAG
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------
*/


@Component({
  selector: 'deferred_dialog',
  templateUrl: './deferred_dialog.html',
})
export class DialogDeferred {
  

  selectedValue:string;
  myForm: FormGroup;
  analysis_horizon:any;
  models_hedging: any[] = [];
  models_risk: any[] = [];
  deferred:any;
  this_exposure_model_id:any;

  d1=true;
  d2=false;



  constructor(public dialogRef: MatDialogRef<DialogDeferred>,
              private fb: FormBuilder,
              private _apiService: ApiService) {

               this.myForm = this.fb.group({
                  name: ['',[Validators.required]],
                }) //end form

  } //end constructor

  onContinue(event){

      if (event=='update'){

          let data_list = [];
            for (var _i = 0; _i < this.models_hedging.length; _i++) {
                let temp = {}
                temp['model_id']=this.models_hedging[_i].model_id;
                temp['deferred']=this.deferred;
                temp['method']='update';
                temp['this_exposure_model_id']=this.this_exposure_model_id;
                data_list.push(temp)
            } //end _i

            var result:any;
            let endpoint='/test/model/serverlistupdate';
            result = this._apiService.addModel(endpoint,data_list).subscribe(x => {
                this.dialogRef.close()
            }); //end result

      }
      if (event=='store'){
          //do nothing
      }

      this.d1=false;
      this.d2=true;
      this.dialogRef.close()
  }



   onSubmit(){

     /*
      var result:any;
      this.model.data.name = this.myForm.controls['name'].value
      let endpoint='/test/model/clone';
      result = this._apiService.addModel(endpoint,this.model).subscribe(x => {
                            //let model_id = x.model_id; 
                            this.dialogRef.close();         
      }); //end result
      */

  } //end onSubmit


}


