import {Component, OnInit, Output,EventEmitter,Provider, forwardRef, AfterViewInit} from '@angular/core';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { ModelValidators } from '../validators/validators';
import { CURRENCIES } from '../object_lists/assets_object';
import { FormComponent } from '../can_deactivate/prevent_unsaved_changes_gaurd.service';
import { LoggedinService } from '../loggedin.service';

 
const FLOAT_REGEX = /^-?\d*(\.\d+)?$/;


@Component({
     selector: 'app-mesa-com-mr',
  templateUrl: './mesa-com-mr.component.html',
  styleUrls: ['./mesa-com-mr.component.css'],
    providers: [RouterLink, FormBuilder, ApiService ]
})  
export class MesaComMrComponent implements OnInit, AfterViewInit, FormComponent {

    endpoint='/test/model/commr';
    use_hash = false;
    myForm: FormGroup;
    myFormArrayStored = new FormArray([]);
    myFormArrayUse = new FormArray([]);
    model_id: string;
    isLoading = false;
    crud:string;

    model_name:string;

    constructor(
        private loggedinService: LoggedinService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private _apiService: ApiService) {

        this.loggedinService.announceLoggedin(true);

        this.model_name = localStorage.getItem('model_name');


        this.myForm = fb.group({
             my_form_array_stored: this.myFormArrayStored,
             my_form_array_use: this.myFormArrayUse,
         })
         
    }

    api_object:any; 
     
    currency_list: any[] = [];
    p_values: any[] = [];
    beta_list: any[] = [];
    temp:any;

    raw_data: any[] = [];

    backward_title:string
    backward_link:string;

    ngOnInit() {
        this.route.params.map(params => params['id'])
            .subscribe(id => { this.model_id=id });

        this.route.data.forEach((data) => {

            /*
            "comm_estresult_mr_speed": [{
					"_row": "Silver_USD",
					"pval": 0.14,
					"betas": 5.7454
				}],
            */

                if (data['results'].hasOwnProperty('commr')===false){
                    this.crud='new';
                } else {
                    this.crud='edit';
                }



                if (data['results'].hasOwnProperty('ceexp')===true){
                    this.backward_title="Currency: Expectations"
                    this.backward_link = 'models/ceexp/' +  this.model_id
                } else {
                    this.backward_title="Market Risk Factors "
                    this.backward_link = 'models/marketriskfactors/' +  this.model_id + ';model=risk'
                }  
                
            
                if (data['results'].hasOwnProperty('commr')){
                    this.raw_data = data['results']['commr'];
                    for (var _i = 0; _i < this.raw_data.length; _i++) {
                        this.myFormArrayUse.push(new FormControl(data['results']['commr'][_i]['use'],[]));
                        this.myFormArrayStored.push(new FormControl(data['results']['commr'][_i]['stored_values'],[Validators.required,Validators.pattern(FLOAT_REGEX)]));
                    }   
                } else { //First save
                    this.raw_data = data['results']["populate_risk"]['comm_estresult_mr_speed'];
                    for (var _i = 0; _i < data['results']["populate_risk"]['comm_estresult_mr_speed'].length; _i++) {
                        this.p_values.push(data['results']["populate_risk"]['comm_estresult_mr_speed'][_i]['pval']);
                        this.beta_list.push(data['results']["populate_risk"]['comm_estresult_mr_speed'][_i]['betas'])
                        if (data['results']["populate_risk"]['comm_estresult_mr_speed'][_i]['pval']<.05){
                                this.myFormArrayUse.push(new FormControl(true,[]));
                                this.myFormArrayStored.push(new FormControl(data['results']["populate_risk"]['comm_estresult_mr_speed'][_i]['betas'],[Validators.required,Validators.pattern(FLOAT_REGEX)]));
                        } else{
                                this.myFormArrayUse.push(new FormControl(false,[]));
                                this.myFormArrayStored.push(new FormControl(0,[Validators.required]));
                        }
                    }
                }  
              
                //this.onEditLoad(data['results']);
            

        });
 
         
    }
    ngAfterViewInit() {
        // Your jQuery code goes here
        //$('[data-toggle="tooltip"]').tooltip();
    }


    back(){
        this._router.navigateByUrl(this.backward_link);
    }
 
    onSubmit() {
        var result:any;
        for (var _i = 0; _i < this.raw_data.length; _i++) {
            this.raw_data[_i]['use']=this.myForm.controls['my_form_array_use'].value[_i];
            this.raw_data[_i]['stored_values']=this.myForm.controls['my_form_array_stored'].value[_i]
        }
        let data = this.raw_data;
        
        if (this.crud=='new'){
            result = this._apiService.addModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    this._router.navigateByUrl('models/comvol/' +  this.model_id);
                });
        }

        if (this.crud=='edit'){
            result = this._apiService.updateModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    this._router.navigateByUrl('models/comvol/' +  this.model_id );
            });
        }
       
        
        
       

    }
} 
