import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { tokenNotExpired } from 'angular2-jwt';
import { LoggedinService } from '../loggedin.service';
import { BusyService } from '../busy.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              private busyService: BusyService,
              private loggedinService: LoggedinService,) {}

  canActivate() {

   
    if (tokenNotExpired()) {
      

      this.loggedinService.announceLoggedin(true);
      return true;
    }
   
    this.busyService.announceBusy(false);
    this.loggedinService.announceLoggedin(false);
    localStorage.removeItem('id_token');
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
    return false;
    
  }
}
