import { Injectable } from '@angular/core';
import {Http,Headers} from '@angular/http';
 
import { AuthHttp  } from 'angular2-jwt';


import { Observable, interval, pipe } from 'rxjs';
import { switchMap, map, takeWhile } from 'rxjs/operators';

import { environment } from '../../environments/environment';


@Injectable()
export class ApiService {

  //private domain =  'http://ec2-52-90-147-194.compute-1.amazonaws.com';
  private domain =  environment.API_URL;
  private _url = environment.API_URL;
  
  constructor(private _http: Http,private _authHttp: AuthHttp){}

  isEmpty(obj) {
    //https://coderwall.com/p/_g3x9q/how-to-check-if-javascript-object-is-empty
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  //if isHash is false then array is true and need to get data[results]
  getPollModles(endpoint:string,isHash=true){
    var url = this.getEndpointUrl(endpoint);
     
    if (isHash==true){
      //https://stackoverflow.com/questions/43592389/unsubscribing-from-an-angular2-method-when-a-certain-response-is-given

      //https://hackernoon.com/manage-your-observable-subscriptions-in-angular-with-help-of-rx-js-f574b590a5cb

      //How to resume if error rather than {}
   
      /*
      var result = Observable.interval(5000).switchMap(() => this._authHttp.get(url)).map(res => res.json()).
      takeWhile(models => this.isEmpty(models) == false);
      */

      
      var result = interval(5000).pipe(switchMap(
        () => this._authHttp.get(url).pipe(
              map(res => res.json())
              ))
      ).pipe(takeWhile(models => this.isEmpty(models) == false))

    } else {
      var result = interval(5000).pipe(switchMap(
                                                    () => this._authHttp.get(url).pipe(
                                                        map(res => res.json())
                                                    )//second pipe
                                                )//sM
                                        )//first pipe
    }

    return result;
  }
  
  getModels(endpoint:string,isHash=true){
    
    //let myHeader = new Headers();
    //myHeader.append('Content-Type', 'application/json');
    //myHeader.append('Authorization', 'Bearer ' + localStorage.getItem('id_token'));
  
    
    var url = this.getEndpointUrl(endpoint);
		//return this._authHttp.get(url,{ headers: myHeader }).map(res => res.json());
    return this._authHttp.get(url).pipe(map(res => res.json()));
	} 
  
  getModel(endpoint:string,modelId:any,isHash=true){
    
    var url = this.getEndpointUrl(endpoint);
     if (isHash==true){
       var result = this._authHttp.get(this.getModelUrl(endpoint,modelId)).pipe(map(res => res.json()));
     } else {
       var result =this._authHttp.get(this.getModelUrl(endpoint,modelId)).pipe(map(res => res.json().results));
     }

		return result;
  }
  
  getBinary(endpoint:string,modelId:any,isHash=true){
    
    var url = this.getEndpointUrl(endpoint);
     if (isHash==true){
       var result = this._authHttp.get(this.getModelUrl(endpoint,modelId)).pipe(map(res => res));
     } else {
       var result = this._authHttp.get(this.getModelUrl(endpoint,modelId)).pipe(map(res => res));
     }

		return result;
	} 

  getOutput(endpoint:string,modelId:any,type:any,isHash=true){
    
    var url = this.getEndpointUrl(endpoint);
     if (isHash==true){
       var result = this._authHttp.get(this.getOutputUrl(endpoint,modelId,type)).pipe(map(res => res.json()));
     } else {
       var result =this._authHttp.get(this.getOutputUrl(endpoint,modelId,type)).pipe(map(res => res.json().results));
     }

		return result;
	}
  
  addModel(endpoint:string,model:any){
    var url = this.getEndpointUrl(endpoint);
    
    return this._authHttp.post(url, JSON.stringify(model)).pipe(map(res => res.json()));
  }

  addModelId(endpoint:string,modelId:any,model:any){
    var url = this.getModelUrl(endpoint,modelId);
   
    return this._authHttp.post(url, JSON.stringify(model)).pipe(map(res => res.json()));
  }


  
 

  
  deleteModel(endpoint:string,modelId:any){
    var url = this.getEndpointUrl(endpoint);
		return this._authHttp.delete(this.getModelUrl(endpoint,modelId)).pipe(map(res => res.json()));
	}
  
  updateModel(endpoint:string,model:any){
    var url = this.getEndpointUrl(endpoint);
		return this._authHttp.put(this.getModelUrl(endpoint,model.model_id), JSON.stringify(model)).pipe(map(res => res.json()));
	}

  updateModelTopline(endpoint:string,model:any){
    var url = this.getEndpointUrl(endpoint);
		return this._authHttp.put(url, JSON.stringify(model)).pipe(map(res => res.json()));
	}
  
  updateModelId(endpoint:string,modelId:any,model:any){
    var url = this.getModelUrl(endpoint,modelId);
		return this._authHttp.put(url, JSON.stringify(model)).pipe(map(res => res.json()));
	}

  private getEndpointUrl(endpoint:string){
      return this._url + endpoint;

  }
  private getModelUrl(endpoint:string,modelId:any){
    var url = this.getEndpointUrl(endpoint)
		return url + "/" + modelId;
	}

   private getOutputUrl(endpoint:string,modelId:any,type:any){
    var url = this.getEndpointUrl(endpoint)
		return url + "/" + modelId + "/" + type;
	}

}