
import {Component, OnInit, Output,EventEmitter,Provider, forwardRef, AfterViewInit} from '@angular/core';
import {FormArray,FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CanDeactivate, Router, ActivatedRoute, RouterLink} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import { ModelValidators } from '../validators/validators';
import { CURRENCIES } from '../object_lists/assets_object';
import { LoggedinService } from '../loggedin.service';
import { FormComponent } from '../can_deactivate/prevent_unsaved_changes_gaurd.service';
import { PollingService } from '../polling.service';

import * as _ from "lodash";


@Component({
     selector: 'app-mesa-cor-fx',
  templateUrl: './mesa-cor-fx.component.html',
  styleUrls: ['./mesa-cor-fx.component.css'],
    providers: [RouterLink, FormBuilder, ApiService ]
})  
export class MesaCorFxComponent implements OnInit, AfterViewInit, FormComponent {

    forward_link:string
    endpoint='/test/model/corfx';
    use_hash = false;
    myForm: FormGroup;
    myFormArrayCorr = new FormArray([]);
    model_id: string;
    isLoading = false;
    crud:string;

    model_name:string;
     
    constructor(
        private loggedinService: LoggedinService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private pollingService: PollingService,
        private _apiService: ApiService) {

        this.loggedinService.announceLoggedin(true);

        this.model_name = localStorage.getItem('model_name');


        this.myForm = fb.group({
             estimation_expo: ['ew'],
             my_form_array_corr: this.myFormArrayCorr
         })
         
    } 

    api_object:any;
     
    currency_list: any[] = [];
    corr_array: any[] = [];
    temp:any;
    asset_list: any[] = [];

    fx_estresult_corr_exp_last: any[] = [];
    fx_estresult_corr_hist_last: any[] = [];

    is_comm = true;
    forward_title="Correlation: Commodities";
    backward_title="Commodity: Expectations";
    backward_route="comexp";



    selectedType:string;
    edit_fx_estresult_corr_exp: any[] = [];
    edit_fx_estresult_corr_hist: any[] = [];
    edit_asset_type_hash = {}
    orig_asset_type_hash:any;

    fx_list=[]
    comm_list=[]
 
    ngOnInit() {
        this.route.params.map(params => params['id'])
            .subscribe(id => { this.model_id=id });

        this.currency_list = [];
        this.route.data.forEach((data) => {

                if (data['results'].hasOwnProperty('corfx')===false){
                    this.crud='new';
                } else {
                    this.crud='edit';
                }

                this.fx_list = data['results']['marketriskfactors']['currencies']
                this.comm_list = data['results']['marketriskfactors']['commodities']
                

                //this.edit_fx_estresult_corr_exp = data['results']["populate_risk"]["fx_estresult_corr_exp"];
                //this.edit_fx_estresult_corr_hist = data['results']["populate_risk"]["fx_estresult_corr_hist"];
                
                if (data['results'].hasOwnProperty('commr')===false){
                    this.is_comm=false;
                    this.forward_title="Save Risk Model";
                    this.backward_title="Currency Estimates: Expectations";
                    this.backward_route="ceexp";
                    this.forward_link = "models"
                } else{
                    this.forward_title="Correlation: Commodities";
                    this.backward_title="Currency Estimates: Expectations";
                    this.forward_link ='models/corcom/' +  this.model_id
                }


                
                //this.fx_estresult_corr_exp_last = data['results']["populate_risk"]["fx_estresult_corr_exp"];
                //this.fx_estresult_corr_hist_last = data['results']["populate_risk"]["fx_estresult_corr_hist"];

                this.currency_list = data['results']['corfx']['asset_list']
                this.asset_list = data['results']['corfx']['asset_list'];
            
                let currency_count = this.currency_list.length; 
                //this.currency_list = _.sortBy(this.currency_list);
                
                if (currency_count==1){
                    this.fx_estresult_corr_exp_last = [1];
                    this.fx_estresult_corr_hist_last = [1];
                }

                
                let estimation_expo = data['results']['basic']['estimation_expo'];
                let cor_len = currency_count*currency_count;

                if (data['results'].hasOwnProperty('corfx')){ //Edit

                    

                   this.edit_asset_type_hash = data['results']['corfx']['edit_asset_type_hash'];
                   this.orig_asset_type_hash = data['results']['corfx']['orig_asset_type_hash'];

                   //currency_list
                 


                    let estimation_expo = data['results']['corfx']['estimation_expo'];
                    this.myForm.controls['estimation_expo'].setValue(estimation_expo);
                    let temp = data['results']['corfx']['correlations'];
                   
                    for (var _i = 0; _i < temp.length; _i++) {
                           let v = temp[_i];
                           this.myFormArrayCorr.push(new FormControl(v,[Validators.required]));
                    }
  

                } else { //new

                    this.createEditHash();
                    
                    this.myForm.controls['estimation_expo'].setValue(estimation_expo);

                    let ct = 'hist';
                    let temp: any[] = [];
                    if (estimation_expo=='es'){
                        //this.myForm.controls['estimation_expo']
                        temp = this.fx_estresult_corr_exp_last;
                        ct = 'exp'; 
                    } else {
                        temp = this.fx_estresult_corr_hist_last;
                        ct = 'hist';
                    }

                    for (var _i = 0; _i < this.currency_list.length; _i++) {
                        for (var _j = 0; _j < this.currency_list.length; _j++) {
                            let v:any;
                            v = this.edit_asset_type_hash[this.currency_list[_i]][this.currency_list[_j]][ct];
                            if (v>=1) v=1;
                            this.myFormArrayCorr.push(new FormControl(v,[Validators.required]));
                        }
                    }

                }
                this.orig_asset_type_hash = _.cloneDeep(this.edit_asset_type_hash)
               
        });
 
         
    }

    onInputChange(ce,i,j){ 
        //let type = this.selectedType;
        let temp = this.myForm.controls['estimation_expo'].value
        
        let type='hist';
        if (temp=='es'){
            type = 'exp';
        }
        let cee = this.currency_list[i];
        let cc = this.currency_list[j];
        
        let idx = ((i+1)*this.currency_list.length)-this.currency_list.length+j;
        this.edit_asset_type_hash[cee][cc][type]=this.myForm.controls['my_form_array_corr'].value[idx];
        
    } 

    createEditHash(){

        let pair = '';
        for (var _i = 0; _i < this.edit_fx_estresult_corr_exp.length; _i++) {
                pair = this.edit_fx_estresult_corr_exp[_i]['_row'];
                this.edit_asset_type_hash[pair]={};
                for (var key in this.edit_fx_estresult_corr_exp[_i]) {
                    if (key!='_row'){
                        this.edit_asset_type_hash[pair][key]={};
                        this.edit_asset_type_hash[pair][key]['exp']=this.edit_fx_estresult_corr_exp[_i][key]
                        this.edit_asset_type_hash[pair][key]['hist']=this.edit_fx_estresult_corr_hist[_i][key]
                    }
                }
        }
    }



    ngAfterViewInit() {

    }


    back(){
        this._router.navigateByUrl('models/' + this.backward_route + '/'+  this.model_id);
    }
 
   
    radioChange(event){

        let test: any[] = [];
        let temp: any[] = [];
        let ct = 'hist';
        if (event=='es'){
            this.selectedType = 'exp';
            ct =  this.selectedType
            temp = this.fx_estresult_corr_exp_last;
        } else {
            this.selectedType = 'hist';
            ct =  this.selectedType
            temp = this.fx_estresult_corr_hist_last;
        }

        for (var _i = 0; _i < this.currency_list.length; _i++) {
            for (var _j = 0; _j < this.currency_list.length; _j++) {
                let v:any;
                v = this.edit_asset_type_hash[this.currency_list[_i]][this.currency_list[_j]][ct];
                if (v>=1) v=1;
                test.push(v);
            }
        }
        this.myFormArrayCorr.setValue(test);

    }
 
    onSubmit() {
        var result:any;
        //this.myForm.controls['my_form_array_stored'].value;
        
      
        var data = {'correlations':this.myForm.controls['my_form_array_corr'].value,
                    'estimation_expo':this.myForm.controls['estimation_expo'].value,
                    'edit_asset_type_hash':this.edit_asset_type_hash,
                    'orig_asset_type_hash':this.orig_asset_type_hash,
                     'asset_list':this.asset_list};

        
        if (this.crud=='new'){
            result = this._apiService.addModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    if (this.fx_list.length>0 && this.comm_list.length==0){
                        this.pollingService.announcePolling({'model_id':this.model_id,'is_output':false});
                    }
                    this._router.navigateByUrl(this.forward_link);
                    
                });
        }

        if (this.crud=='edit'){
            
            result = this._apiService.updateModelId(this.endpoint,this.model_id,data).subscribe(x => {
                    if (this.fx_list.length>0 && this.comm_list.length==0){
                        this.pollingService.announcePolling({'model_id':this.model_id,'is_output':false});
                    }
                    this._router.navigateByUrl(this.forward_link);
            });
        }
       
        
        
        

    }
} 

