import { Component, OnInit, ViewChild } from '@angular/core';
import {CanDeactivate, Router, ActivatedRoute, RouterLink,} from '@angular/router';
import { ApiService } from '../api_service/api.service';
import {MatTabsModule,MatTabGroup} from '@angular/material';

import * as _ from "lodash";
declare var $: any;
declare var require: any;

var Highcharts = require('highcharts/highstock');
//require('highcharts/modules/exporting')(Highcharts);
//require('highcharts/modules/map')(Highcharts);
 

@Component({
  selector: 'app-mesa-output-details',
  templateUrl: './mesa-output-details.component.html',
  styleUrls: ['./mesa-output-details.component.css']
})
export class MesaOutputDetailsComponent implements OnInit {

  @ViewChild('tabbar') tabbar: MatTabGroup;
   
  output_data = [
    {"exposure":"EUR_USD","USD_equivalent":0,"Individual_VaR":0,"Marginal_VaR":0,"Cost_Benefit":0},
    {"exposure":"JPY_USD","USD_equivalent":0,"Individual_VaR":0,"Marginal_VaR":0,"Cost_Benefit":0}
  ]
  navLinks = []

  //this._router.navigate( ['/output', {outlets: {'output': ['details',{'id':model['idx']},{outlets:{'data':['distributions',{'id':model['idx']}]}}]}}] )

  
  isLoading=false;
  data={}; 
  id:any;
  private sub: any;

  analysis_horizon_keys = [];
  selectedAnalysisHorizon:any;
  analysis_horizon:any; 

  endpoint='/output'

  testFlag = false;

  type_array = [{'name':'Earnings','id':'E'},{'name':'Cash Flows','id':'CF'}]
  percentile_array = [{'name':'01','id':'01'},
                      {'name':'05','id':'05'},
                      {'name':'10','id':'10'},
                      {'name':'20','id':'20'},
                      
                      ]
  selectedPercentile='01';
  selectedType='CF';

  is_id = false;
 
  constructor(private route: ActivatedRoute,
              private _router: Router,
              private _apiService: ApiService
              ) { 

              this.route.params.map(params => params['id']).subscribe(id => { this.id=id
               });

              

              if (this.id){
                this.is_id = true;
              } else {
                this.is_id = false;
              }

 

              if (this.is_id){
                this.navLinks = [
                    {"name":"Distributions","link":['/output', {outlets: {'output': ['details',{outlets:{'data':['distributions',{'id':this.id}]}}]}}]},
                    //{"name":"Histograms","link":['/output', {outlets: {'output': ['details',{outlets:{'data':['histograms']}}]}}]},
                    {"name":"Monthly Risk","link":['/output', {outlets: {'output': ['details',{outlets:{'data':['monthlyrisk',{'id':this.id}]}}]}}]},
                    {"name":"Exposure Tables","link":['/output', {outlets: {'output': ['details',{outlets:{'data':['exposuretables',{'id':this.id}]}}]}}]},
                    {"name":"Hedging Cost Graphs","link":['/output', {outlets: {'output': ['details',{outlets:{'data':['hedgingcost',{'id':this.id}]}}]}}]},
                    //{"name":"Earning vs. Cash Flows","link":['/output', {outlets: {'output': ['details',{outlets:{'data':['earnsingsvscf',{'id':this.id}]}}]}}]},
                    //{"name":"Monthly Graphs","link":['/output', {outlets: {'output': ['details',{outlets:{'data':['monthlygraphs']}}]}}]}
                  ]
              }
              

            /*
              this.id = this.route.snapshot.params["id"];
             
            */
 
  } //end constructor
   
  ngOnInit() {
   
   } //end ngOnInit

   toggle:boolean = true;

   changeRoute(){



   }

   test={}
   dude(id,type){
      
      this._apiService.getOutput(this.endpoint,id,type)
                      .subscribe(data => {
                          this.data = data
                         
                           
                          let analysis_horizon_keys = Object.keys(this.data);
                          //.this.analysis_horizon_keys = this.analysis_horizon_keys.sort();
                          //this.analysis_horizon_keys.length;

                          let temp = []
                          for (var _i = 0; _i < analysis_horizon_keys.length; _i++) {
                            temp.push(`M${_i+1}`)
                          }
                          this.analysis_horizon_keys = temp


                          this.selectedAnalysisHorizon = this.analysis_horizon_keys.slice(-1)[0];

                          let type="E"
                          this.test = this.data[this.selectedAnalysisHorizon]['Current'][type]
                          let container_id = "container_density_e"
                          let args = {'x':"dist_x",'y':"dist_y"}
                          this.dudder(container_id,args)


                          this.test = this.data[this.selectedAnalysisHorizon]['Current'][type]
                          container_id = "container_zoomdist_e"
                          args = {'x':"zoomdist_x",'y':"zoomdist_y"}
                          this.dudder(container_id,args)


                          type="CF"
                          this.test = this.data[this.selectedAnalysisHorizon]['Current'][type]
                          container_id = "container_density_cf"
                          args = {'x':"dist_x",'y':"dist_y"}
                          this.dudder(container_id,args)


                          this.test = this.data[this.selectedAnalysisHorizon]['Current'][type]
                          container_id = "container_zoomdist_cf"
                          args = {'x':"zoomdist_x",'y':"zoomdist_y"}
                          this.dudder(container_id,args)



                      }
                      ,null,() => { this.isLoading = false;
                        //this.busyService.announceBusy(false); 
                        
                      });


    } //end duder


    dudder(container_id,args){

        
       

         //let data_den = this.density()

         var ah_lag = this.selectedAnalysisHorizon.replace("M", "")

         let data_den = _.zip(this.test[ args.x],this.test[ args.y]);
         let exp_value = this.test['exp_value'][0];

         
        
          let q = this.test[`q_${this.selectedPercentile}`]
          let ES = this.test[`ES_${this.selectedPercentile}`]

          var title = this.test["chart_title"][0]

          var chart_ylab = this.test["chart_ylab"][0]
          var chart_xlab = this.test["chart_xlab"][0]


         $(function(){ 

            Highcharts.chart(container_id, {
                title: {
                    //text: `Earnings Distribution Plot: ${ah_lag} Month EaR`
                    text: title
                    
                },

                credits: {
                        enabled: false
                    },

                /*
                subtitle: {
                    text: 'Source: thesolarfoundation.com'
                },
                */

/*
                 A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  https://material.io/guidelines/style/color.html#
  */

                xAxis: {
                  title: {
                        text: chart_xlab
                    },
                    plotLines: [{
                        color: '#81D4FA', // Red
                        dashStyle: "Solid",
                        width: 1,
                        value: exp_value // Position, you'll have to translate this to the values on your x axis
                    },
                    {
                        color: '#00BCD4', // Red
                        dashStyle: "dash",
                        width: 1,
                        value: q // Position, you'll have to translate this to the values on your x axis
                    },{
                        color: '#009688', // Red
                        dashStyle: "dash",
                        width: 1,
                        value: ES // Position, you'll have to translate this to the values on your x axis
                    },
                    
                    
                    ]
                },


                yAxis: {
                    title: {
                        text:  chart_ylab
                    }
                },
                /*
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },
               */
                plotOptions: {
                    scatter: {
                            marker: {
                                radius: 5,
                                states: {
                                    hover: {
                                        enabled: true,
                                        lineColor: 'rgb(100,100,100)'
                                    }
                                }
                            },
                            states: {
                                hover: {
                                    marker: {
                                        enabled: false
                                    }
                                }
                            }
                           
                        }
                },
 
                series: [{
                            showInLegend: false,     
                            //name: 'EUR_USD',
                            dashStyle: "Solid",
                            //color: 'rgba(223, 83, 83, .5)',
                            lineWidth: 3,
                            data: data_den
                        }
                ]

                        })
        
         });
         this.testFlag=true;




    } //end dudder





    onAnalysisHorizonChange(){

      let type='E'
      let container_id = "container_density_e"
      let args = {'x':"dist_x",'y':"dist_y"}
      this.test = this.data[this.selectedAnalysisHorizon]['Current'][type]
      this.dudder(container_id,args)

      type='E'
      container_id = "container_zoomdist_e"
      args = {'x':"zoomdist_x",'y':"zoomdist_y"}
      this.test = this.data[this.selectedAnalysisHorizon]['Current'][type]
      this.dudder(container_id,args)

      type='CF'
      container_id = "container_density_cf"
      args = {'x':"dist_x",'y':"dist_y"}
      this.test = this.data[this.selectedAnalysisHorizon]['Current'][type]
      this.dudder(container_id,args)

      type='CF'
      container_id = "container_zoomdist_cf"
      args = {'x':"zoomdist_x",'y':"zoomdist_y"}
      this.test = this.data[this.selectedAnalysisHorizon]['Current'][type]
      this.dudder(container_id,args)


    }

    /*
    onTypeChange(){

      this.test = this.data[this.selectedAnalysisHorizon]['Current'][this.selectedType]
      let container_id = "container_density"
      let args = {'x':"dist_x",'y':"dist_y"}
      this.dudder(container_id,args)

    }
    */

    onPercentileChange(){

      let type='E'
      let container_id = "container_density_e"
      let args = {'x':"dist_x",'y':"dist_y"}
      this.test = this.data[this.selectedAnalysisHorizon]['Current']['E']
      this.dudder(container_id,args)

      type='E'
      container_id = "container_zoomdist_e"
      args = {'x':"zoomdist_x",'y':"zoomdist_y"}
      this.test = this.data[this.selectedAnalysisHorizon]['Current'][type]
      this.dudder(container_id,args)

      type='CF'
      container_id = "container_density_cf"
      args = {'x':"dist_x",'y':"dist_y"}
      this.test = this.data[this.selectedAnalysisHorizon]['Current'][type]
      this.dudder(container_id,args)

      type='CF'
      container_id = "container_zoomdist_cf"
      args = {'x':"zoomdist_x",'y':"zoomdist_y"}
      this.test = this.data[this.selectedAnalysisHorizon]['Current'][type]
      this.dudder(container_id,args)

    }







}


