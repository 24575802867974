import { Injectable } from '@angular/core';
import {Http,Headers} from '@angular/http';
import 'rxjs/add/operator/map';
import { AuthHttp  } from 'angular2-jwt';
import {Observable} from "rxjs/Rx";
import { environment } from '../../environments/environment';
import { CanDeactivate } from '@angular/router';
import { FormGroup } from '@angular/forms';

//https://scotch.io/courses/routing-angular-2-applications/candeactivate

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  canDeactivate(component: CanComponentDeactivate) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }

}