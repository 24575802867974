import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ApiService } from '../api_service/api.service';
@Injectable()
export class ModelFormResolve implements Resolve<any> {

  constructor(private _apiService: ApiService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    let id = route.params['id'];
    let model_id = id;
    let endpoint='/test/model';
    return new Promise((resolve) => { this._apiService.getModels(endpoint).subscribe(api_object => {
            if (api_object) {
                
                return resolve(api_object); 
            } else { // id not found
                this.router.navigateByUrl('/models/new/' +  model_id +')');
                return true;
            }
            });

    });


  }
} 
