import { Assets } from './assets';
   
export var CURRENCIES: Assets[] = [
{type: 'fx', displayname: 'USD', pair: 'USD', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'EUR', pair: 'USD_EUR', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'GBP', pair: 'USD_GBP', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'AUD', pair: 'USD_AUD', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'NZD', pair: 'USD_NZD', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'CAD', pair: 'USD_CAD', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'CHF', pair: 'USD_CHF', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'JPY', pair: 'USD_JPY', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'ARS', pair: 'USD_ARS', ivol: 1, forward: 1, NDF: 1},
{type: 'fx', displayname: 'BRL', pair: 'USD_BRL', ivol: 1, forward: 1, NDF: 1},
{type: 'fx', displayname: 'CNH', pair: 'USD_CNH', ivol: 1, forward: 1, NDF: 1},
{type: 'fx', displayname: 'CNY', pair: 'USD_CNY', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'COP', pair: 'USD_COP', ivol: 1, forward: 1, NDF: 1},
{type: 'fx', displayname: 'DOP', pair: 'USD_DOP', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'HKD', pair: 'USD_HKD', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'IDR', pair: 'USD_IDR', ivol: 1, forward: 1, NDF: 1},
{type: 'fx', displayname: 'INR', pair: 'USD_INR', ivol: 1, forward: 1, NDF: 1},
{type: 'fx', displayname: 'KRW', pair: 'USD_KRW', ivol: 1, forward: 1, NDF: 1},
{type: 'fx', displayname: 'MXN', pair: 'USD_MXN', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'MYR', pair: 'USD_MYR', ivol: 1, forward: 1, NDF: 1},
{type: 'fx', displayname: 'NOK', pair: 'USD_NOK', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'PEN', pair: 'USD_PEN', ivol: 1, forward: 1, NDF: 1},
{type: 'fx', displayname: 'PHP', pair: 'USD_PHP', ivol: 1, forward: 1, NDF: 1},
{type: 'fx', displayname: 'PLN', pair: 'USD_PLN', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'RUB', pair: 'USD_RUB', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'SEK', pair: 'USD_SEK', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'SGD', pair: 'USD_SGD', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'THB', pair: 'USD_THB', ivol: 1, forward: 1, NDF: 1},
{type: 'fx', displayname: 'TRY', pair: 'USD_TRY', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'TWD', pair: 'USD_TWD', ivol: 1, forward: 1, NDF: 1},
{type: 'fx', displayname: 'VEF', pair: 'USD_VEF', ivol: 1, forward: 1, NDF: 1},
{type: 'fx', displayname: 'VND', pair: 'USD_VND', ivol: 1, forward: 1, NDF: 0},
{type: 'fx', displayname: 'ZAR', pair: 'USD_ZAR', ivol: 1, forward: 1, NDF: 1}
]; 

export var COMMODITIES: Assets[] = [
  {type: 'comm', displayname: 'Gold',  pair: 'Gold_USD', ivol: 1, forward: 1, NDF: 0},
  {type: 'comm', displayname: 'Silver', pair: 'Silver_USD', ivol: 1, forward: 1, NDF: 0},
  {type: 'comm', displayname: 'WTI', pair: 'WTI_USD', ivol: 1, forward: 1, NDF: 0},
  {type: 'comm', displayname: 'Sugar', pair: 'Sugar_USD', ivol: 1, forward: 1, NDF: 0},
  {type: 'comm', displayname: 'Coffee', pair: 'Coffee_USD', ivol: 1, forward: 1, NDF: 0},
  {type: 'comm', displayname: 'Milk', pair: 'Milk_USD', ivol: 1, forward: 1, NDF: 0},
  {type: 'comm', displayname: 'Gasoline', pair: 'Gasoline_USD', ivol: 1, forward: 1, NDF: 0},
  {type: 'comm', displayname: 'NatGas', pair: 'NatGas_USD', ivol: 1, forward: 1, NDF: 0},
  {type: 'comm', displayname: 'Platinum', pair: 'Platinum_USD', ivol: 1, forward: 1, NDF: 0},
  {type: 'comm', displayname: 'Palladium', pair: 'Palladium_USD', ivol: 1, forward: 1, NDF: 0},
  {type: 'comm', displayname: 'Corn', pair: 'Corn_USD', ivol: 1, forward: 1, NDF: 0}
];   