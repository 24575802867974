import { Component, OnInit, OnDestroy} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import { LoggedinService } from '../loggedin.service';
import { Http, Headers } from '@angular/http';

import {HttpClientModule} from '@angular/common/http';

import { contentHeaders } from '../common/headers';
import { environment } from '../../environments/environment';
import { Router,ActivatedRoute } from '@angular/router';
import { BusyService } from '../busy.service';

import {MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA} from '@angular/material';


import { AuthService } from '../auth/auth.service';

import {MatSnackBar} from '@angular/material';

import { PollingService } from '../polling.service';
import { ApiService } from '../api_service/api.service';

import * as _ from "lodash";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
 
  isLoggedIn=false;
  username: string; 
  password: string;
  passcode: string;
  myForm: FormGroup;

  is_preauth = false;
  is_username = true;
  is_password = true;

  userColor = "primary"
  passColor = "primary"
 
  
  //group.value = push

  constructor(private fb: FormBuilder,
              public router: Router,
              public http: Http,
              private route: ActivatedRoute,
              private loggedinService: LoggedinService,
              private busyService: BusyService,
              private authService: AuthService,
              private pollingService: PollingService,
              private _apiService: ApiService,
              public snackBar: MatSnackBar,
              public dialog: MatDialog) {

              this.busyService.announceBusy(false);

              if (environment.environment=='local' ){
                this.username = 'david@treasuryanalytics.com';
                this.password='test101';
              }

              //https://github.com/angular/material2/issues/4027
              this.myForm = fb.group({
                    username: ['',[Validators.required]],
                    password: ['',[Validators.required]],
                    passcode: ['']
                })     

                   
  }


  preuth(){

  if (environment.environment!='pproduction' ){
  //if (environment.environment=='production' ){ 
     this.onSubmit()
   } else {
        var username = this.username;
        var password = this.password;
        let body = JSON.stringify({ username, password });
        this.http.post( environment.API_URL + '/preauth', body, { headers: contentHeaders })
              .subscribe(
                response => {
                  let res = response.json();
                  if (res.hasOwnProperty('status')==true){
                    let status = res['status']
                    if (status==true){
                     
                      this.is_preauth = true;
                    }
                    if (status==false){
                      this.is_preauth = false;
                      if (res['is_username']==false){
                        this.is_username = false;
                        this.userColor = "warn"
                      }
                      if (res['is_password']==false){
                        this.is_password = false;
                        this.passColor = "warn"
                      }
                    }
                  }
                },
                error => {
              
                 //{_body: ProgressEvent, status: 0, ok: false, statusText: "", headers: t, …}
                }
              );
         }//end else

  }



  duo(type){
    
    var username = this.username;
    let passcode = this.passcode
    let body = JSON.stringify({ username, type, passcode });
    
    this.http.post( environment.API_URL + '/duo', body, { headers: contentHeaders })
        .subscribe(
          response => {
           
            let res = response.json();
            if (res.hasOwnProperty('response')==true){
              let status = res['response']['status']
              if (status=='allow'){
                
                this.onSubmit()
              }
            }
          },
          error => {
            
          }
        );
        
  }

 
  is_auth_error = false
  is_conn_error = false
 
  getServiceData(){
    this._apiService.getModels('/asset_hash')
        .subscribe(service_data => {
            localStorage.setItem('asset_hash', JSON.stringify(service_data));
            localStorage.setItem('currency_list', JSON.stringify(service_data['currency_list']));
            localStorage.setItem('commodity_list', JSON.stringify(service_data['commodity_list']));
            localStorage.setItem('analysis_date', JSON.stringify(service_data['analysis_date']));
            localStorage.setItem('user_group_object', JSON.stringify(service_data['user_group_object']));
            this.router.navigateByUrl('/dashboard');

           
            //let service_data_object = JSON.parse(localStorage.getItem('asset_hash'));
        }
        ,null,() => { 
  
        });
} //end getServiceData

  onSubmit() {
      var username = this.username;
      var password = this.password;
      let body = JSON.stringify({ username, password });
      this.busyService.announceBusy(true);
      this.http.post( environment.API_URL + '/auth', body, { headers: contentHeaders })
        .subscribe(
          response => {
            this.loggedinService.announceLoggedin(true);
            this.isLoggedIn=true;
            //localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('id_token', response.json().access_token);
            localStorage.setItem('token', response.json().access_token);
            this.restartPolling()
            this.getServiceData()
            //this.router.navigate(['/dashboard']);
            
            
          },
          error => {
            let error_json = JSON.parse(error.text())
            if (error_json.hasOwnProperty('description')){
              this.is_auth_error=true
            } else {
              this.is_conn_error = true
            }

            this.busyService.announceBusy(false);
            this.loggedinService.announceLoggedin(false);
            this.isLoggedIn=false;
            //localStorage.removeItem('isLoggedIn');
            //alert(error.text());



          
          }
        );
    } //end onSubmit



      restartPolling(){
        /*
        restart polling on logging
        */
        let models = {}
        this._apiService.getModels('/test/polling/none').subscribe(models => {
          
          if (models.hasOwnProperty('data')){
            for (var _i = 0; _i < models['data'].length; _i++) {
              if (models['data'][_i]['data']['status']=='running'){
                this.pollingService.announcePolling({'model_id':models['data'][_i]['model_id'],'is_output':false});
              }
            }
          }
          
          
          
         });
      } //end restartPolling


      authenticate_loop() {
        setInterval (() => {
          
        }, 500)
      }
 

    ngOnInit() {

      
      
    } //end OnInit

    wtf(){
      //this.authService.login()
    }

    touchUsername(){
      this.is_username = true;
      this.userColor = "primary"
    }

    touchPassword(){
      this.is_password = true;
      this.passColor = "primary"
    }

  ngOnDestroy(){
    /*
    if(this.af_sub){
      
       this.af_sub.unsubscribe();
    } 
    */
  } //end OnDestroy


    onForgot(){
      let dialogRef =  this.dialog.open(DialogForgotDialog);
      dialogRef.afterClosed().subscribe(result => {
          //this.selectedOption = result;
        });
      }


}

 



@Component({
  selector: 'on-forgot-dialog',
  template: `
                  <div style="width:200px;">
                  Please contact MESA Support at  <a href="mailto:mesa@treasuryanaytics.com?Subject=Forgot%20password" target="_top">mesa@treasuryanaytics.com</a> and we will provide you with new login credentials.
                  
                 

                  </div>

                  <br>
                  <button mat-raised-button type="button" color="primary" (click)="dialogRef.close('cancel')">OK</button>
             `,
})
export class DialogForgotDialog {
  constructor(public dialogRef: MatDialogRef<DialogForgotDialog>) {}
}
