import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/filter';
//import auth0 from 'auth0-js';
import * as auth0 from 'auth0-js';

import { environment } from '../../environments/environment';
import { LoggedinService } from '../loggedin.service';
import { BusyService } from '../busy.service';

@Injectable()
export class AuthService {

  private _url = environment.API_URL;
  isLoggedIn = false;
  

  auth0 = new auth0.WebAuth({
    clientID: 'i3JFaaJ1WVP4mUfkT0fHycU9vRLpohY4',
    domain: 'qta.auth0.com',
    responseType: 'token id_token',
    audience: 'https://qta.auth0.com/userinfo',
    redirectUri: 'http://localhost:4200/callback',      
    scope: 'openid'
  });
 
  constructor(public router: Router,
              private busyService: BusyService,
              private loggedinService: LoggedinService) {
                

              }

              

  public login(): void {
    this.auth0.authorize();
  }

  public handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = '';
        this.setSession(authResult);
        this.router.navigate(['/dashboard']);
      } else if (err) {
        this.router.navigate(['/login']);
       
      }
    });
  }

  private setSession(authResult): void {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());

    this.loggedinService.announceLoggedin(true);
    this.isLoggedIn=true;




    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  public logout(): void {

    this.busyService.announceBusy(false);
    this.loggedinService.announceLoggedin(false);
    this.isLoggedIn=false;



    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    // Go back to the home route
    this.router.navigate(['/']);
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }


}


